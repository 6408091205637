import React from "react"
import { View, StyleSheet, ViewStyle } from "react-native"

interface IProps {
  style: ViewStyle
}

export const TriangleCorner: React.FC<IProps> = ({ style }) => {
  return <View style={[styles.triangleCorner, style]} />
}

const styles = StyleSheet.create({
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderRightWidth: 100,
    borderTopWidth: 100,
    borderRightColor: "transparent",
    borderTopColor: "red",
  },
})
