import React from "react"
import * as Updates from "expo-updates"

export const useOTAUpdate = (): boolean => {
  const [updateFound, setUpdateFound] = React.useState(false)

  const checkForUpdateAsync = async () => {
    if (!__DEV__) {
      try {
        const update = await Updates.checkForUpdateAsync()
        if (update.isAvailable) {
          setUpdateFound(true)
          await Updates.fetchUpdateAsync()
          await Updates.reloadAsync()
        }
      } catch (e) {
        throw new Error("OTA Update Error: " + e)
      }
    }
  }

  React.useEffect(() => {
    checkForUpdateAsync()
  }, [])

  return updateFound
}