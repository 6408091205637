import { TouchableOpacity, View, StyleSheet, ColorValue } from "react-native"
import { TeamColor } from "@common/utils/team"
import React from "react"
import { Avatar } from "react-native-elements"
import { noop } from "lodash"
import { Text } from "@common/components"
import { TeamColorsEnum } from "@gql"
import { theme } from "@common/theme"
interface Props {
  username?: string
  teamColor?: TeamColorsEnum
  handleDelete?: () => void
  handleSwitch?: () => void
  textColor?: ColorValue
  showText?: boolean
}

export const PlayerChip: React.FC<Props> = ({
  username,
  teamColor,
  handleDelete,
  handleSwitch,
  showText = true,
  textColor = theme.color.text_primary,
  children,
}) => {
  const myUsername = username ?? (children ? String(children) : "")
  const color = teamColor
    ? teamColor === TeamColorsEnum.Red
      ? TeamColor[TeamColorsEnum.Red]
      : TeamColor[TeamColorsEnum.Blue]
    : "grey"

  const Wrapper: React.FC = ({ children }) => {
    if (handleDelete || handleSwitch) {
      return (
        <TouchableOpacity
          style={styles.wrapper}
          onPress={handleSwitch ? handleSwitch : noop}
          onLongPress={handleDelete ? handleDelete : noop}
        >
          {children}
        </TouchableOpacity>
      )
    } else {
      return <View style={styles.wrapper}>{children}</View>
    }
  }

  return (
    <Wrapper>
      <Avatar
        rounded
        size="small"
        title={myUsername.charAt(0).toLocaleUpperCase()}
        containerStyle={{
          backgroundColor: color,
        }}
        titleStyle={{ fontSize: 20, fontWeight: "700", color: "white" }}
      />
      {showText && (
        <Text style={{ textAlign: "center", color: textColor }}>
          {myUsername}
        </Text>
      )}
    </Wrapper>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    margin: 2,
    flexDirection: "column",
    alignItems: "center",
  },
})
