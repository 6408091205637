import React from "react"
import { useTranslation } from "react-i18next"
import {
  Dimensions,
  TouchableOpacity,
  View,
  Image,
  Platform,
} from "react-native"
import ConfettiCannon from "react-native-confetti-cannon"
import { MotiView } from "moti"
import { FontAwesome } from "@expo/vector-icons"
import LottieView from "lottie-react-native"
import { Text } from "@common/components"
import { theme } from "@common/theme"
import { usePlaySound } from "@common/hooks/usePlaySound"

const { width } = Dimensions.get("window")

interface IProps {
  setHideMyTeamWon: (newVal: boolean) => void
}

export const WinScreen: React.FC<IProps> = ({ setHideMyTeamWon }) => {
  const { t } = useTranslation()
  usePlaySound("team_won", true, true, "long")

  return (
    <TouchableOpacity
      style={{ flex: 1, overflow: "hidden" }}
      onPress={() => setHideMyTeamWon(true)}
    >
      <ConfettiCannon
        count={100}
        origin={{ x: -10, y: 0 }}
        autoStartDelay={4400}
        fallSpeed={2400}
      />
      <ConfettiCannon
        count={100}
        origin={{ x: width + 10, y: 0 }}
        autoStartDelay={4700}
        fallSpeed={2000}
      />

      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        {Platform.OS === "web" ? (
          <Image
            style={{ position: "absolute", aspectRatio: 1, width: "80%" }}
            source={require("@assets/trophy.gif")}
          />
        ) : (
          <LottieView
            source={require("@assets/lottie/677-trophy.json")}
            autoPlay
          />
        )}
        <MotiView
          from={{ opacity: 0, rotate: "0deg", scale: 0 }}
          animate={{ opacity: 0.4, rotate: "1080deg", scale: 1.5 }}
          transition={{
            delay: 1000,
            // default settings for all style values
            type: "timing",
            duration: 4000,
          }}
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesome color="gold" name="sun-o" size={width} />
        </MotiView>

        <View
          style={{
            // backgroundColor: "blue",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: theme.color.text_secondary,

              fontSize: 50,
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {t("end.result.selfWin", "You WIN!")}
          </Text>
        </View>
      </View>
      <Text
        style={{
          color: theme.color.text_secondary,
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        {t("end.touchanywhere", "Touch anywhere to continue")}
      </Text>
    </TouchableOpacity>
  )
}
