import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "cards" */
export type Cards = {
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_allowed?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  player: Players;
  player_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  word: Scalars['String'];
};

/** aggregated selection of "cards" */
export type CardsAggregate = {
  aggregate?: Maybe<CardsAggregateFields>;
  nodes: Array<Cards>;
};

/** aggregate fields of "cards" */
export type CardsAggregateFields = {
  count: Scalars['Int'];
  max?: Maybe<CardsMaxFields>;
  min?: Maybe<CardsMinFields>;
};


/** aggregate fields of "cards" */
export type CardsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CardsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cards" */
export type CardsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CardsMaxOrderBy>;
  min?: InputMaybe<CardsMinOrderBy>;
};

/** input type for inserting array relation for remote table "cards" */
export type CardsArrRelInsertInput = {
  data: Array<CardsInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<CardsOnConflict>;
};

/** Boolean expression to filter rows from the table "cards". All fields are combined with a logical 'AND'. */
export type CardsBoolExp = {
  _and?: InputMaybe<Array<CardsBoolExp>>;
  _not?: InputMaybe<CardsBoolExp>;
  _or?: InputMaybe<Array<CardsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  game?: InputMaybe<GamesBoolExp>;
  game_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  is_allowed?: InputMaybe<BooleanComparisonExp>;
  player?: InputMaybe<PlayersBoolExp>;
  player_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  word?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cards" */
export enum CardsConstraint {
  /** unique or primary key constraint */
  CardsPkey = 'cards_pkey'
}

/** input type for inserting data into table "cards" */
export type CardsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<GamesObjRelInsertInput>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_allowed?: InputMaybe<Scalars['Boolean']>;
  player?: InputMaybe<PlayersObjRelInsertInput>;
  player_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  word?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CardsMaxFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  player_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  word?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cards" */
export type CardsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  player_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  word?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CardsMinFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  player_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  word?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cards" */
export type CardsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  player_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  word?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cards" */
export type CardsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cards>;
};

/** input type for inserting object relation for remote table "cards" */
export type CardsObjRelInsertInput = {
  data: CardsInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<CardsOnConflict>;
};

/** on conflict condition type for table "cards" */
export type CardsOnConflict = {
  constraint: CardsConstraint;
  update_columns?: Array<CardsUpdateColumn>;
  where?: InputMaybe<CardsBoolExp>;
};

/** Ordering options when selecting data from "cards". */
export type CardsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game?: InputMaybe<GamesOrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_allowed?: InputMaybe<OrderBy>;
  player?: InputMaybe<PlayersOrderBy>;
  player_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  word?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cards */
export type CardsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "cards" */
export enum CardsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAllowed = 'is_allowed',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Word = 'word'
}

/** input type for updating data in table "cards" */
export type CardsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_allowed?: InputMaybe<Scalars['Boolean']>;
  player_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  word?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cards" */
export enum CardsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsAllowed = 'is_allowed',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Word = 'word'
}

/** columns and relationships of "game_card_play_style" */
export type GameCardPlayStyle = {
  /** fetch data from the table: "games" */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: GamesAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "game_card_play_style" */
export type GameCardPlayStyleGamesArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


/** columns and relationships of "game_card_play_style" */
export type GameCardPlayStyleGamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};

/** aggregated selection of "game_card_play_style" */
export type GameCardPlayStyleAggregate = {
  aggregate?: Maybe<GameCardPlayStyleAggregateFields>;
  nodes: Array<GameCardPlayStyle>;
};

/** aggregate fields of "game_card_play_style" */
export type GameCardPlayStyleAggregateFields = {
  count: Scalars['Int'];
  max?: Maybe<GameCardPlayStyleMaxFields>;
  min?: Maybe<GameCardPlayStyleMinFields>;
};


/** aggregate fields of "game_card_play_style" */
export type GameCardPlayStyleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GameCardPlayStyleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "game_card_play_style". All fields are combined with a logical 'AND'. */
export type GameCardPlayStyleBoolExp = {
  _and?: InputMaybe<Array<GameCardPlayStyleBoolExp>>;
  _not?: InputMaybe<GameCardPlayStyleBoolExp>;
  _or?: InputMaybe<Array<GameCardPlayStyleBoolExp>>;
  games?: InputMaybe<GamesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "game_card_play_style" */
export enum GameCardPlayStyleConstraint {
  /** unique or primary key constraint */
  GameCardPlayStylePkey = 'game_card_play_style_pkey'
}

export enum GameCardPlayStyleEnum {
  HostProvidesWords = 'host_provides_words',
  PackWords = 'pack_words',
  PlayersSubmitWords = 'players_submit_words'
}

/** Boolean expression to compare columns of type "game_card_play_style_enum". All fields are combined with logical 'AND'. */
export type GameCardPlayStyleEnumComparisonExp = {
  _eq?: InputMaybe<GameCardPlayStyleEnum>;
  _in?: InputMaybe<Array<GameCardPlayStyleEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<GameCardPlayStyleEnum>;
  _nin?: InputMaybe<Array<GameCardPlayStyleEnum>>;
};

/** input type for inserting data into table "game_card_play_style" */
export type GameCardPlayStyleInsertInput = {
  games?: InputMaybe<GamesArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GameCardPlayStyleMaxFields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GameCardPlayStyleMinFields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "game_card_play_style" */
export type GameCardPlayStyleMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GameCardPlayStyle>;
};

/** input type for inserting object relation for remote table "game_card_play_style" */
export type GameCardPlayStyleObjRelInsertInput = {
  data: GameCardPlayStyleInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<GameCardPlayStyleOnConflict>;
};

/** on conflict condition type for table "game_card_play_style" */
export type GameCardPlayStyleOnConflict = {
  constraint: GameCardPlayStyleConstraint;
  update_columns?: Array<GameCardPlayStyleUpdateColumn>;
  where?: InputMaybe<GameCardPlayStyleBoolExp>;
};

/** Ordering options when selecting data from "game_card_play_style". */
export type GameCardPlayStyleOrderBy = {
  games_aggregate?: InputMaybe<GamesAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: game_card_play_style */
export type GameCardPlayStylePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "game_card_play_style" */
export enum GameCardPlayStyleSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "game_card_play_style" */
export type GameCardPlayStyleSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "game_card_play_style" */
export enum GameCardPlayStyleUpdateColumn {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "game_state" */
export type GameState = {
  value: Scalars['String'];
};

/** aggregated selection of "game_state" */
export type GameStateAggregate = {
  aggregate?: Maybe<GameStateAggregateFields>;
  nodes: Array<GameState>;
};

/** aggregate fields of "game_state" */
export type GameStateAggregateFields = {
  count: Scalars['Int'];
  max?: Maybe<GameStateMaxFields>;
  min?: Maybe<GameStateMinFields>;
};


/** aggregate fields of "game_state" */
export type GameStateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GameStateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "game_state". All fields are combined with a logical 'AND'. */
export type GameStateBoolExp = {
  _and?: InputMaybe<Array<GameStateBoolExp>>;
  _not?: InputMaybe<GameStateBoolExp>;
  _or?: InputMaybe<Array<GameStateBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "game_state" */
export enum GameStateConstraint {
  /** unique or primary key constraint */
  GameStatePkey = 'game_state_pkey'
}

export enum GameStateEnum {
  ActivePlay = 'active_play',
  CardSubmission = 'card_submission',
  Ended = 'ended',
  Lobby = 'lobby',
  TeamAssignment = 'team_assignment',
  TeamNameCreation = 'team_name_creation'
}

/** Boolean expression to compare columns of type "game_state_enum". All fields are combined with logical 'AND'. */
export type GameStateEnumComparisonExp = {
  _eq?: InputMaybe<GameStateEnum>;
  _in?: InputMaybe<Array<GameStateEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<GameStateEnum>;
  _nin?: InputMaybe<Array<GameStateEnum>>;
};

/** input type for inserting data into table "game_state" */
export type GameStateInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GameStateMaxFields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GameStateMinFields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "game_state" */
export type GameStateMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GameState>;
};

/** input type for inserting object relation for remote table "game_state" */
export type GameStateObjRelInsertInput = {
  data: GameStateInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<GameStateOnConflict>;
};

/** on conflict condition type for table "game_state" */
export type GameStateOnConflict = {
  constraint: GameStateConstraint;
  update_columns?: Array<GameStateUpdateColumn>;
  where?: InputMaybe<GameStateBoolExp>;
};

/** Ordering options when selecting data from "game_state". */
export type GameStateOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: game_state */
export type GameStatePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "game_state" */
export enum GameStateSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "game_state" */
export type GameStateSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "game_state" */
export enum GameStateUpdateColumn {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "games" */
export type Games = {
  allow_card_skips: Scalars['Boolean'];
  card_play_style: GameCardPlayStyleEnum;
  /** An array relationship */
  cards: Array<Cards>;
  /** An aggregate relationship */
  cards_aggregate: CardsAggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game_card_play_style: GameCardPlayStyle;
  /** An object relationship */
  game_state: GameState;
  /** An object relationship */
  host?: Maybe<Players>;
  host_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  join_code?: Maybe<Scalars['String']>;
  num_entries_per_player?: Maybe<Scalars['Int']>;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  players_aggregate: PlayersAggregate;
  /** An array relationship */
  rounds: Array<Rounds>;
  /** An aggregate relationship */
  rounds_aggregate: RoundsAggregate;
  screen_cards: Scalars['Boolean'];
  seconds_per_turn?: Maybe<Scalars['Int']>;
  starting_letter?: Maybe<Scalars['String']>;
  state: GameStateEnum;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: TeamsAggregate;
  /** An array relationship */
  turns: Array<Turns>;
  /** An aggregate relationship */
  turns_aggregate: TurnsAggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "games" */
export type GamesCardsArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesCardsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesPlayersArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


/** columns and relationships of "games" */
export type GamesPlayersAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


/** columns and relationships of "games" */
export type GamesRoundsArgs = {
  distinct_on?: InputMaybe<Array<RoundsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundsOrderBy>>;
  where?: InputMaybe<RoundsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesRoundsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RoundsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundsOrderBy>>;
  where?: InputMaybe<RoundsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesTeamsArgs = {
  distinct_on?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesTurnsArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


/** columns and relationships of "games" */
export type GamesTurnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};

/** aggregated selection of "games" */
export type GamesAggregate = {
  aggregate?: Maybe<GamesAggregateFields>;
  nodes: Array<Games>;
};

/** aggregate fields of "games" */
export type GamesAggregateFields = {
  avg?: Maybe<GamesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GamesMaxFields>;
  min?: Maybe<GamesMinFields>;
  stddev?: Maybe<GamesStddevFields>;
  stddev_pop?: Maybe<GamesStddevPopFields>;
  stddev_samp?: Maybe<GamesStddevSampFields>;
  sum?: Maybe<GamesSumFields>;
  var_pop?: Maybe<GamesVarPopFields>;
  var_samp?: Maybe<GamesVarSampFields>;
  variance?: Maybe<GamesVarianceFields>;
};


/** aggregate fields of "games" */
export type GamesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GamesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "games" */
export type GamesAggregateOrderBy = {
  avg?: InputMaybe<GamesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GamesMaxOrderBy>;
  min?: InputMaybe<GamesMinOrderBy>;
  stddev?: InputMaybe<GamesStddevOrderBy>;
  stddev_pop?: InputMaybe<GamesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<GamesStddevSampOrderBy>;
  sum?: InputMaybe<GamesSumOrderBy>;
  var_pop?: InputMaybe<GamesVarPopOrderBy>;
  var_samp?: InputMaybe<GamesVarSampOrderBy>;
  variance?: InputMaybe<GamesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "games" */
export type GamesArrRelInsertInput = {
  data: Array<GamesInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<GamesOnConflict>;
};

/** aggregate avg on columns */
export type GamesAvgFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "games" */
export type GamesAvgOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "games". All fields are combined with a logical 'AND'. */
export type GamesBoolExp = {
  _and?: InputMaybe<Array<GamesBoolExp>>;
  _not?: InputMaybe<GamesBoolExp>;
  _or?: InputMaybe<Array<GamesBoolExp>>;
  allow_card_skips?: InputMaybe<BooleanComparisonExp>;
  card_play_style?: InputMaybe<GameCardPlayStyleEnumComparisonExp>;
  cards?: InputMaybe<CardsBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  game_card_play_style?: InputMaybe<GameCardPlayStyleBoolExp>;
  game_state?: InputMaybe<GameStateBoolExp>;
  host?: InputMaybe<PlayersBoolExp>;
  host_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  join_code?: InputMaybe<StringComparisonExp>;
  num_entries_per_player?: InputMaybe<IntComparisonExp>;
  players?: InputMaybe<PlayersBoolExp>;
  rounds?: InputMaybe<RoundsBoolExp>;
  screen_cards?: InputMaybe<BooleanComparisonExp>;
  seconds_per_turn?: InputMaybe<IntComparisonExp>;
  starting_letter?: InputMaybe<StringComparisonExp>;
  state?: InputMaybe<GameStateEnumComparisonExp>;
  teams?: InputMaybe<TeamsBoolExp>;
  turns?: InputMaybe<TurnsBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "games" */
export enum GamesConstraint {
  /** unique or primary key constraint */
  GamesJoinCodeIdx = 'games_join_code_idx',
  /** unique or primary key constraint */
  GamesJoinCodeKey = 'games_join_code_key',
  /** unique or primary key constraint */
  GamesPkey = 'games_pkey'
}

/** input type for incrementing numeric columns in table "games" */
export type GamesIncInput = {
  num_entries_per_player?: InputMaybe<Scalars['Int']>;
  seconds_per_turn?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "games" */
export type GamesInsertInput = {
  allow_card_skips?: InputMaybe<Scalars['Boolean']>;
  card_play_style?: InputMaybe<GameCardPlayStyleEnum>;
  cards?: InputMaybe<CardsArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_card_play_style?: InputMaybe<GameCardPlayStyleObjRelInsertInput>;
  game_state?: InputMaybe<GameStateObjRelInsertInput>;
  host?: InputMaybe<PlayersObjRelInsertInput>;
  host_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  join_code?: InputMaybe<Scalars['String']>;
  num_entries_per_player?: InputMaybe<Scalars['Int']>;
  players?: InputMaybe<PlayersArrRelInsertInput>;
  rounds?: InputMaybe<RoundsArrRelInsertInput>;
  screen_cards?: InputMaybe<Scalars['Boolean']>;
  seconds_per_turn?: InputMaybe<Scalars['Int']>;
  starting_letter?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<GameStateEnum>;
  teams?: InputMaybe<TeamsArrRelInsertInput>;
  turns?: InputMaybe<TurnsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type GamesMaxFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  host_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  join_code?: Maybe<Scalars['String']>;
  num_entries_per_player?: Maybe<Scalars['Int']>;
  seconds_per_turn?: Maybe<Scalars['Int']>;
  starting_letter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "games" */
export type GamesMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  host_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  join_code?: InputMaybe<OrderBy>;
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
  starting_letter?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GamesMinFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  host_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  join_code?: Maybe<Scalars['String']>;
  num_entries_per_player?: Maybe<Scalars['Int']>;
  seconds_per_turn?: Maybe<Scalars['Int']>;
  starting_letter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "games" */
export type GamesMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  host_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  join_code?: InputMaybe<OrderBy>;
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
  starting_letter?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "games" */
export type GamesMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Games>;
};

/** input type for inserting object relation for remote table "games" */
export type GamesObjRelInsertInput = {
  data: GamesInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<GamesOnConflict>;
};

/** on conflict condition type for table "games" */
export type GamesOnConflict = {
  constraint: GamesConstraint;
  update_columns?: Array<GamesUpdateColumn>;
  where?: InputMaybe<GamesBoolExp>;
};

/** Ordering options when selecting data from "games". */
export type GamesOrderBy = {
  allow_card_skips?: InputMaybe<OrderBy>;
  card_play_style?: InputMaybe<OrderBy>;
  cards_aggregate?: InputMaybe<CardsAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  game_card_play_style?: InputMaybe<GameCardPlayStyleOrderBy>;
  game_state?: InputMaybe<GameStateOrderBy>;
  host?: InputMaybe<PlayersOrderBy>;
  host_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  join_code?: InputMaybe<OrderBy>;
  num_entries_per_player?: InputMaybe<OrderBy>;
  players_aggregate?: InputMaybe<PlayersAggregateOrderBy>;
  rounds_aggregate?: InputMaybe<RoundsAggregateOrderBy>;
  screen_cards?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
  starting_letter?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  teams_aggregate?: InputMaybe<TeamsAggregateOrderBy>;
  turns_aggregate?: InputMaybe<TurnsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: games */
export type GamesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "games" */
export enum GamesSelectColumn {
  /** column name */
  AllowCardSkips = 'allow_card_skips',
  /** column name */
  CardPlayStyle = 'card_play_style',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  JoinCode = 'join_code',
  /** column name */
  NumEntriesPerPlayer = 'num_entries_per_player',
  /** column name */
  ScreenCards = 'screen_cards',
  /** column name */
  SecondsPerTurn = 'seconds_per_turn',
  /** column name */
  StartingLetter = 'starting_letter',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "games" */
export type GamesSetInput = {
  allow_card_skips?: InputMaybe<Scalars['Boolean']>;
  card_play_style?: InputMaybe<GameCardPlayStyleEnum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  host_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  join_code?: InputMaybe<Scalars['String']>;
  num_entries_per_player?: InputMaybe<Scalars['Int']>;
  screen_cards?: InputMaybe<Scalars['Boolean']>;
  seconds_per_turn?: InputMaybe<Scalars['Int']>;
  starting_letter?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<GameStateEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type GamesStddevFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "games" */
export type GamesStddevOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type GamesStddevPopFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "games" */
export type GamesStddevPopOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type GamesStddevSampFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "games" */
export type GamesStddevSampOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type GamesSumFields = {
  num_entries_per_player?: Maybe<Scalars['Int']>;
  seconds_per_turn?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "games" */
export type GamesSumOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** update columns of table "games" */
export enum GamesUpdateColumn {
  /** column name */
  AllowCardSkips = 'allow_card_skips',
  /** column name */
  CardPlayStyle = 'card_play_style',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  JoinCode = 'join_code',
  /** column name */
  NumEntriesPerPlayer = 'num_entries_per_player',
  /** column name */
  ScreenCards = 'screen_cards',
  /** column name */
  SecondsPerTurn = 'seconds_per_turn',
  /** column name */
  StartingLetter = 'starting_letter',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type GamesVarPopFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "games" */
export type GamesVarPopOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type GamesVarSampFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "games" */
export type GamesVarSampOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GamesVarianceFields = {
  num_entries_per_player?: Maybe<Scalars['Float']>;
  seconds_per_turn?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "games" */
export type GamesVarianceOrderBy = {
  num_entries_per_player?: InputMaybe<OrderBy>;
  seconds_per_turn?: InputMaybe<OrderBy>;
};

export type JoinGameOutput = {
  id: Scalars['String'];
  jwt_token: Scalars['String'];
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type MutationRoot = {
  /** delete data from the table: "cards" */
  delete_cards?: Maybe<CardsMutationResponse>;
  /** delete single row from the table: "cards" */
  delete_cards_by_pk?: Maybe<Cards>;
  /** delete data from the table: "game_card_play_style" */
  delete_game_card_play_style?: Maybe<GameCardPlayStyleMutationResponse>;
  /** delete single row from the table: "game_card_play_style" */
  delete_game_card_play_style_by_pk?: Maybe<GameCardPlayStyle>;
  /** delete data from the table: "game_state" */
  delete_game_state?: Maybe<GameStateMutationResponse>;
  /** delete single row from the table: "game_state" */
  delete_game_state_by_pk?: Maybe<GameState>;
  /** delete data from the table: "games" */
  delete_games?: Maybe<GamesMutationResponse>;
  /** delete single row from the table: "games" */
  delete_games_by_pk?: Maybe<Games>;
  /** delete data from the table: "players" */
  delete_players?: Maybe<PlayersMutationResponse>;
  /** delete single row from the table: "players" */
  delete_players_by_pk?: Maybe<Players>;
  /** delete data from the table: "rounds" */
  delete_rounds?: Maybe<RoundsMutationResponse>;
  /** delete single row from the table: "rounds" */
  delete_rounds_by_pk?: Maybe<Rounds>;
  /** delete data from the table: "team_colors" */
  delete_team_colors?: Maybe<TeamColorsMutationResponse>;
  /** delete single row from the table: "team_colors" */
  delete_team_colors_by_pk?: Maybe<TeamColors>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<TeamsMutationResponse>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "turn_scorings" */
  delete_turn_scorings?: Maybe<TurnScoringsMutationResponse>;
  /** delete single row from the table: "turn_scorings" */
  delete_turn_scorings_by_pk?: Maybe<TurnScorings>;
  /** delete data from the table: "turns" */
  delete_turns?: Maybe<TurnsMutationResponse>;
  /** delete single row from the table: "turns" */
  delete_turns_by_pk?: Maybe<Turns>;
  /** insert data into the table: "cards" */
  insert_cards?: Maybe<CardsMutationResponse>;
  /** insert a single row into the table: "cards" */
  insert_cards_one?: Maybe<Cards>;
  /** insert data into the table: "game_card_play_style" */
  insert_game_card_play_style?: Maybe<GameCardPlayStyleMutationResponse>;
  /** insert a single row into the table: "game_card_play_style" */
  insert_game_card_play_style_one?: Maybe<GameCardPlayStyle>;
  /** insert data into the table: "game_state" */
  insert_game_state?: Maybe<GameStateMutationResponse>;
  /** insert a single row into the table: "game_state" */
  insert_game_state_one?: Maybe<GameState>;
  /** insert data into the table: "games" */
  insert_games?: Maybe<GamesMutationResponse>;
  /** insert a single row into the table: "games" */
  insert_games_one?: Maybe<Games>;
  /** insert data into the table: "players" */
  insert_players?: Maybe<PlayersMutationResponse>;
  /** insert a single row into the table: "players" */
  insert_players_one?: Maybe<Players>;
  /** insert data into the table: "rounds" */
  insert_rounds?: Maybe<RoundsMutationResponse>;
  /** insert a single row into the table: "rounds" */
  insert_rounds_one?: Maybe<Rounds>;
  /** insert data into the table: "team_colors" */
  insert_team_colors?: Maybe<TeamColorsMutationResponse>;
  /** insert a single row into the table: "team_colors" */
  insert_team_colors_one?: Maybe<TeamColors>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<TeamsMutationResponse>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "turn_scorings" */
  insert_turn_scorings?: Maybe<TurnScoringsMutationResponse>;
  /** insert a single row into the table: "turn_scorings" */
  insert_turn_scorings_one?: Maybe<TurnScorings>;
  /** insert data into the table: "turns" */
  insert_turns?: Maybe<TurnsMutationResponse>;
  /** insert a single row into the table: "turns" */
  insert_turns_one?: Maybe<Turns>;
  joinGame?: Maybe<JoinGameOutput>;
  newGame?: Maybe<NewGameOutput>;
  /** update data of the table: "cards" */
  update_cards?: Maybe<CardsMutationResponse>;
  /** update single row of the table: "cards" */
  update_cards_by_pk?: Maybe<Cards>;
  /** update data of the table: "game_card_play_style" */
  update_game_card_play_style?: Maybe<GameCardPlayStyleMutationResponse>;
  /** update single row of the table: "game_card_play_style" */
  update_game_card_play_style_by_pk?: Maybe<GameCardPlayStyle>;
  /** update data of the table: "game_state" */
  update_game_state?: Maybe<GameStateMutationResponse>;
  /** update single row of the table: "game_state" */
  update_game_state_by_pk?: Maybe<GameState>;
  /** update data of the table: "games" */
  update_games?: Maybe<GamesMutationResponse>;
  /** update single row of the table: "games" */
  update_games_by_pk?: Maybe<Games>;
  /** update data of the table: "players" */
  update_players?: Maybe<PlayersMutationResponse>;
  /** update single row of the table: "players" */
  update_players_by_pk?: Maybe<Players>;
  /** update data of the table: "rounds" */
  update_rounds?: Maybe<RoundsMutationResponse>;
  /** update single row of the table: "rounds" */
  update_rounds_by_pk?: Maybe<Rounds>;
  /** update data of the table: "team_colors" */
  update_team_colors?: Maybe<TeamColorsMutationResponse>;
  /** update single row of the table: "team_colors" */
  update_team_colors_by_pk?: Maybe<TeamColors>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<TeamsMutationResponse>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update data of the table: "turn_scorings" */
  update_turn_scorings?: Maybe<TurnScoringsMutationResponse>;
  /** update single row of the table: "turn_scorings" */
  update_turn_scorings_by_pk?: Maybe<TurnScorings>;
  /** update data of the table: "turns" */
  update_turns?: Maybe<TurnsMutationResponse>;
  /** update single row of the table: "turns" */
  update_turns_by_pk?: Maybe<Turns>;
};


/** mutation root */
export type MutationRootDeleteCardsArgs = {
  where: CardsBoolExp;
};


/** mutation root */
export type MutationRootDeleteCardsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteGameCardPlayStyleArgs = {
  where: GameCardPlayStyleBoolExp;
};


/** mutation root */
export type MutationRootDeleteGameCardPlayStyleByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteGameStateArgs = {
  where: GameStateBoolExp;
};


/** mutation root */
export type MutationRootDeleteGameStateByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteGamesArgs = {
  where: GamesBoolExp;
};


/** mutation root */
export type MutationRootDeleteGamesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePlayersArgs = {
  where: PlayersBoolExp;
};


/** mutation root */
export type MutationRootDeletePlayersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteRoundsArgs = {
  where: RoundsBoolExp;
};


/** mutation root */
export type MutationRootDeleteRoundsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTeamColorsArgs = {
  where: TeamColorsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTeamColorsByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteTeamsArgs = {
  where: TeamsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTeamsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTurnScoringsArgs = {
  where: TurnScoringsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTurnScoringsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTurnsArgs = {
  where: TurnsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTurnsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootInsertCardsArgs = {
  objects: Array<CardsInsertInput>;
  on_conflict?: InputMaybe<CardsOnConflict>;
};


/** mutation root */
export type MutationRootInsertCardsOneArgs = {
  object: CardsInsertInput;
  on_conflict?: InputMaybe<CardsOnConflict>;
};


/** mutation root */
export type MutationRootInsertGameCardPlayStyleArgs = {
  objects: Array<GameCardPlayStyleInsertInput>;
  on_conflict?: InputMaybe<GameCardPlayStyleOnConflict>;
};


/** mutation root */
export type MutationRootInsertGameCardPlayStyleOneArgs = {
  object: GameCardPlayStyleInsertInput;
  on_conflict?: InputMaybe<GameCardPlayStyleOnConflict>;
};


/** mutation root */
export type MutationRootInsertGameStateArgs = {
  objects: Array<GameStateInsertInput>;
  on_conflict?: InputMaybe<GameStateOnConflict>;
};


/** mutation root */
export type MutationRootInsertGameStateOneArgs = {
  object: GameStateInsertInput;
  on_conflict?: InputMaybe<GameStateOnConflict>;
};


/** mutation root */
export type MutationRootInsertGamesArgs = {
  objects: Array<GamesInsertInput>;
  on_conflict?: InputMaybe<GamesOnConflict>;
};


/** mutation root */
export type MutationRootInsertGamesOneArgs = {
  object: GamesInsertInput;
  on_conflict?: InputMaybe<GamesOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlayersArgs = {
  objects: Array<PlayersInsertInput>;
  on_conflict?: InputMaybe<PlayersOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlayersOneArgs = {
  object: PlayersInsertInput;
  on_conflict?: InputMaybe<PlayersOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoundsArgs = {
  objects: Array<RoundsInsertInput>;
  on_conflict?: InputMaybe<RoundsOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoundsOneArgs = {
  object: RoundsInsertInput;
  on_conflict?: InputMaybe<RoundsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTeamColorsArgs = {
  objects: Array<TeamColorsInsertInput>;
  on_conflict?: InputMaybe<TeamColorsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTeamColorsOneArgs = {
  object: TeamColorsInsertInput;
  on_conflict?: InputMaybe<TeamColorsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTeamsArgs = {
  objects: Array<TeamsInsertInput>;
  on_conflict?: InputMaybe<TeamsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTeamsOneArgs = {
  object: TeamsInsertInput;
  on_conflict?: InputMaybe<TeamsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTurnScoringsArgs = {
  objects: Array<TurnScoringsInsertInput>;
  on_conflict?: InputMaybe<TurnScoringsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTurnScoringsOneArgs = {
  object: TurnScoringsInsertInput;
  on_conflict?: InputMaybe<TurnScoringsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTurnsArgs = {
  objects: Array<TurnsInsertInput>;
  on_conflict?: InputMaybe<TurnsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTurnsOneArgs = {
  object: TurnsInsertInput;
  on_conflict?: InputMaybe<TurnsOnConflict>;
};


/** mutation root */
export type MutationRootJoinGameArgs = {
  clientUuid: Scalars['String'];
  gameId: Scalars['String'];
};


/** mutation root */
export type MutationRootNewGameArgs = {
  clientUuid: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateCardsArgs = {
  _set?: InputMaybe<CardsSetInput>;
  where: CardsBoolExp;
};


/** mutation root */
export type MutationRootUpdateCardsByPkArgs = {
  _set?: InputMaybe<CardsSetInput>;
  pk_columns: CardsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGameCardPlayStyleArgs = {
  _set?: InputMaybe<GameCardPlayStyleSetInput>;
  where: GameCardPlayStyleBoolExp;
};


/** mutation root */
export type MutationRootUpdateGameCardPlayStyleByPkArgs = {
  _set?: InputMaybe<GameCardPlayStyleSetInput>;
  pk_columns: GameCardPlayStylePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGameStateArgs = {
  _set?: InputMaybe<GameStateSetInput>;
  where: GameStateBoolExp;
};


/** mutation root */
export type MutationRootUpdateGameStateByPkArgs = {
  _set?: InputMaybe<GameStateSetInput>;
  pk_columns: GameStatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGamesArgs = {
  _inc?: InputMaybe<GamesIncInput>;
  _set?: InputMaybe<GamesSetInput>;
  where: GamesBoolExp;
};


/** mutation root */
export type MutationRootUpdateGamesByPkArgs = {
  _inc?: InputMaybe<GamesIncInput>;
  _set?: InputMaybe<GamesSetInput>;
  pk_columns: GamesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlayersArgs = {
  _inc?: InputMaybe<PlayersIncInput>;
  _set?: InputMaybe<PlayersSetInput>;
  where: PlayersBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlayersByPkArgs = {
  _inc?: InputMaybe<PlayersIncInput>;
  _set?: InputMaybe<PlayersSetInput>;
  pk_columns: PlayersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRoundsArgs = {
  _inc?: InputMaybe<RoundsIncInput>;
  _set?: InputMaybe<RoundsSetInput>;
  where: RoundsBoolExp;
};


/** mutation root */
export type MutationRootUpdateRoundsByPkArgs = {
  _inc?: InputMaybe<RoundsIncInput>;
  _set?: InputMaybe<RoundsSetInput>;
  pk_columns: RoundsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTeamColorsArgs = {
  _set?: InputMaybe<TeamColorsSetInput>;
  where: TeamColorsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTeamColorsByPkArgs = {
  _set?: InputMaybe<TeamColorsSetInput>;
  pk_columns: TeamColorsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTeamsArgs = {
  _set?: InputMaybe<TeamsSetInput>;
  where: TeamsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTeamsByPkArgs = {
  _set?: InputMaybe<TeamsSetInput>;
  pk_columns: TeamsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTurnScoringsArgs = {
  _inc?: InputMaybe<TurnScoringsIncInput>;
  _set?: InputMaybe<TurnScoringsSetInput>;
  where: TurnScoringsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTurnScoringsByPkArgs = {
  _inc?: InputMaybe<TurnScoringsIncInput>;
  _set?: InputMaybe<TurnScoringsSetInput>;
  pk_columns: TurnScoringsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTurnsArgs = {
  _append?: InputMaybe<TurnsAppendInput>;
  _delete_at_path?: InputMaybe<TurnsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TurnsDeleteElemInput>;
  _delete_key?: InputMaybe<TurnsDeleteKeyInput>;
  _inc?: InputMaybe<TurnsIncInput>;
  _prepend?: InputMaybe<TurnsPrependInput>;
  _set?: InputMaybe<TurnsSetInput>;
  where: TurnsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTurnsByPkArgs = {
  _append?: InputMaybe<TurnsAppendInput>;
  _delete_at_path?: InputMaybe<TurnsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TurnsDeleteElemInput>;
  _delete_key?: InputMaybe<TurnsDeleteKeyInput>;
  _inc?: InputMaybe<TurnsIncInput>;
  _prepend?: InputMaybe<TurnsPrependInput>;
  _set?: InputMaybe<TurnsSetInput>;
  pk_columns: TurnsPkColumnsInput;
};

export type NewGameOutput = {
  id: Scalars['String'];
  jwt_token: Scalars['String'];
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "players" */
export type Players = {
  /** An array relationship */
  cards: Array<Cards>;
  /** An aggregate relationship */
  cards_aggregate: CardsAggregate;
  client_uuid?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  /** fetch data from the table: "games" */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: GamesAggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id?: Maybe<Scalars['uuid']>;
  team_sequence?: Maybe<Scalars['Int']>;
  /** An array relationship */
  turns: Array<Turns>;
  /** An aggregate relationship */
  turns_aggregate: TurnsAggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};


/** columns and relationships of "players" */
export type PlayersCardsArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


/** columns and relationships of "players" */
export type PlayersCardsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


/** columns and relationships of "players" */
export type PlayersGamesArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


/** columns and relationships of "players" */
export type PlayersGamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


/** columns and relationships of "players" */
export type PlayersTurnsArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


/** columns and relationships of "players" */
export type PlayersTurnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};

/** aggregated selection of "players" */
export type PlayersAggregate = {
  aggregate?: Maybe<PlayersAggregateFields>;
  nodes: Array<Players>;
};

/** aggregate fields of "players" */
export type PlayersAggregateFields = {
  avg?: Maybe<PlayersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PlayersMaxFields>;
  min?: Maybe<PlayersMinFields>;
  stddev?: Maybe<PlayersStddevFields>;
  stddev_pop?: Maybe<PlayersStddevPopFields>;
  stddev_samp?: Maybe<PlayersStddevSampFields>;
  sum?: Maybe<PlayersSumFields>;
  var_pop?: Maybe<PlayersVarPopFields>;
  var_samp?: Maybe<PlayersVarSampFields>;
  variance?: Maybe<PlayersVarianceFields>;
};


/** aggregate fields of "players" */
export type PlayersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "players" */
export type PlayersAggregateOrderBy = {
  avg?: InputMaybe<PlayersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlayersMaxOrderBy>;
  min?: InputMaybe<PlayersMinOrderBy>;
  stddev?: InputMaybe<PlayersStddevOrderBy>;
  stddev_pop?: InputMaybe<PlayersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlayersStddevSampOrderBy>;
  sum?: InputMaybe<PlayersSumOrderBy>;
  var_pop?: InputMaybe<PlayersVarPopOrderBy>;
  var_samp?: InputMaybe<PlayersVarSampOrderBy>;
  variance?: InputMaybe<PlayersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "players" */
export type PlayersArrRelInsertInput = {
  data: Array<PlayersInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<PlayersOnConflict>;
};

/** aggregate avg on columns */
export type PlayersAvgFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "players" */
export type PlayersAvgOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "players". All fields are combined with a logical 'AND'. */
export type PlayersBoolExp = {
  _and?: InputMaybe<Array<PlayersBoolExp>>;
  _not?: InputMaybe<PlayersBoolExp>;
  _or?: InputMaybe<Array<PlayersBoolExp>>;
  cards?: InputMaybe<CardsBoolExp>;
  client_uuid?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  game?: InputMaybe<GamesBoolExp>;
  game_id?: InputMaybe<UuidComparisonExp>;
  games?: InputMaybe<GamesBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  team?: InputMaybe<TeamsBoolExp>;
  team_id?: InputMaybe<UuidComparisonExp>;
  team_sequence?: InputMaybe<IntComparisonExp>;
  turns?: InputMaybe<TurnsBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  username?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "players" */
export enum PlayersConstraint {
  /** unique or primary key constraint */
  PlayersClientUuidGameIdKey = 'players_client_uuid_game_id_key',
  /** unique or primary key constraint */
  PlayersPkey = 'players_pkey'
}

/** input type for incrementing numeric columns in table "players" */
export type PlayersIncInput = {
  team_sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "players" */
export type PlayersInsertInput = {
  cards?: InputMaybe<CardsArrRelInsertInput>;
  client_uuid?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<GamesObjRelInsertInput>;
  game_id?: InputMaybe<Scalars['uuid']>;
  games?: InputMaybe<GamesArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_sequence?: InputMaybe<Scalars['Int']>;
  turns?: InputMaybe<TurnsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PlayersMaxFields = {
  client_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "players" */
export type PlayersMaxOrderBy = {
  client_uuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  team_id?: InputMaybe<OrderBy>;
  team_sequence?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PlayersMinFields = {
  client_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  team_sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "players" */
export type PlayersMinOrderBy = {
  client_uuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  team_id?: InputMaybe<OrderBy>;
  team_sequence?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "players" */
export type PlayersMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Players>;
};

/** input type for inserting object relation for remote table "players" */
export type PlayersObjRelInsertInput = {
  data: PlayersInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<PlayersOnConflict>;
};

/** on conflict condition type for table "players" */
export type PlayersOnConflict = {
  constraint: PlayersConstraint;
  update_columns?: Array<PlayersUpdateColumn>;
  where?: InputMaybe<PlayersBoolExp>;
};

/** Ordering options when selecting data from "players". */
export type PlayersOrderBy = {
  cards_aggregate?: InputMaybe<CardsAggregateOrderBy>;
  client_uuid?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  game?: InputMaybe<GamesOrderBy>;
  game_id?: InputMaybe<OrderBy>;
  games_aggregate?: InputMaybe<GamesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  team_id?: InputMaybe<OrderBy>;
  team_sequence?: InputMaybe<OrderBy>;
  turns_aggregate?: InputMaybe<TurnsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: players */
export type PlayersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "players" */
export enum PlayersSelectColumn {
  /** column name */
  ClientUuid = 'client_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamSequence = 'team_sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "players" */
export type PlayersSetInput = {
  client_uuid?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  team_sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PlayersStddevFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "players" */
export type PlayersStddevOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PlayersStddevPopFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "players" */
export type PlayersStddevPopOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PlayersStddevSampFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "players" */
export type PlayersStddevSampOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type PlayersSumFields = {
  team_sequence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "players" */
export type PlayersSumOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** update columns of table "players" */
export enum PlayersUpdateColumn {
  /** column name */
  ClientUuid = 'client_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamSequence = 'team_sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** aggregate var_pop on columns */
export type PlayersVarPopFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "players" */
export type PlayersVarPopOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PlayersVarSampFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "players" */
export type PlayersVarSampOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PlayersVarianceFields = {
  team_sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "players" */
export type PlayersVarianceOrderBy = {
  team_sequence?: InputMaybe<OrderBy>;
};

export type QueryRoot = {
  /** An array relationship */
  cards: Array<Cards>;
  /** An aggregate relationship */
  cards_aggregate: CardsAggregate;
  /** fetch data from the table: "cards" using primary key columns */
  cards_by_pk?: Maybe<Cards>;
  /** fetch data from the table: "game_card_play_style" */
  game_card_play_style: Array<GameCardPlayStyle>;
  /** fetch aggregated fields from the table: "game_card_play_style" */
  game_card_play_style_aggregate: GameCardPlayStyleAggregate;
  /** fetch data from the table: "game_card_play_style" using primary key columns */
  game_card_play_style_by_pk?: Maybe<GameCardPlayStyle>;
  /** fetch data from the table: "game_state" */
  game_state: Array<GameState>;
  /** fetch aggregated fields from the table: "game_state" */
  game_state_aggregate: GameStateAggregate;
  /** fetch data from the table: "game_state" using primary key columns */
  game_state_by_pk?: Maybe<GameState>;
  /** fetch data from the table: "games" */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: GamesAggregate;
  /** fetch data from the table: "games" using primary key columns */
  games_by_pk?: Maybe<Games>;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  players_aggregate: PlayersAggregate;
  /** fetch data from the table: "players" using primary key columns */
  players_by_pk?: Maybe<Players>;
  /** An array relationship */
  rounds: Array<Rounds>;
  /** An aggregate relationship */
  rounds_aggregate: RoundsAggregate;
  /** fetch data from the table: "rounds" using primary key columns */
  rounds_by_pk?: Maybe<Rounds>;
  /** fetch data from the table: "server_time" */
  server_time: Array<ServerTime>;
  /** fetch aggregated fields from the table: "server_time" */
  server_time_aggregate: ServerTimeAggregate;
  /** fetch data from the table: "team_colors" */
  team_colors: Array<TeamColors>;
  /** fetch aggregated fields from the table: "team_colors" */
  team_colors_aggregate: TeamColorsAggregate;
  /** fetch data from the table: "team_colors" using primary key columns */
  team_colors_by_pk?: Maybe<TeamColors>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: TeamsAggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "turn_scorings" */
  turn_scorings: Array<TurnScorings>;
  /** fetch aggregated fields from the table: "turn_scorings" */
  turn_scorings_aggregate: TurnScoringsAggregate;
  /** fetch data from the table: "turn_scorings" using primary key columns */
  turn_scorings_by_pk?: Maybe<TurnScorings>;
  /** An array relationship */
  turns: Array<Turns>;
  /** An aggregate relationship */
  turns_aggregate: TurnsAggregate;
  /** fetch data from the table: "turns" using primary key columns */
  turns_by_pk?: Maybe<Turns>;
};


export type QueryRootCardsArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


export type QueryRootCardsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


export type QueryRootCardsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGameCardPlayStyleArgs = {
  distinct_on?: InputMaybe<Array<GameCardPlayStyleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameCardPlayStyleOrderBy>>;
  where?: InputMaybe<GameCardPlayStyleBoolExp>;
};


export type QueryRootGameCardPlayStyleAggregateArgs = {
  distinct_on?: InputMaybe<Array<GameCardPlayStyleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameCardPlayStyleOrderBy>>;
  where?: InputMaybe<GameCardPlayStyleBoolExp>;
};


export type QueryRootGameCardPlayStyleByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootGameStateArgs = {
  distinct_on?: InputMaybe<Array<GameStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameStateOrderBy>>;
  where?: InputMaybe<GameStateBoolExp>;
};


export type QueryRootGameStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<GameStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameStateOrderBy>>;
  where?: InputMaybe<GameStateBoolExp>;
};


export type QueryRootGameStateByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootGamesArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


export type QueryRootGamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


export type QueryRootGamesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPlayersArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


export type QueryRootPlayersAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


export type QueryRootPlayersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootRoundsArgs = {
  distinct_on?: InputMaybe<Array<RoundsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundsOrderBy>>;
  where?: InputMaybe<RoundsBoolExp>;
};


export type QueryRootRoundsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RoundsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundsOrderBy>>;
  where?: InputMaybe<RoundsBoolExp>;
};


export type QueryRootRoundsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootServerTimeArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServerTimeOrderBy>>;
  where?: InputMaybe<ServerTimeBoolExp>;
};


export type QueryRootServerTimeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServerTimeOrderBy>>;
  where?: InputMaybe<ServerTimeBoolExp>;
};


export type QueryRootTeamColorsArgs = {
  distinct_on?: InputMaybe<Array<TeamColorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamColorsOrderBy>>;
  where?: InputMaybe<TeamColorsBoolExp>;
};


export type QueryRootTeamColorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TeamColorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamColorsOrderBy>>;
  where?: InputMaybe<TeamColorsBoolExp>;
};


export type QueryRootTeamColorsByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootTeamsArgs = {
  distinct_on?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};


export type QueryRootTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};


export type QueryRootTeamsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTurnScoringsArgs = {
  distinct_on?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnScoringsOrderBy>>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};


export type QueryRootTurnScoringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnScoringsOrderBy>>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};


export type QueryRootTurnScoringsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTurnsArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


export type QueryRootTurnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


export type QueryRootTurnsByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "rounds" */
export type Rounds = {
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  order_sequence: Scalars['Int'];
  /** An array relationship */
  turns: Array<Turns>;
  /** An aggregate relationship */
  turns_aggregate: TurnsAggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value: Scalars['String'];
};


/** columns and relationships of "rounds" */
export type RoundsTurnsArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


/** columns and relationships of "rounds" */
export type RoundsTurnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};

/** aggregated selection of "rounds" */
export type RoundsAggregate = {
  aggregate?: Maybe<RoundsAggregateFields>;
  nodes: Array<Rounds>;
};

/** aggregate fields of "rounds" */
export type RoundsAggregateFields = {
  avg?: Maybe<RoundsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RoundsMaxFields>;
  min?: Maybe<RoundsMinFields>;
  stddev?: Maybe<RoundsStddevFields>;
  stddev_pop?: Maybe<RoundsStddevPopFields>;
  stddev_samp?: Maybe<RoundsStddevSampFields>;
  sum?: Maybe<RoundsSumFields>;
  var_pop?: Maybe<RoundsVarPopFields>;
  var_samp?: Maybe<RoundsVarSampFields>;
  variance?: Maybe<RoundsVarianceFields>;
};


/** aggregate fields of "rounds" */
export type RoundsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RoundsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rounds" */
export type RoundsAggregateOrderBy = {
  avg?: InputMaybe<RoundsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RoundsMaxOrderBy>;
  min?: InputMaybe<RoundsMinOrderBy>;
  stddev?: InputMaybe<RoundsStddevOrderBy>;
  stddev_pop?: InputMaybe<RoundsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RoundsStddevSampOrderBy>;
  sum?: InputMaybe<RoundsSumOrderBy>;
  var_pop?: InputMaybe<RoundsVarPopOrderBy>;
  var_samp?: InputMaybe<RoundsVarSampOrderBy>;
  variance?: InputMaybe<RoundsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "rounds" */
export type RoundsArrRelInsertInput = {
  data: Array<RoundsInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<RoundsOnConflict>;
};

/** aggregate avg on columns */
export type RoundsAvgFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rounds" */
export type RoundsAvgOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "rounds". All fields are combined with a logical 'AND'. */
export type RoundsBoolExp = {
  _and?: InputMaybe<Array<RoundsBoolExp>>;
  _not?: InputMaybe<RoundsBoolExp>;
  _or?: InputMaybe<Array<RoundsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  game?: InputMaybe<GamesBoolExp>;
  game_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  order_sequence?: InputMaybe<IntComparisonExp>;
  turns?: InputMaybe<TurnsBoolExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "rounds" */
export enum RoundsConstraint {
  /** unique or primary key constraint */
  RoundsPkey = 'rounds_pkey'
}

/** input type for incrementing numeric columns in table "rounds" */
export type RoundsIncInput = {
  order_sequence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rounds" */
export type RoundsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<GamesObjRelInsertInput>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_sequence?: InputMaybe<Scalars['Int']>;
  turns?: InputMaybe<TurnsArrRelInsertInput>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RoundsMaxFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order_sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "rounds" */
export type RoundsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  order_sequence?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RoundsMinFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order_sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "rounds" */
export type RoundsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  order_sequence?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "rounds" */
export type RoundsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rounds>;
};

/** input type for inserting object relation for remote table "rounds" */
export type RoundsObjRelInsertInput = {
  data: RoundsInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<RoundsOnConflict>;
};

/** on conflict condition type for table "rounds" */
export type RoundsOnConflict = {
  constraint: RoundsConstraint;
  update_columns?: Array<RoundsUpdateColumn>;
  where?: InputMaybe<RoundsBoolExp>;
};

/** Ordering options when selecting data from "rounds". */
export type RoundsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game?: InputMaybe<GamesOrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  order_sequence?: InputMaybe<OrderBy>;
  turns_aggregate?: InputMaybe<TurnsAggregateOrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: rounds */
export type RoundsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "rounds" */
export enum RoundsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderSequence = 'order_sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rounds" */
export type RoundsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_sequence?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RoundsStddevFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rounds" */
export type RoundsStddevOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RoundsStddevPopFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rounds" */
export type RoundsStddevPopOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RoundsStddevSampFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rounds" */
export type RoundsStddevSampOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type RoundsSumFields = {
  order_sequence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "rounds" */
export type RoundsSumOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** update columns of table "rounds" */
export enum RoundsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderSequence = 'order_sequence',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type RoundsVarPopFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rounds" */
export type RoundsVarPopOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RoundsVarSampFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rounds" */
export type RoundsVarSampOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RoundsVarianceFields = {
  order_sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rounds" */
export type RoundsVarianceOrderBy = {
  order_sequence?: InputMaybe<OrderBy>;
};

/** columns and relationships of "server_time" */
export type ServerTime = {
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "server_time" */
export type ServerTimeAggregate = {
  aggregate?: Maybe<ServerTimeAggregateFields>;
  nodes: Array<ServerTime>;
};

/** aggregate fields of "server_time" */
export type ServerTimeAggregateFields = {
  count: Scalars['Int'];
  max?: Maybe<ServerTimeMaxFields>;
  min?: Maybe<ServerTimeMinFields>;
};


/** aggregate fields of "server_time" */
export type ServerTimeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ServerTimeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "server_time". All fields are combined with a logical 'AND'. */
export type ServerTimeBoolExp = {
  _and?: InputMaybe<Array<ServerTimeBoolExp>>;
  _not?: InputMaybe<ServerTimeBoolExp>;
  _or?: InputMaybe<Array<ServerTimeBoolExp>>;
  now?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type ServerTimeMaxFields = {
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ServerTimeMinFields = {
  now?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "server_time". */
export type ServerTimeOrderBy = {
  now?: InputMaybe<OrderBy>;
};

/** select columns of table "server_time" */
export enum ServerTimeSelectColumn {
  /** column name */
  Now = 'now'
}

export type SubscriptionRoot = {
  /** An array relationship */
  cards: Array<Cards>;
  /** An aggregate relationship */
  cards_aggregate: CardsAggregate;
  /** fetch data from the table: "cards" using primary key columns */
  cards_by_pk?: Maybe<Cards>;
  /** fetch data from the table: "game_card_play_style" */
  game_card_play_style: Array<GameCardPlayStyle>;
  /** fetch aggregated fields from the table: "game_card_play_style" */
  game_card_play_style_aggregate: GameCardPlayStyleAggregate;
  /** fetch data from the table: "game_card_play_style" using primary key columns */
  game_card_play_style_by_pk?: Maybe<GameCardPlayStyle>;
  /** fetch data from the table: "game_state" */
  game_state: Array<GameState>;
  /** fetch aggregated fields from the table: "game_state" */
  game_state_aggregate: GameStateAggregate;
  /** fetch data from the table: "game_state" using primary key columns */
  game_state_by_pk?: Maybe<GameState>;
  /** fetch data from the table: "games" */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: GamesAggregate;
  /** fetch data from the table: "games" using primary key columns */
  games_by_pk?: Maybe<Games>;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  players_aggregate: PlayersAggregate;
  /** fetch data from the table: "players" using primary key columns */
  players_by_pk?: Maybe<Players>;
  /** An array relationship */
  rounds: Array<Rounds>;
  /** An aggregate relationship */
  rounds_aggregate: RoundsAggregate;
  /** fetch data from the table: "rounds" using primary key columns */
  rounds_by_pk?: Maybe<Rounds>;
  /** fetch data from the table: "server_time" */
  server_time: Array<ServerTime>;
  /** fetch aggregated fields from the table: "server_time" */
  server_time_aggregate: ServerTimeAggregate;
  /** fetch data from the table: "team_colors" */
  team_colors: Array<TeamColors>;
  /** fetch aggregated fields from the table: "team_colors" */
  team_colors_aggregate: TeamColorsAggregate;
  /** fetch data from the table: "team_colors" using primary key columns */
  team_colors_by_pk?: Maybe<TeamColors>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: TeamsAggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "turn_scorings" */
  turn_scorings: Array<TurnScorings>;
  /** fetch aggregated fields from the table: "turn_scorings" */
  turn_scorings_aggregate: TurnScoringsAggregate;
  /** fetch data from the table: "turn_scorings" using primary key columns */
  turn_scorings_by_pk?: Maybe<TurnScorings>;
  /** An array relationship */
  turns: Array<Turns>;
  /** An aggregate relationship */
  turns_aggregate: TurnsAggregate;
  /** fetch data from the table: "turns" using primary key columns */
  turns_by_pk?: Maybe<Turns>;
};


export type SubscriptionRootCardsArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


export type SubscriptionRootCardsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CardsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CardsOrderBy>>;
  where?: InputMaybe<CardsBoolExp>;
};


export type SubscriptionRootCardsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGameCardPlayStyleArgs = {
  distinct_on?: InputMaybe<Array<GameCardPlayStyleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameCardPlayStyleOrderBy>>;
  where?: InputMaybe<GameCardPlayStyleBoolExp>;
};


export type SubscriptionRootGameCardPlayStyleAggregateArgs = {
  distinct_on?: InputMaybe<Array<GameCardPlayStyleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameCardPlayStyleOrderBy>>;
  where?: InputMaybe<GameCardPlayStyleBoolExp>;
};


export type SubscriptionRootGameCardPlayStyleByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootGameStateArgs = {
  distinct_on?: InputMaybe<Array<GameStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameStateOrderBy>>;
  where?: InputMaybe<GameStateBoolExp>;
};


export type SubscriptionRootGameStateAggregateArgs = {
  distinct_on?: InputMaybe<Array<GameStateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GameStateOrderBy>>;
  where?: InputMaybe<GameStateBoolExp>;
};


export type SubscriptionRootGameStateByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootGamesArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


export type SubscriptionRootGamesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GamesOrderBy>>;
  where?: InputMaybe<GamesBoolExp>;
};


export type SubscriptionRootGamesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPlayersArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


export type SubscriptionRootPlayersAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


export type SubscriptionRootPlayersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootRoundsArgs = {
  distinct_on?: InputMaybe<Array<RoundsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundsOrderBy>>;
  where?: InputMaybe<RoundsBoolExp>;
};


export type SubscriptionRootRoundsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RoundsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundsOrderBy>>;
  where?: InputMaybe<RoundsBoolExp>;
};


export type SubscriptionRootRoundsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootServerTimeArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServerTimeOrderBy>>;
  where?: InputMaybe<ServerTimeBoolExp>;
};


export type SubscriptionRootServerTimeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ServerTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServerTimeOrderBy>>;
  where?: InputMaybe<ServerTimeBoolExp>;
};


export type SubscriptionRootTeamColorsArgs = {
  distinct_on?: InputMaybe<Array<TeamColorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamColorsOrderBy>>;
  where?: InputMaybe<TeamColorsBoolExp>;
};


export type SubscriptionRootTeamColorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TeamColorsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamColorsOrderBy>>;
  where?: InputMaybe<TeamColorsBoolExp>;
};


export type SubscriptionRootTeamColorsByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootTeamsArgs = {
  distinct_on?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};


export type SubscriptionRootTeamsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};


export type SubscriptionRootTeamsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTurnScoringsArgs = {
  distinct_on?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnScoringsOrderBy>>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};


export type SubscriptionRootTurnScoringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnScoringsOrderBy>>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};


export type SubscriptionRootTurnScoringsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTurnsArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


export type SubscriptionRootTurnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnsOrderBy>>;
  where?: InputMaybe<TurnsBoolExp>;
};


export type SubscriptionRootTurnsByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "team_colors" */
export type TeamColors = {
  value: Scalars['String'];
};

/** aggregated selection of "team_colors" */
export type TeamColorsAggregate = {
  aggregate?: Maybe<TeamColorsAggregateFields>;
  nodes: Array<TeamColors>;
};

/** aggregate fields of "team_colors" */
export type TeamColorsAggregateFields = {
  count: Scalars['Int'];
  max?: Maybe<TeamColorsMaxFields>;
  min?: Maybe<TeamColorsMinFields>;
};


/** aggregate fields of "team_colors" */
export type TeamColorsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamColorsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "team_colors". All fields are combined with a logical 'AND'. */
export type TeamColorsBoolExp = {
  _and?: InputMaybe<Array<TeamColorsBoolExp>>;
  _not?: InputMaybe<TeamColorsBoolExp>;
  _or?: InputMaybe<Array<TeamColorsBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "team_colors" */
export enum TeamColorsConstraint {
  /** unique or primary key constraint */
  TeamColorsPkey = 'team_colors_pkey'
}

export enum TeamColorsEnum {
  Blue = 'blue',
  Red = 'red'
}

/** Boolean expression to compare columns of type "team_colors_enum". All fields are combined with logical 'AND'. */
export type TeamColorsEnumComparisonExp = {
  _eq?: InputMaybe<TeamColorsEnum>;
  _in?: InputMaybe<Array<TeamColorsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TeamColorsEnum>;
  _nin?: InputMaybe<Array<TeamColorsEnum>>;
};

/** input type for inserting data into table "team_colors" */
export type TeamColorsInsertInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TeamColorsMaxFields = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TeamColorsMinFields = {
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "team_colors" */
export type TeamColorsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TeamColors>;
};

/** on conflict condition type for table "team_colors" */
export type TeamColorsOnConflict = {
  constraint: TeamColorsConstraint;
  update_columns?: Array<TeamColorsUpdateColumn>;
  where?: InputMaybe<TeamColorsBoolExp>;
};

/** Ordering options when selecting data from "team_colors". */
export type TeamColorsOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_colors */
export type TeamColorsPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "team_colors" */
export enum TeamColorsSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "team_colors" */
export type TeamColorsSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "team_colors" */
export enum TeamColorsUpdateColumn {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "teams" */
export type Teams = {
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  players_aggregate: PlayersAggregate;
  team_color: TeamColorsEnum;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "teams" */
export type TeamsPlayersArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};


/** columns and relationships of "teams" */
export type TeamsPlayersAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

/** aggregated selection of "teams" */
export type TeamsAggregate = {
  aggregate?: Maybe<TeamsAggregateFields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type TeamsAggregateFields = {
  count: Scalars['Int'];
  max?: Maybe<TeamsMaxFields>;
  min?: Maybe<TeamsMinFields>;
};


/** aggregate fields of "teams" */
export type TeamsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams" */
export type TeamsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TeamsMaxOrderBy>;
  min?: InputMaybe<TeamsMinOrderBy>;
};

/** input type for inserting array relation for remote table "teams" */
export type TeamsArrRelInsertInput = {
  data: Array<TeamsInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<TeamsOnConflict>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type TeamsBoolExp = {
  _and?: InputMaybe<Array<TeamsBoolExp>>;
  _not?: InputMaybe<TeamsBoolExp>;
  _or?: InputMaybe<Array<TeamsBoolExp>>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  game?: InputMaybe<GamesBoolExp>;
  game_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  players?: InputMaybe<PlayersBoolExp>;
  team_color?: InputMaybe<TeamColorsEnumComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "teams" */
export enum TeamsConstraint {
  /** unique or primary key constraint */
  TeamsPkey = 'teams_pkey'
}

/** input type for inserting data into table "teams" */
export type TeamsInsertInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<GamesObjRelInsertInput>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  players?: InputMaybe<PlayersArrRelInsertInput>;
  team_color?: InputMaybe<TeamColorsEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TeamsMaxFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teams" */
export type TeamsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TeamsMinFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teams" */
export type TeamsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "teams" */
export type TeamsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type TeamsObjRelInsertInput = {
  data: TeamsInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<TeamsOnConflict>;
};

/** on conflict condition type for table "teams" */
export type TeamsOnConflict = {
  constraint: TeamsConstraint;
  update_columns?: Array<TeamsUpdateColumn>;
  where?: InputMaybe<TeamsBoolExp>;
};

/** Ordering options when selecting data from "teams". */
export type TeamsOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  game?: InputMaybe<GamesOrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  players_aggregate?: InputMaybe<PlayersAggregateOrderBy>;
  team_color?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: teams */
export type TeamsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "teams" */
export enum TeamsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamColor = 'team_color',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teams" */
export type TeamsSetInput = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_color?: InputMaybe<TeamColorsEnum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "teams" */
export enum TeamsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamColor = 'team_color',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "turn_scorings" */
export type TurnScorings = {
  /** An object relationship */
  card: Cards;
  card_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  ended_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  score: Scalars['Int'];
  started_at: Scalars['timestamptz'];
  status: Scalars['String'];
  /** An object relationship */
  turn: Turns;
  turn_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "turn_scorings" */
export type TurnScoringsAggregate = {
  aggregate?: Maybe<TurnScoringsAggregateFields>;
  nodes: Array<TurnScorings>;
};

/** aggregate fields of "turn_scorings" */
export type TurnScoringsAggregateFields = {
  avg?: Maybe<TurnScoringsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TurnScoringsMaxFields>;
  min?: Maybe<TurnScoringsMinFields>;
  stddev?: Maybe<TurnScoringsStddevFields>;
  stddev_pop?: Maybe<TurnScoringsStddevPopFields>;
  stddev_samp?: Maybe<TurnScoringsStddevSampFields>;
  sum?: Maybe<TurnScoringsSumFields>;
  var_pop?: Maybe<TurnScoringsVarPopFields>;
  var_samp?: Maybe<TurnScoringsVarSampFields>;
  variance?: Maybe<TurnScoringsVarianceFields>;
};


/** aggregate fields of "turn_scorings" */
export type TurnScoringsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "turn_scorings" */
export type TurnScoringsAggregateOrderBy = {
  avg?: InputMaybe<TurnScoringsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TurnScoringsMaxOrderBy>;
  min?: InputMaybe<TurnScoringsMinOrderBy>;
  stddev?: InputMaybe<TurnScoringsStddevOrderBy>;
  stddev_pop?: InputMaybe<TurnScoringsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TurnScoringsStddevSampOrderBy>;
  sum?: InputMaybe<TurnScoringsSumOrderBy>;
  var_pop?: InputMaybe<TurnScoringsVarPopOrderBy>;
  var_samp?: InputMaybe<TurnScoringsVarSampOrderBy>;
  variance?: InputMaybe<TurnScoringsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "turn_scorings" */
export type TurnScoringsArrRelInsertInput = {
  data: Array<TurnScoringsInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<TurnScoringsOnConflict>;
};

/** aggregate avg on columns */
export type TurnScoringsAvgFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "turn_scorings" */
export type TurnScoringsAvgOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "turn_scorings". All fields are combined with a logical 'AND'. */
export type TurnScoringsBoolExp = {
  _and?: InputMaybe<Array<TurnScoringsBoolExp>>;
  _not?: InputMaybe<TurnScoringsBoolExp>;
  _or?: InputMaybe<Array<TurnScoringsBoolExp>>;
  card?: InputMaybe<CardsBoolExp>;
  card_id?: InputMaybe<UuidComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  ended_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  score?: InputMaybe<IntComparisonExp>;
  started_at?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  turn?: InputMaybe<TurnsBoolExp>;
  turn_id?: InputMaybe<UuidComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "turn_scorings" */
export enum TurnScoringsConstraint {
  /** unique or primary key constraint */
  TurnScoringsPkey = 'turn_scorings_pkey'
}

/** input type for incrementing numeric columns in table "turn_scorings" */
export type TurnScoringsIncInput = {
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "turn_scorings" */
export type TurnScoringsInsertInput = {
  card?: InputMaybe<CardsObjRelInsertInput>;
  card_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  score?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  turn?: InputMaybe<TurnsObjRelInsertInput>;
  turn_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TurnScoringsMaxFields = {
  card_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  turn_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "turn_scorings" */
export type TurnScoringsMaxOrderBy = {
  card_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  ended_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  score?: InputMaybe<OrderBy>;
  started_at?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  turn_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TurnScoringsMinFields = {
  card_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  score?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  turn_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "turn_scorings" */
export type TurnScoringsMinOrderBy = {
  card_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  ended_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  score?: InputMaybe<OrderBy>;
  started_at?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  turn_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "turn_scorings" */
export type TurnScoringsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TurnScorings>;
};

/** on conflict condition type for table "turn_scorings" */
export type TurnScoringsOnConflict = {
  constraint: TurnScoringsConstraint;
  update_columns?: Array<TurnScoringsUpdateColumn>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};

/** Ordering options when selecting data from "turn_scorings". */
export type TurnScoringsOrderBy = {
  card?: InputMaybe<CardsOrderBy>;
  card_id?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  ended_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  score?: InputMaybe<OrderBy>;
  started_at?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  turn?: InputMaybe<TurnsOrderBy>;
  turn_id?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: turn_scorings */
export type TurnScoringsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "turn_scorings" */
export enum TurnScoringsSelectColumn {
  /** column name */
  CardId = 'card_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Status = 'status',
  /** column name */
  TurnId = 'turn_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "turn_scorings" */
export type TurnScoringsSetInput = {
  card_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  score?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  turn_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TurnScoringsStddevFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "turn_scorings" */
export type TurnScoringsStddevOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TurnScoringsStddevPopFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "turn_scorings" */
export type TurnScoringsStddevPopOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TurnScoringsStddevSampFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "turn_scorings" */
export type TurnScoringsStddevSampOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TurnScoringsSumFields = {
  score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "turn_scorings" */
export type TurnScoringsSumOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** update columns of table "turn_scorings" */
export enum TurnScoringsUpdateColumn {
  /** column name */
  CardId = 'card_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Score = 'score',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Status = 'status',
  /** column name */
  TurnId = 'turn_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type TurnScoringsVarPopFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "turn_scorings" */
export type TurnScoringsVarPopOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TurnScoringsVarSampFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "turn_scorings" */
export type TurnScoringsVarSampOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TurnScoringsVarianceFields = {
  score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "turn_scorings" */
export type TurnScoringsVarianceOrderBy = {
  score?: InputMaybe<OrderBy>;
};

/** columns and relationships of "turns" */
export type Turns = {
  completed_card_ids: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  player: Players;
  player_id: Scalars['uuid'];
  review_started_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  round?: Maybe<Rounds>;
  round_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  scorings: Array<TurnScorings>;
  /** An aggregate relationship */
  scorings_aggregate: TurnScoringsAggregate;
  seconds_per_turn_override?: Maybe<Scalars['Int']>;
  sequential_id: Scalars['Int'];
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "turns" */
export type TurnsCompletedCardIdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "turns" */
export type TurnsScoringsArgs = {
  distinct_on?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnScoringsOrderBy>>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};


/** columns and relationships of "turns" */
export type TurnsScoringsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TurnScoringsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TurnScoringsOrderBy>>;
  where?: InputMaybe<TurnScoringsBoolExp>;
};

/** aggregated selection of "turns" */
export type TurnsAggregate = {
  aggregate?: Maybe<TurnsAggregateFields>;
  nodes: Array<Turns>;
};

/** aggregate fields of "turns" */
export type TurnsAggregateFields = {
  avg?: Maybe<TurnsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TurnsMaxFields>;
  min?: Maybe<TurnsMinFields>;
  stddev?: Maybe<TurnsStddevFields>;
  stddev_pop?: Maybe<TurnsStddevPopFields>;
  stddev_samp?: Maybe<TurnsStddevSampFields>;
  sum?: Maybe<TurnsSumFields>;
  var_pop?: Maybe<TurnsVarPopFields>;
  var_samp?: Maybe<TurnsVarSampFields>;
  variance?: Maybe<TurnsVarianceFields>;
};


/** aggregate fields of "turns" */
export type TurnsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TurnsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "turns" */
export type TurnsAggregateOrderBy = {
  avg?: InputMaybe<TurnsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TurnsMaxOrderBy>;
  min?: InputMaybe<TurnsMinOrderBy>;
  stddev?: InputMaybe<TurnsStddevOrderBy>;
  stddev_pop?: InputMaybe<TurnsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TurnsStddevSampOrderBy>;
  sum?: InputMaybe<TurnsSumOrderBy>;
  var_pop?: InputMaybe<TurnsVarPopOrderBy>;
  var_samp?: InputMaybe<TurnsVarSampOrderBy>;
  variance?: InputMaybe<TurnsVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TurnsAppendInput = {
  completed_card_ids?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "turns" */
export type TurnsArrRelInsertInput = {
  data: Array<TurnsInsertInput>;
  /** on conflict condition */
  on_conflict?: InputMaybe<TurnsOnConflict>;
};

/** aggregate avg on columns */
export type TurnsAvgFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "turns" */
export type TurnsAvgOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "turns". All fields are combined with a logical 'AND'. */
export type TurnsBoolExp = {
  _and?: InputMaybe<Array<TurnsBoolExp>>;
  _not?: InputMaybe<TurnsBoolExp>;
  _or?: InputMaybe<Array<TurnsBoolExp>>;
  completed_card_ids?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  ended_at?: InputMaybe<TimestamptzComparisonExp>;
  game?: InputMaybe<GamesBoolExp>;
  game_id?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  player?: InputMaybe<PlayersBoolExp>;
  player_id?: InputMaybe<UuidComparisonExp>;
  review_started_at?: InputMaybe<TimestamptzComparisonExp>;
  round?: InputMaybe<RoundsBoolExp>;
  round_id?: InputMaybe<UuidComparisonExp>;
  scorings?: InputMaybe<TurnScoringsBoolExp>;
  seconds_per_turn_override?: InputMaybe<IntComparisonExp>;
  sequential_id?: InputMaybe<IntComparisonExp>;
  started_at?: InputMaybe<TimestamptzComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "turns" */
export enum TurnsConstraint {
  /** unique or primary key constraint */
  TurnsPkey = 'turns_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TurnsDeleteAtPathInput = {
  completed_card_ids?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TurnsDeleteElemInput = {
  completed_card_ids?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TurnsDeleteKeyInput = {
  completed_card_ids?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "turns" */
export type TurnsIncInput = {
  seconds_per_turn_override?: InputMaybe<Scalars['Int']>;
  sequential_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "turns" */
export type TurnsInsertInput = {
  completed_card_ids?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<GamesObjRelInsertInput>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  player?: InputMaybe<PlayersObjRelInsertInput>;
  player_id?: InputMaybe<Scalars['uuid']>;
  review_started_at?: InputMaybe<Scalars['timestamptz']>;
  round?: InputMaybe<RoundsObjRelInsertInput>;
  round_id?: InputMaybe<Scalars['uuid']>;
  scorings?: InputMaybe<TurnScoringsArrRelInsertInput>;
  seconds_per_turn_override?: InputMaybe<Scalars['Int']>;
  sequential_id?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TurnsMaxFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  player_id?: Maybe<Scalars['uuid']>;
  review_started_at?: Maybe<Scalars['timestamptz']>;
  round_id?: Maybe<Scalars['uuid']>;
  seconds_per_turn_override?: Maybe<Scalars['Int']>;
  sequential_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "turns" */
export type TurnsMaxOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  ended_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  player_id?: InputMaybe<OrderBy>;
  review_started_at?: InputMaybe<OrderBy>;
  round_id?: InputMaybe<OrderBy>;
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
  started_at?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TurnsMinFields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  player_id?: Maybe<Scalars['uuid']>;
  review_started_at?: Maybe<Scalars['timestamptz']>;
  round_id?: Maybe<Scalars['uuid']>;
  seconds_per_turn_override?: Maybe<Scalars['Int']>;
  sequential_id?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "turns" */
export type TurnsMinOrderBy = {
  created_at?: InputMaybe<OrderBy>;
  ended_at?: InputMaybe<OrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  player_id?: InputMaybe<OrderBy>;
  review_started_at?: InputMaybe<OrderBy>;
  round_id?: InputMaybe<OrderBy>;
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
  started_at?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "turns" */
export type TurnsMutationResponse = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Turns>;
};

/** input type for inserting object relation for remote table "turns" */
export type TurnsObjRelInsertInput = {
  data: TurnsInsertInput;
  /** on conflict condition */
  on_conflict?: InputMaybe<TurnsOnConflict>;
};

/** on conflict condition type for table "turns" */
export type TurnsOnConflict = {
  constraint: TurnsConstraint;
  update_columns?: Array<TurnsUpdateColumn>;
  where?: InputMaybe<TurnsBoolExp>;
};

/** Ordering options when selecting data from "turns". */
export type TurnsOrderBy = {
  completed_card_ids?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  ended_at?: InputMaybe<OrderBy>;
  game?: InputMaybe<GamesOrderBy>;
  game_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  player?: InputMaybe<PlayersOrderBy>;
  player_id?: InputMaybe<OrderBy>;
  review_started_at?: InputMaybe<OrderBy>;
  round?: InputMaybe<RoundsOrderBy>;
  round_id?: InputMaybe<OrderBy>;
  scorings_aggregate?: InputMaybe<TurnScoringsAggregateOrderBy>;
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
  started_at?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: turns */
export type TurnsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TurnsPrependInput = {
  completed_card_ids?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "turns" */
export enum TurnsSelectColumn {
  /** column name */
  CompletedCardIds = 'completed_card_ids',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  ReviewStartedAt = 'review_started_at',
  /** column name */
  RoundId = 'round_id',
  /** column name */
  SecondsPerTurnOverride = 'seconds_per_turn_override',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "turns" */
export type TurnsSetInput = {
  completed_card_ids?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  player_id?: InputMaybe<Scalars['uuid']>;
  review_started_at?: InputMaybe<Scalars['timestamptz']>;
  round_id?: InputMaybe<Scalars['uuid']>;
  seconds_per_turn_override?: InputMaybe<Scalars['Int']>;
  sequential_id?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TurnsStddevFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "turns" */
export type TurnsStddevOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TurnsStddevPopFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "turns" */
export type TurnsStddevPopOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TurnsStddevSampFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "turns" */
export type TurnsStddevSampOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TurnsSumFields = {
  seconds_per_turn_override?: Maybe<Scalars['Int']>;
  sequential_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "turns" */
export type TurnsSumOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** update columns of table "turns" */
export enum TurnsUpdateColumn {
  /** column name */
  CompletedCardIds = 'completed_card_ids',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  ReviewStartedAt = 'review_started_at',
  /** column name */
  RoundId = 'round_id',
  /** column name */
  SecondsPerTurnOverride = 'seconds_per_turn_override',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type TurnsVarPopFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "turns" */
export type TurnsVarPopOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TurnsVarSampFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "turns" */
export type TurnsVarSampOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TurnsVarianceFields = {
  seconds_per_turn_override?: Maybe<Scalars['Float']>;
  sequential_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "turns" */
export type TurnsVarianceOrderBy = {
  seconds_per_turn_override?: InputMaybe<OrderBy>;
  sequential_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type SubmitCardsMutationVariables = Exact<{
  cards: Array<CardsInsertInput> | CardsInsertInput;
}>;


export type SubmitCardsMutation = { insert_cards?: { returning: Array<{ id: any, player_id: any, game_id: any, word: string }> } | null };

export type AcceptCardMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type AcceptCardMutation = { update_cards_by_pk?: { id: any, is_allowed?: boolean | null } | null };

export type RejectCardMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RejectCardMutation = { update_cards_by_pk?: { id: any, is_allowed?: boolean | null } | null };

export type CurrentPlayerQueryVariables = Exact<{
  clientUuid: Scalars['uuid'];
  joinCode: Scalars['String'];
}>;


export type CurrentPlayerQuery = { players: Array<{ id: any, client_uuid?: any | null, username?: string | null, game: { id: any, host?: { id: any, username?: string | null } | null } }> };

export type TurnFragment = { id: any, player_id: any, started_at?: any | null, review_started_at?: any | null, completed_card_ids: any, seconds_per_turn_override?: number | null };

export type CurrentGameSubscriptionVariables = Exact<{
  joinCode: Scalars['String'];
}>;


export type CurrentGameSubscription = { games: Array<{ id: any, join_code?: string | null, starting_letter?: string | null, seconds_per_turn?: number | null, num_entries_per_player?: number | null, allow_card_skips: boolean, screen_cards: boolean, card_play_style: GameCardPlayStyleEnum, state: GameStateEnum, host?: { id: any, username?: string | null } | null, rounds: Array<{ id: any, value: string }>, cards: Array<{ id: any, word: string, player_id: any, is_allowed?: boolean | null }>, players: Array<{ id: any, client_uuid?: any | null, username?: string | null, team_id?: any | null, team_sequence?: number | null, team?: { id: any, name?: string | null, team_color: TeamColorsEnum } | null }>, turns: Array<{ id: any, player_id: any, started_at?: any | null, review_started_at?: any | null, completed_card_ids: any, seconds_per_turn_override?: number | null }>, teams: Array<{ id: any, name?: string | null, team_color: TeamColorsEnum }> }> };

export type UpdateGameStateMutationVariables = Exact<{
  id: Scalars['uuid'];
  state: GameStateEnum;
}>;


export type UpdateGameStateMutation = { update_games_by_pk?: { id: any, state: GameStateEnum } | null };

export type StartGameMutationVariables = Exact<{ [key: string]: never; }>;


export type StartGameMutation = { insert_games_one?: { id: any } | null };

export type NewGameMutationVariables = Exact<{
  clientUuid: Scalars['String'];
}>;


export type NewGameMutation = { newGame?: { id: string, jwt_token: string } | null };

export type JoinGameMutationVariables = Exact<{
  gameId: Scalars['String'];
  clientUuid: Scalars['String'];
}>;


export type JoinGameMutation = { joinGame?: { id: string, jwt_token: string } | null };

export type GameByJoinCodeQueryVariables = Exact<{
  joinCode: Scalars['String'];
}>;


export type GameByJoinCodeQuery = { games: Array<{ id: any }> };

export type GameByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GameByIdSubscription = { games_by_pk?: { id: any, join_code?: string | null } | null };

export type QueryGameByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type QueryGameByIdQuery = { games_by_pk?: { id: any, join_code?: string | null } | null };

export type UpdateGameSettingsMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: GamesSetInput;
}>;


export type UpdateGameSettingsMutation = { update_games_by_pk?: { id: any, join_code?: string | null, starting_letter?: string | null, seconds_per_turn?: number | null, num_entries_per_player?: number | null, allow_card_skips: boolean, screen_cards: boolean, card_play_style: GameCardPlayStyleEnum } | null };

export type UpdatePlayerMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: PlayersSetInput;
}>;


export type UpdatePlayerMutation = { update_players_by_pk?: { id: any, username?: string | null, team_sequence?: number | null, team?: { team_color: TeamColorsEnum } | null } | null };

export type RemovePlayerMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemovePlayerMutation = { delete_players_by_pk?: { id: any } | null };

export type DeleteRoundMutationVariables = Exact<{
  id: Scalars['uuid'];
  gameId: Scalars['uuid'];
  rounds: Array<RoundsInsertInput> | RoundsInsertInput;
}>;


export type DeleteRoundMutation = { delete_rounds_by_pk?: { id: any } | null, insert_games_one?: { id: any, rounds: Array<{ id: any, order_sequence: number }> } | null };

export type DeleteRoundsMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
  gameId: Scalars['uuid'];
  rounds: Array<RoundsInsertInput> | RoundsInsertInput;
}>;


export type DeleteRoundsMutation = { delete_rounds?: { affected_rows: number } | null, insert_games_one?: { id: any, rounds: Array<{ id: any, order_sequence: number }> } | null };

export type UpdateAllRoundsMutationVariables = Exact<{
  gameId: Scalars['uuid'];
  rounds: Array<RoundsInsertInput> | RoundsInsertInput;
}>;


export type UpdateAllRoundsMutation = { insert_games_one?: { id: any, rounds: Array<{ id: any, order_sequence: number }> } | null };

export type AddRoundMutationVariables = Exact<{
  object: RoundsInsertInput;
}>;


export type AddRoundMutation = { insert_rounds_one?: { id: any, value: string, order_sequence: number } | null };

export type AddRoundsMutationVariables = Exact<{
  objects: Array<RoundsInsertInput> | RoundsInsertInput;
}>;


export type AddRoundsMutation = { insert_rounds?: { affected_rows: number, returning: Array<{ id: any, value: string }> } | null };

export type LoadWordsMutationVariables = Exact<{
  objects: Array<CardsInsertInput> | CardsInsertInput;
}>;


export type LoadWordsMutation = { insert_cards?: { affected_rows: number, returning: Array<{ id: any }> } | null };

export type CreateTurnMutationVariables = Exact<{
  gameId: Scalars['uuid'];
  playerId: Scalars['uuid'];
  roundId: Scalars['uuid'];
}>;


export type CreateTurnMutation = { insert_turns_one?: { id: any, game_id: any, player_id: any, round_id?: any | null } | null };

export type StartTurnMutationVariables = Exact<{
  currentTurnId: Scalars['uuid'];
}>;


export type StartTurnMutation = { update_turns_by_pk?: { id: any, started_at?: any | null } | null };

export type EndCurrentTurnAndStartNextTurnMutationVariables = Exact<{
  currentTurnId: Scalars['uuid'];
  completedCardIds: Scalars['jsonb'];
  gameId: Scalars['uuid'];
  currentTurnScorings: Array<TurnScoringsInsertInput> | TurnScoringsInsertInput;
  nextTurnplayerId: Scalars['uuid'];
  nextTurnSecondsPerTurnOverride?: InputMaybe<Scalars['Int']>;
  roundId?: InputMaybe<Scalars['uuid']>;
}>;


export type EndCurrentTurnAndStartNextTurnMutation = { delete_turn_scorings?: { returning: Array<{ id: any }> } | null, insert_turn_scorings?: { returning: Array<{ id: any }> } | null, update_turns_by_pk?: { id: any, ended_at?: any | null, completed_card_ids: any } | null, insert_turns_one?: { id: any, game_id: any, player_id: any, seconds_per_turn_override?: number | null, round_id?: any | null } | null };

export type StartReviewMutationVariables = Exact<{
  currentTurnId: Scalars['uuid'];
}>;


export type StartReviewMutation = { update_turns_by_pk?: { id: any, review_started_at?: any | null } | null };

export type ServerTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerTimeQuery = { server_time: Array<{ now?: any | null }> };

export type UpdateAllPlayersMutationVariables = Exact<{
  gameId: Scalars['uuid'];
  players: Array<PlayersInsertInput> | PlayersInsertInput;
}>;


export type UpdateAllPlayersMutation = { insert_games_one?: { id: any, players: Array<{ id: any, game_id: any, team_sequence?: number | null, team?: { name?: string | null, team_color: TeamColorsEnum } | null }> } | null };

export type UpdateTeamNameMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type UpdateTeamNameMutation = { update_teams_by_pk?: { id: any, name?: string | null } | null };

export const TurnFragmentDoc = gql`
    fragment Turn on turns {
  id
  player_id
  started_at
  review_started_at
  completed_card_ids
  seconds_per_turn_override
}
    `;
export const SubmitCardsDocument = gql`
    mutation SubmitCards($cards: [cards_insert_input!]!) {
  insert_cards(objects: $cards) {
    returning {
      id
      player_id
      game_id
      word
    }
  }
}
    `;
export type SubmitCardsMutationFn = Apollo.MutationFunction<SubmitCardsMutation, SubmitCardsMutationVariables>;

/**
 * __useSubmitCardsMutation__
 *
 * To run a mutation, you first call `useSubmitCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCardsMutation, { data, loading, error }] = useSubmitCardsMutation({
 *   variables: {
 *      cards: // value for 'cards'
 *   },
 * });
 */
export function useSubmitCardsMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCardsMutation, SubmitCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitCardsMutation, SubmitCardsMutationVariables>(SubmitCardsDocument, options);
      }
export type SubmitCardsMutationHookResult = ReturnType<typeof useSubmitCardsMutation>;
export type SubmitCardsMutationResult = Apollo.MutationResult<SubmitCardsMutation>;
export type SubmitCardsMutationOptions = Apollo.BaseMutationOptions<SubmitCardsMutation, SubmitCardsMutationVariables>;
export const AcceptCardDocument = gql`
    mutation AcceptCard($id: uuid!) {
  update_cards_by_pk(pk_columns: {id: $id}, _set: {is_allowed: true}) {
    id
    is_allowed
  }
}
    `;
export type AcceptCardMutationFn = Apollo.MutationFunction<AcceptCardMutation, AcceptCardMutationVariables>;

/**
 * __useAcceptCardMutation__
 *
 * To run a mutation, you first call `useAcceptCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCardMutation, { data, loading, error }] = useAcceptCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcceptCardMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCardMutation, AcceptCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCardMutation, AcceptCardMutationVariables>(AcceptCardDocument, options);
      }
export type AcceptCardMutationHookResult = ReturnType<typeof useAcceptCardMutation>;
export type AcceptCardMutationResult = Apollo.MutationResult<AcceptCardMutation>;
export type AcceptCardMutationOptions = Apollo.BaseMutationOptions<AcceptCardMutation, AcceptCardMutationVariables>;
export const RejectCardDocument = gql`
    mutation RejectCard($id: uuid!) {
  update_cards_by_pk(pk_columns: {id: $id}, _set: {is_allowed: false}) {
    id
    is_allowed
  }
}
    `;
export type RejectCardMutationFn = Apollo.MutationFunction<RejectCardMutation, RejectCardMutationVariables>;

/**
 * __useRejectCardMutation__
 *
 * To run a mutation, you first call `useRejectCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCardMutation, { data, loading, error }] = useRejectCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectCardMutation(baseOptions?: Apollo.MutationHookOptions<RejectCardMutation, RejectCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectCardMutation, RejectCardMutationVariables>(RejectCardDocument, options);
      }
export type RejectCardMutationHookResult = ReturnType<typeof useRejectCardMutation>;
export type RejectCardMutationResult = Apollo.MutationResult<RejectCardMutation>;
export type RejectCardMutationOptions = Apollo.BaseMutationOptions<RejectCardMutation, RejectCardMutationVariables>;
export const CurrentPlayerDocument = gql`
    query CurrentPlayer($clientUuid: uuid!, $joinCode: String!) {
  players(
    where: {client_uuid: {_eq: $clientUuid}, game: {join_code: {_eq: $joinCode}}}
  ) {
    id
    client_uuid
    username
    game {
      id
      host {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useCurrentPlayerQuery__
 *
 * To run a query within a React component, call `useCurrentPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPlayerQuery({
 *   variables: {
 *      clientUuid: // value for 'clientUuid'
 *      joinCode: // value for 'joinCode'
 *   },
 * });
 */
export function useCurrentPlayerQuery(baseOptions: Apollo.QueryHookOptions<CurrentPlayerQuery, CurrentPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentPlayerQuery, CurrentPlayerQueryVariables>(CurrentPlayerDocument, options);
      }
export function useCurrentPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentPlayerQuery, CurrentPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentPlayerQuery, CurrentPlayerQueryVariables>(CurrentPlayerDocument, options);
        }
export type CurrentPlayerQueryHookResult = ReturnType<typeof useCurrentPlayerQuery>;
export type CurrentPlayerLazyQueryHookResult = ReturnType<typeof useCurrentPlayerLazyQuery>;
export type CurrentPlayerQueryResult = Apollo.QueryResult<CurrentPlayerQuery, CurrentPlayerQueryVariables>;
export const CurrentGameDocument = gql`
    subscription CurrentGame($joinCode: String!) {
  games(where: {join_code: {_eq: $joinCode}}) {
    id
    join_code
    starting_letter
    seconds_per_turn
    num_entries_per_player
    allow_card_skips
    screen_cards
    card_play_style
    state
    host {
      id
      username
    }
    rounds(order_by: {order_sequence: asc}) {
      id
      value
    }
    cards(where: {_or: [{is_allowed: {_is_null: true}}, {is_allowed: {_eq: true}}]}) {
      id
      word
      player_id
      is_allowed
    }
    players {
      id
      client_uuid
      username
      team_id
      team {
        id
        name
        team_color
      }
      team_sequence
    }
    turns(order_by: {sequential_id: asc}) {
      ...Turn
    }
    teams {
      id
      name
      team_color
    }
  }
}
    ${TurnFragmentDoc}`;

/**
 * __useCurrentGameSubscription__
 *
 * To run a query within a React component, call `useCurrentGameSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentGameSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentGameSubscription({
 *   variables: {
 *      joinCode: // value for 'joinCode'
 *   },
 * });
 */
export function useCurrentGameSubscription(baseOptions: Apollo.SubscriptionHookOptions<CurrentGameSubscription, CurrentGameSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CurrentGameSubscription, CurrentGameSubscriptionVariables>(CurrentGameDocument, options);
      }
export type CurrentGameSubscriptionHookResult = ReturnType<typeof useCurrentGameSubscription>;
export type CurrentGameSubscriptionResult = Apollo.SubscriptionResult<CurrentGameSubscription>;
export const UpdateGameStateDocument = gql`
    mutation UpdateGameState($id: uuid!, $state: game_state_enum!) {
  update_games_by_pk(pk_columns: {id: $id}, _set: {state: $state}) {
    id
    state
  }
}
    `;
export type UpdateGameStateMutationFn = Apollo.MutationFunction<UpdateGameStateMutation, UpdateGameStateMutationVariables>;

/**
 * __useUpdateGameStateMutation__
 *
 * To run a mutation, you first call `useUpdateGameStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameStateMutation, { data, loading, error }] = useUpdateGameStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateGameStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameStateMutation, UpdateGameStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGameStateMutation, UpdateGameStateMutationVariables>(UpdateGameStateDocument, options);
      }
export type UpdateGameStateMutationHookResult = ReturnType<typeof useUpdateGameStateMutation>;
export type UpdateGameStateMutationResult = Apollo.MutationResult<UpdateGameStateMutation>;
export type UpdateGameStateMutationOptions = Apollo.BaseMutationOptions<UpdateGameStateMutation, UpdateGameStateMutationVariables>;
export const StartGameDocument = gql`
    mutation StartGame {
  insert_games_one(object: {}) {
    id
  }
}
    `;
export type StartGameMutationFn = Apollo.MutationFunction<StartGameMutation, StartGameMutationVariables>;

/**
 * __useStartGameMutation__
 *
 * To run a mutation, you first call `useStartGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGameMutation, { data, loading, error }] = useStartGameMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartGameMutation(baseOptions?: Apollo.MutationHookOptions<StartGameMutation, StartGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartGameMutation, StartGameMutationVariables>(StartGameDocument, options);
      }
export type StartGameMutationHookResult = ReturnType<typeof useStartGameMutation>;
export type StartGameMutationResult = Apollo.MutationResult<StartGameMutation>;
export type StartGameMutationOptions = Apollo.BaseMutationOptions<StartGameMutation, StartGameMutationVariables>;
export const NewGameDocument = gql`
    mutation NewGame($clientUuid: String!) {
  newGame(clientUuid: $clientUuid) {
    id
    jwt_token
  }
}
    `;
export type NewGameMutationFn = Apollo.MutationFunction<NewGameMutation, NewGameMutationVariables>;

/**
 * __useNewGameMutation__
 *
 * To run a mutation, you first call `useNewGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newGameMutation, { data, loading, error }] = useNewGameMutation({
 *   variables: {
 *      clientUuid: // value for 'clientUuid'
 *   },
 * });
 */
export function useNewGameMutation(baseOptions?: Apollo.MutationHookOptions<NewGameMutation, NewGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewGameMutation, NewGameMutationVariables>(NewGameDocument, options);
      }
export type NewGameMutationHookResult = ReturnType<typeof useNewGameMutation>;
export type NewGameMutationResult = Apollo.MutationResult<NewGameMutation>;
export type NewGameMutationOptions = Apollo.BaseMutationOptions<NewGameMutation, NewGameMutationVariables>;
export const JoinGameDocument = gql`
    mutation JoinGame($gameId: String!, $clientUuid: String!) {
  joinGame(gameId: $gameId, clientUuid: $clientUuid) {
    id
    jwt_token
  }
}
    `;
export type JoinGameMutationFn = Apollo.MutationFunction<JoinGameMutation, JoinGameMutationVariables>;

/**
 * __useJoinGameMutation__
 *
 * To run a mutation, you first call `useJoinGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGameMutation, { data, loading, error }] = useJoinGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      clientUuid: // value for 'clientUuid'
 *   },
 * });
 */
export function useJoinGameMutation(baseOptions?: Apollo.MutationHookOptions<JoinGameMutation, JoinGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinGameMutation, JoinGameMutationVariables>(JoinGameDocument, options);
      }
export type JoinGameMutationHookResult = ReturnType<typeof useJoinGameMutation>;
export type JoinGameMutationResult = Apollo.MutationResult<JoinGameMutation>;
export type JoinGameMutationOptions = Apollo.BaseMutationOptions<JoinGameMutation, JoinGameMutationVariables>;
export const GameByJoinCodeDocument = gql`
    query GameByJoinCode($joinCode: String!) {
  games(where: {join_code: {_eq: $joinCode}}) {
    id
  }
}
    `;

/**
 * __useGameByJoinCodeQuery__
 *
 * To run a query within a React component, call `useGameByJoinCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameByJoinCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameByJoinCodeQuery({
 *   variables: {
 *      joinCode: // value for 'joinCode'
 *   },
 * });
 */
export function useGameByJoinCodeQuery(baseOptions: Apollo.QueryHookOptions<GameByJoinCodeQuery, GameByJoinCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameByJoinCodeQuery, GameByJoinCodeQueryVariables>(GameByJoinCodeDocument, options);
      }
export function useGameByJoinCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameByJoinCodeQuery, GameByJoinCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameByJoinCodeQuery, GameByJoinCodeQueryVariables>(GameByJoinCodeDocument, options);
        }
export type GameByJoinCodeQueryHookResult = ReturnType<typeof useGameByJoinCodeQuery>;
export type GameByJoinCodeLazyQueryHookResult = ReturnType<typeof useGameByJoinCodeLazyQuery>;
export type GameByJoinCodeQueryResult = Apollo.QueryResult<GameByJoinCodeQuery, GameByJoinCodeQueryVariables>;
export const GameByIdDocument = gql`
    subscription GameById($id: uuid!) {
  games_by_pk(id: $id) {
    id
    join_code
  }
}
    `;

/**
 * __useGameByIdSubscription__
 *
 * To run a query within a React component, call `useGameByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGameByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGameByIdSubscription(baseOptions: Apollo.SubscriptionHookOptions<GameByIdSubscription, GameByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GameByIdSubscription, GameByIdSubscriptionVariables>(GameByIdDocument, options);
      }
export type GameByIdSubscriptionHookResult = ReturnType<typeof useGameByIdSubscription>;
export type GameByIdSubscriptionResult = Apollo.SubscriptionResult<GameByIdSubscription>;
export const QueryGameByIdDocument = gql`
    query QueryGameById($id: uuid!) {
  games_by_pk(id: $id) {
    id
    join_code
  }
}
    `;

/**
 * __useQueryGameByIdQuery__
 *
 * To run a query within a React component, call `useQueryGameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryGameByIdQuery(baseOptions: Apollo.QueryHookOptions<QueryGameByIdQuery, QueryGameByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGameByIdQuery, QueryGameByIdQueryVariables>(QueryGameByIdDocument, options);
      }
export function useQueryGameByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGameByIdQuery, QueryGameByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGameByIdQuery, QueryGameByIdQueryVariables>(QueryGameByIdDocument, options);
        }
export type QueryGameByIdQueryHookResult = ReturnType<typeof useQueryGameByIdQuery>;
export type QueryGameByIdLazyQueryHookResult = ReturnType<typeof useQueryGameByIdLazyQuery>;
export type QueryGameByIdQueryResult = Apollo.QueryResult<QueryGameByIdQuery, QueryGameByIdQueryVariables>;
export const UpdateGameSettingsDocument = gql`
    mutation UpdateGameSettings($id: uuid!, $input: games_set_input!) {
  update_games_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
    join_code
    starting_letter
    seconds_per_turn
    num_entries_per_player
    allow_card_skips
    screen_cards
    card_play_style
  }
}
    `;
export type UpdateGameSettingsMutationFn = Apollo.MutationFunction<UpdateGameSettingsMutation, UpdateGameSettingsMutationVariables>;

/**
 * __useUpdateGameSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateGameSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameSettingsMutation, { data, loading, error }] = useUpdateGameSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGameSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameSettingsMutation, UpdateGameSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGameSettingsMutation, UpdateGameSettingsMutationVariables>(UpdateGameSettingsDocument, options);
      }
export type UpdateGameSettingsMutationHookResult = ReturnType<typeof useUpdateGameSettingsMutation>;
export type UpdateGameSettingsMutationResult = Apollo.MutationResult<UpdateGameSettingsMutation>;
export type UpdateGameSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateGameSettingsMutation, UpdateGameSettingsMutationVariables>;
export const UpdatePlayerDocument = gql`
    mutation UpdatePlayer($id: uuid!, $input: players_set_input!) {
  update_players_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
    username
    team {
      team_color
    }
    team_sequence
  }
}
    `;
export type UpdatePlayerMutationFn = Apollo.MutationFunction<UpdatePlayerMutation, UpdatePlayerMutationVariables>;

/**
 * __useUpdatePlayerMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerMutation, { data, loading, error }] = useUpdatePlayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlayerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlayerMutation, UpdatePlayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlayerMutation, UpdatePlayerMutationVariables>(UpdatePlayerDocument, options);
      }
export type UpdatePlayerMutationHookResult = ReturnType<typeof useUpdatePlayerMutation>;
export type UpdatePlayerMutationResult = Apollo.MutationResult<UpdatePlayerMutation>;
export type UpdatePlayerMutationOptions = Apollo.BaseMutationOptions<UpdatePlayerMutation, UpdatePlayerMutationVariables>;
export const RemovePlayerDocument = gql`
    mutation RemovePlayer($id: uuid!) {
  delete_players_by_pk(id: $id) {
    id
  }
}
    `;
export type RemovePlayerMutationFn = Apollo.MutationFunction<RemovePlayerMutation, RemovePlayerMutationVariables>;

/**
 * __useRemovePlayerMutation__
 *
 * To run a mutation, you first call `useRemovePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlayerMutation, { data, loading, error }] = useRemovePlayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePlayerMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlayerMutation, RemovePlayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlayerMutation, RemovePlayerMutationVariables>(RemovePlayerDocument, options);
      }
export type RemovePlayerMutationHookResult = ReturnType<typeof useRemovePlayerMutation>;
export type RemovePlayerMutationResult = Apollo.MutationResult<RemovePlayerMutation>;
export type RemovePlayerMutationOptions = Apollo.BaseMutationOptions<RemovePlayerMutation, RemovePlayerMutationVariables>;
export const DeleteRoundDocument = gql`
    mutation DeleteRound($id: uuid!, $gameId: uuid!, $rounds: [rounds_insert_input!]!) {
  delete_rounds_by_pk(id: $id) {
    id
  }
  insert_games_one(
    object: {id: $gameId, rounds: {data: $rounds, on_conflict: {constraint: rounds_pkey, update_columns: [order_sequence]}}}
    on_conflict: {constraint: games_pkey, update_columns: [id]}
  ) {
    id
    rounds {
      id
      order_sequence
    }
  }
}
    `;
export type DeleteRoundMutationFn = Apollo.MutationFunction<DeleteRoundMutation, DeleteRoundMutationVariables>;

/**
 * __useDeleteRoundMutation__
 *
 * To run a mutation, you first call `useDeleteRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoundMutation, { data, loading, error }] = useDeleteRoundMutation({
 *   variables: {
 *      id: // value for 'id'
 *      gameId: // value for 'gameId'
 *      rounds: // value for 'rounds'
 *   },
 * });
 */
export function useDeleteRoundMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoundMutation, DeleteRoundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoundMutation, DeleteRoundMutationVariables>(DeleteRoundDocument, options);
      }
export type DeleteRoundMutationHookResult = ReturnType<typeof useDeleteRoundMutation>;
export type DeleteRoundMutationResult = Apollo.MutationResult<DeleteRoundMutation>;
export type DeleteRoundMutationOptions = Apollo.BaseMutationOptions<DeleteRoundMutation, DeleteRoundMutationVariables>;
export const DeleteRoundsDocument = gql`
    mutation DeleteRounds($ids: [uuid!]!, $gameId: uuid!, $rounds: [rounds_insert_input!]!) {
  delete_rounds(where: {id: {_in: $ids}}) {
    affected_rows
  }
  insert_games_one(
    object: {id: $gameId, rounds: {data: $rounds, on_conflict: {constraint: rounds_pkey, update_columns: [order_sequence]}}}
    on_conflict: {constraint: games_pkey, update_columns: [id]}
  ) {
    id
    rounds {
      id
      order_sequence
    }
  }
}
    `;
export type DeleteRoundsMutationFn = Apollo.MutationFunction<DeleteRoundsMutation, DeleteRoundsMutationVariables>;

/**
 * __useDeleteRoundsMutation__
 *
 * To run a mutation, you first call `useDeleteRoundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoundsMutation, { data, loading, error }] = useDeleteRoundsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      gameId: // value for 'gameId'
 *      rounds: // value for 'rounds'
 *   },
 * });
 */
export function useDeleteRoundsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoundsMutation, DeleteRoundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoundsMutation, DeleteRoundsMutationVariables>(DeleteRoundsDocument, options);
      }
export type DeleteRoundsMutationHookResult = ReturnType<typeof useDeleteRoundsMutation>;
export type DeleteRoundsMutationResult = Apollo.MutationResult<DeleteRoundsMutation>;
export type DeleteRoundsMutationOptions = Apollo.BaseMutationOptions<DeleteRoundsMutation, DeleteRoundsMutationVariables>;
export const UpdateAllRoundsDocument = gql`
    mutation UpdateAllRounds($gameId: uuid!, $rounds: [rounds_insert_input!]!) {
  insert_games_one(
    object: {id: $gameId, rounds: {data: $rounds, on_conflict: {constraint: rounds_pkey, update_columns: [order_sequence]}}}
    on_conflict: {constraint: games_pkey, update_columns: [id]}
  ) {
    id
    rounds {
      id
      order_sequence
    }
  }
}
    `;
export type UpdateAllRoundsMutationFn = Apollo.MutationFunction<UpdateAllRoundsMutation, UpdateAllRoundsMutationVariables>;

/**
 * __useUpdateAllRoundsMutation__
 *
 * To run a mutation, you first call `useUpdateAllRoundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllRoundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllRoundsMutation, { data, loading, error }] = useUpdateAllRoundsMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      rounds: // value for 'rounds'
 *   },
 * });
 */
export function useUpdateAllRoundsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllRoundsMutation, UpdateAllRoundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAllRoundsMutation, UpdateAllRoundsMutationVariables>(UpdateAllRoundsDocument, options);
      }
export type UpdateAllRoundsMutationHookResult = ReturnType<typeof useUpdateAllRoundsMutation>;
export type UpdateAllRoundsMutationResult = Apollo.MutationResult<UpdateAllRoundsMutation>;
export type UpdateAllRoundsMutationOptions = Apollo.BaseMutationOptions<UpdateAllRoundsMutation, UpdateAllRoundsMutationVariables>;
export const AddRoundDocument = gql`
    mutation AddRound($object: rounds_insert_input!) {
  insert_rounds_one(object: $object) {
    id
    value
    order_sequence
  }
}
    `;
export type AddRoundMutationFn = Apollo.MutationFunction<AddRoundMutation, AddRoundMutationVariables>;

/**
 * __useAddRoundMutation__
 *
 * To run a mutation, you first call `useAddRoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoundMutation, { data, loading, error }] = useAddRoundMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddRoundMutation(baseOptions?: Apollo.MutationHookOptions<AddRoundMutation, AddRoundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoundMutation, AddRoundMutationVariables>(AddRoundDocument, options);
      }
export type AddRoundMutationHookResult = ReturnType<typeof useAddRoundMutation>;
export type AddRoundMutationResult = Apollo.MutationResult<AddRoundMutation>;
export type AddRoundMutationOptions = Apollo.BaseMutationOptions<AddRoundMutation, AddRoundMutationVariables>;
export const AddRoundsDocument = gql`
    mutation AddRounds($objects: [rounds_insert_input!]!) {
  insert_rounds(objects: $objects) {
    returning {
      id
      value
    }
    affected_rows
  }
}
    `;
export type AddRoundsMutationFn = Apollo.MutationFunction<AddRoundsMutation, AddRoundsMutationVariables>;

/**
 * __useAddRoundsMutation__
 *
 * To run a mutation, you first call `useAddRoundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoundsMutation, { data, loading, error }] = useAddRoundsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddRoundsMutation(baseOptions?: Apollo.MutationHookOptions<AddRoundsMutation, AddRoundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoundsMutation, AddRoundsMutationVariables>(AddRoundsDocument, options);
      }
export type AddRoundsMutationHookResult = ReturnType<typeof useAddRoundsMutation>;
export type AddRoundsMutationResult = Apollo.MutationResult<AddRoundsMutation>;
export type AddRoundsMutationOptions = Apollo.BaseMutationOptions<AddRoundsMutation, AddRoundsMutationVariables>;
export const LoadWordsDocument = gql`
    mutation LoadWords($objects: [cards_insert_input!]!) {
  insert_cards(objects: $objects) {
    returning {
      id
    }
    affected_rows
  }
}
    `;
export type LoadWordsMutationFn = Apollo.MutationFunction<LoadWordsMutation, LoadWordsMutationVariables>;

/**
 * __useLoadWordsMutation__
 *
 * To run a mutation, you first call `useLoadWordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadWordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadWordsMutation, { data, loading, error }] = useLoadWordsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useLoadWordsMutation(baseOptions?: Apollo.MutationHookOptions<LoadWordsMutation, LoadWordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadWordsMutation, LoadWordsMutationVariables>(LoadWordsDocument, options);
      }
export type LoadWordsMutationHookResult = ReturnType<typeof useLoadWordsMutation>;
export type LoadWordsMutationResult = Apollo.MutationResult<LoadWordsMutation>;
export type LoadWordsMutationOptions = Apollo.BaseMutationOptions<LoadWordsMutation, LoadWordsMutationVariables>;
export const CreateTurnDocument = gql`
    mutation CreateTurn($gameId: uuid!, $playerId: uuid!, $roundId: uuid!) {
  insert_turns_one(
    object: {game_id: $gameId, player_id: $playerId, round_id: $roundId}
  ) {
    id
    game_id
    player_id
    round_id
  }
}
    `;
export type CreateTurnMutationFn = Apollo.MutationFunction<CreateTurnMutation, CreateTurnMutationVariables>;

/**
 * __useCreateTurnMutation__
 *
 * To run a mutation, you first call `useCreateTurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTurnMutation, { data, loading, error }] = useCreateTurnMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      playerId: // value for 'playerId'
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useCreateTurnMutation(baseOptions?: Apollo.MutationHookOptions<CreateTurnMutation, CreateTurnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTurnMutation, CreateTurnMutationVariables>(CreateTurnDocument, options);
      }
export type CreateTurnMutationHookResult = ReturnType<typeof useCreateTurnMutation>;
export type CreateTurnMutationResult = Apollo.MutationResult<CreateTurnMutation>;
export type CreateTurnMutationOptions = Apollo.BaseMutationOptions<CreateTurnMutation, CreateTurnMutationVariables>;
export const StartTurnDocument = gql`
    mutation StartTurn($currentTurnId: uuid!) {
  update_turns_by_pk(
    pk_columns: {id: $currentTurnId}
    _set: {started_at: "now()"}
  ) {
    id
    started_at
  }
}
    `;
export type StartTurnMutationFn = Apollo.MutationFunction<StartTurnMutation, StartTurnMutationVariables>;

/**
 * __useStartTurnMutation__
 *
 * To run a mutation, you first call `useStartTurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTurnMutation, { data, loading, error }] = useStartTurnMutation({
 *   variables: {
 *      currentTurnId: // value for 'currentTurnId'
 *   },
 * });
 */
export function useStartTurnMutation(baseOptions?: Apollo.MutationHookOptions<StartTurnMutation, StartTurnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTurnMutation, StartTurnMutationVariables>(StartTurnDocument, options);
      }
export type StartTurnMutationHookResult = ReturnType<typeof useStartTurnMutation>;
export type StartTurnMutationResult = Apollo.MutationResult<StartTurnMutation>;
export type StartTurnMutationOptions = Apollo.BaseMutationOptions<StartTurnMutation, StartTurnMutationVariables>;
export const EndCurrentTurnAndStartNextTurnDocument = gql`
    mutation EndCurrentTurnAndStartNextTurn($currentTurnId: uuid!, $completedCardIds: jsonb!, $gameId: uuid!, $currentTurnScorings: [turn_scorings_insert_input!]!, $nextTurnplayerId: uuid!, $nextTurnSecondsPerTurnOverride: Int, $roundId: uuid) {
  delete_turn_scorings(where: {turn_id: {_eq: $currentTurnId}}) {
    returning {
      id
    }
  }
  insert_turn_scorings(objects: $currentTurnScorings) {
    returning {
      id
    }
  }
  update_turns_by_pk(
    pk_columns: {id: $currentTurnId}
    _set: {ended_at: "now()", completed_card_ids: $completedCardIds}
  ) {
    id
    ended_at
    completed_card_ids
  }
  insert_turns_one(
    object: {game_id: $gameId, player_id: $nextTurnplayerId, seconds_per_turn_override: $nextTurnSecondsPerTurnOverride, round_id: $roundId}
  ) {
    id
    game_id
    player_id
    seconds_per_turn_override
    round_id
  }
}
    `;
export type EndCurrentTurnAndStartNextTurnMutationFn = Apollo.MutationFunction<EndCurrentTurnAndStartNextTurnMutation, EndCurrentTurnAndStartNextTurnMutationVariables>;

/**
 * __useEndCurrentTurnAndStartNextTurnMutation__
 *
 * To run a mutation, you first call `useEndCurrentTurnAndStartNextTurnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndCurrentTurnAndStartNextTurnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endCurrentTurnAndStartNextTurnMutation, { data, loading, error }] = useEndCurrentTurnAndStartNextTurnMutation({
 *   variables: {
 *      currentTurnId: // value for 'currentTurnId'
 *      completedCardIds: // value for 'completedCardIds'
 *      gameId: // value for 'gameId'
 *      currentTurnScorings: // value for 'currentTurnScorings'
 *      nextTurnplayerId: // value for 'nextTurnplayerId'
 *      nextTurnSecondsPerTurnOverride: // value for 'nextTurnSecondsPerTurnOverride'
 *      roundId: // value for 'roundId'
 *   },
 * });
 */
export function useEndCurrentTurnAndStartNextTurnMutation(baseOptions?: Apollo.MutationHookOptions<EndCurrentTurnAndStartNextTurnMutation, EndCurrentTurnAndStartNextTurnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndCurrentTurnAndStartNextTurnMutation, EndCurrentTurnAndStartNextTurnMutationVariables>(EndCurrentTurnAndStartNextTurnDocument, options);
      }
export type EndCurrentTurnAndStartNextTurnMutationHookResult = ReturnType<typeof useEndCurrentTurnAndStartNextTurnMutation>;
export type EndCurrentTurnAndStartNextTurnMutationResult = Apollo.MutationResult<EndCurrentTurnAndStartNextTurnMutation>;
export type EndCurrentTurnAndStartNextTurnMutationOptions = Apollo.BaseMutationOptions<EndCurrentTurnAndStartNextTurnMutation, EndCurrentTurnAndStartNextTurnMutationVariables>;
export const StartReviewDocument = gql`
    mutation StartReview($currentTurnId: uuid!) {
  update_turns_by_pk(
    pk_columns: {id: $currentTurnId}
    _set: {review_started_at: "now()"}
  ) {
    id
    review_started_at
  }
}
    `;
export type StartReviewMutationFn = Apollo.MutationFunction<StartReviewMutation, StartReviewMutationVariables>;

/**
 * __useStartReviewMutation__
 *
 * To run a mutation, you first call `useStartReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startReviewMutation, { data, loading, error }] = useStartReviewMutation({
 *   variables: {
 *      currentTurnId: // value for 'currentTurnId'
 *   },
 * });
 */
export function useStartReviewMutation(baseOptions?: Apollo.MutationHookOptions<StartReviewMutation, StartReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartReviewMutation, StartReviewMutationVariables>(StartReviewDocument, options);
      }
export type StartReviewMutationHookResult = ReturnType<typeof useStartReviewMutation>;
export type StartReviewMutationResult = Apollo.MutationResult<StartReviewMutation>;
export type StartReviewMutationOptions = Apollo.BaseMutationOptions<StartReviewMutation, StartReviewMutationVariables>;
export const ServerTimeDocument = gql`
    query ServerTime {
  server_time {
    now
  }
}
    `;

/**
 * __useServerTimeQuery__
 *
 * To run a query within a React component, call `useServerTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerTimeQuery(baseOptions?: Apollo.QueryHookOptions<ServerTimeQuery, ServerTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServerTimeQuery, ServerTimeQueryVariables>(ServerTimeDocument, options);
      }
export function useServerTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServerTimeQuery, ServerTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServerTimeQuery, ServerTimeQueryVariables>(ServerTimeDocument, options);
        }
export type ServerTimeQueryHookResult = ReturnType<typeof useServerTimeQuery>;
export type ServerTimeLazyQueryHookResult = ReturnType<typeof useServerTimeLazyQuery>;
export type ServerTimeQueryResult = Apollo.QueryResult<ServerTimeQuery, ServerTimeQueryVariables>;
export const UpdateAllPlayersDocument = gql`
    mutation UpdateAllPlayers($gameId: uuid!, $players: [players_insert_input!]!) {
  insert_games_one(
    object: {id: $gameId, players: {data: $players, on_conflict: {constraint: players_pkey, update_columns: [team_id, team_sequence]}}}
    on_conflict: {constraint: games_pkey, update_columns: [id]}
  ) {
    id
    players {
      id
      game_id
      team {
        name
        team_color
      }
      team_sequence
    }
  }
}
    `;
export type UpdateAllPlayersMutationFn = Apollo.MutationFunction<UpdateAllPlayersMutation, UpdateAllPlayersMutationVariables>;

/**
 * __useUpdateAllPlayersMutation__
 *
 * To run a mutation, you first call `useUpdateAllPlayersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllPlayersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllPlayersMutation, { data, loading, error }] = useUpdateAllPlayersMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      players: // value for 'players'
 *   },
 * });
 */
export function useUpdateAllPlayersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllPlayersMutation, UpdateAllPlayersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAllPlayersMutation, UpdateAllPlayersMutationVariables>(UpdateAllPlayersDocument, options);
      }
export type UpdateAllPlayersMutationHookResult = ReturnType<typeof useUpdateAllPlayersMutation>;
export type UpdateAllPlayersMutationResult = Apollo.MutationResult<UpdateAllPlayersMutation>;
export type UpdateAllPlayersMutationOptions = Apollo.BaseMutationOptions<UpdateAllPlayersMutation, UpdateAllPlayersMutationVariables>;
export const UpdateTeamNameDocument = gql`
    mutation UpdateTeamName($id: uuid!, $name: String!) {
  update_teams_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
    name
  }
}
    `;
export type UpdateTeamNameMutationFn = Apollo.MutationFunction<UpdateTeamNameMutation, UpdateTeamNameMutationVariables>;

/**
 * __useUpdateTeamNameMutation__
 *
 * To run a mutation, you first call `useUpdateTeamNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamNameMutation, { data, loading, error }] = useUpdateTeamNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateTeamNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamNameMutation, UpdateTeamNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamNameMutation, UpdateTeamNameMutationVariables>(UpdateTeamNameDocument, options);
      }
export type UpdateTeamNameMutationHookResult = ReturnType<typeof useUpdateTeamNameMutation>;
export type UpdateTeamNameMutationResult = Apollo.MutationResult<UpdateTeamNameMutation>;
export type UpdateTeamNameMutationOptions = Apollo.BaseMutationOptions<UpdateTeamNameMutation, UpdateTeamNameMutationVariables>;