import React from "react"
import { CheckBox as RNECheckBox } from "react-native-elements"

type CheckboxComponentProps = {
  defaultValue: boolean
  handlePress: (newVal: boolean) => void
  [x: string]: unknown
}

export const CheckBox: React.FC<CheckboxComponentProps> = ({
  defaultValue,
  handlePress,
  ...props
}) => {
  const [check, setCheck] = React.useState(defaultValue)

  return (
    <RNECheckBox
      checked={check}
      onPress={() => {
        const newCheck = !check
        setCheck(newCheck)
        handlePress(newCheck)
      }}
      {...props}
    />
  )
}
