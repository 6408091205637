import React from "react"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { GameStateEnum } from "@gql"
import { GameRoutes, RootRoutes } from "@common/routes"
import { useNavigation, useFocusEffect } from "@react-navigation/native"
import { LoadingScreen } from "../LoadingScreen"

interface IProps {
  joinCode: string
  hideLoading?: boolean
}

export const getNavigateComponentName = (currentGameState: GameStateEnum) => {
  switch (currentGameState) {
    case GameStateEnum.Lobby:
      return GameRoutes.Lobby
    case GameStateEnum.CardSubmission:
      return GameRoutes.CardSubmission
    case GameStateEnum.TeamAssignment:
      return GameRoutes.TeamAssignment
    case GameStateEnum.TeamNameCreation:
      return GameRoutes.TeamNameCreation
    case GameStateEnum.ActivePlay:
      return GameRoutes.ActivePlay
    case GameStateEnum.Ended:
      return GameRoutes.Ended
    default:
      return GameRoutes.Lobby
  }
}

export const GameStateRedirects: React.FC<IProps> = ({
  joinCode,
  hideLoading = false,
}) => {
  const currentGame = React.useContext(CurrentGameContext)
  const navigation = useNavigation<any>()
  const routes = navigation.getState().routes

  useFocusEffect(
    React.useCallback(() => {
      if (!currentGame) {
        navigation.navigate(RootRoutes.Home)
      }
    }, [currentGame])
  )

  React.useEffect(() => {
    const currentRouteName = routes[routes.length - 1].name
    const navigateTo = getNavigateComponentName(currentGame.state)

    if (currentRouteName !== navigateTo) {
      setTimeout(() => {
        navigation.replace(navigateTo, {
          joinCode: currentGame?.join_code?.toLocaleUpperCase() ?? joinCode,
        })
      }, 0)
    }
  }, [currentGame.state])

  return hideLoading ? null : <LoadingScreen />
}
