import React from "react"
import { View } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { SettingsSummary } from "./SettingsSummary"
import { grid, vertGrid } from "@common/theme"

export const ParticpantSettingsSummary = () => {
  const currentGame = React.useContext(CurrentGameContext)

  return (
    <View style={{ paddingHorizontal: grid(1), marginVertical: vertGrid(1) }}>
      <SettingsSummary
        numEntriesPerPlayer={Number(currentGame.num_entries_per_player)}
        startingLetter={currentGame.starting_letter || ""}
        screenCard={currentGame.screen_cards}
        secondsPerTurn={Number(currentGame.seconds_per_turn)}
        allowCardSkips={currentGame.allow_card_skips}
        rounds={currentGame.rounds}
      />
    </View>
  )
}
