/*
  Color takens from Better Design System:
  (https://www.figma.com/file/cbcihjsqma9k1SbHgKbzja/Tokens%3A-Colors?node-id=1583%3A4511&viewport=4213%2C-6909%2C0.18009395897388458)
*/
// Color Primitives
const COLOR_BLACK = '#292B29';
const COLOR_BLACK_40 = '#292B2966';
const COLOR_GRAY = '#494341';
const COLOR_GRAY_700 = '#444B48';
const COLOR_GRAY_500 = '#6F7672';
const COLOR_GRAY_400 = '#A4A8A4';
const COLOR_GRAY_300 = '#C7C9C5';
const COLOR_GRAY_200 = '#DFE0DC';
const COLOR_GRAY_100 = '#DFE0DC';
const COLOR_GRAY_50 = '#F6F6F3';
const COLOR_CAPTION = '#6F6F6F';
const COLOR_WHITE = '#FFFFFF';
const COLOR_GREEN = '#ADEBBE';
const COLOR_GREEN_900 = '#073126';
const COLOR_GREEN_700 = '#004733';
const COLOR_GREEN_300 = '#99D6A9';
const COLOR_GREEN_100 = '#CCE9D3';
const COLOR_GREEN_50 = '#F0F7F1';
const COLOR_LIME = '#1EE07F';
const COLOR_NAVY = '#22577E';
const COLOR_BLUE = '#516091';
const COLOR_LIGHT_BLUE = '#5584AC';
const COLOR_TEAL = '#74BEC1';
const COLOR_INDIGO_900 = '#3E089B';
const COLOR_INDIGO_100 = '#C6CAF1';
const COLOR_INDIGO_50 = '#E6E8F9';
const COLOR_RED_900 = '#DD4A48';
const COLOR_RED_100 = '#F8D4CC';
const COLOR_RED_50 = '#F6F0EE';
const COLOR_YELLOW = '#EEF3AD';
const COLOR_YELLOW_900 = '#B24A00';
const COLOR_YELLOW_100 = '#F8EBC9';
const COLOR_YELLOW_50 = '#FBF6E9';

// Design System Color Tokens
// https://www.figma.com/file/cbcihjsqma9k1SbHgKbzja/Tokens%3A-Colors?node-id=1583%3A4511&viewport=4213%2C-6909%2C0.18009395897388458
export const color = {
  // Base Theme colors
  primary: COLOR_GREEN,
  background_color: COLOR_TEAL,
  text: COLOR_BLACK,
  border: COLOR_GRAY_400,

  pastel_gray: COLOR_GRAY,
  pastel_navy: COLOR_NAVY,
  pastel_blue: COLOR_BLUE,
  pastel_light_blue: COLOR_LIGHT_BLUE,
  pastel_teal: COLOR_TEAL,
  pastel_green: COLOR_GREEN,
  pastel_yellow: COLOR_YELLOW,

  background_primary: COLOR_WHITE,
  background_secondary: COLOR_GRAY_50,
  background_tertiary: COLOR_WHITE,
  background_overlay: COLOR_BLACK_40,
  background_inverse_primary: COLOR_GREEN,
  background_inverse_secondary: COLOR_GREEN_900,

  text_primary: COLOR_GRAY,
  text_secondary: COLOR_WHITE,
  text_tertiary: COLOR_WHITE,
  text_highlight: COLOR_GREEN,
  text_inverse_primary: COLOR_WHITE,
  text_inverse_secondary: COLOR_WHITE,
  text_inverse_highlight: COLOR_LIME,
  text_caption: COLOR_CAPTION,

  stroke_border: COLOR_GRAY_400,
  stroke_divider: COLOR_GRAY_300,
  stroke_inverse_border: COLOR_GREEN_300,
  stroke_inverse_divider: COLOR_GREEN_300,

  interactive_primary: COLOR_GREEN,
  interactive_secondary: COLOR_BLUE,
  interactive_tertiary: COLOR_TEAL,
  interactive_disabled: COLOR_GRAY_300,
  interactive_foreground_primary: COLOR_GRAY,
  interactive_foreground_secondary: COLOR_WHITE,
  interactive_foreground_muted: COLOR_GRAY_500,

  accent_primary: COLOR_GREEN_700,
  accent_secondary: COLOR_GRAY_300,
  accent_background: COLOR_GRAY_100,
  accent_border_primary: COLOR_GREEN_300,
  accent_border_secondary: COLOR_GREEN,
  accent_border_inverse_secondary: COLOR_WHITE,

  success_primary: COLOR_GREEN_700,
  success_background: COLOR_GREEN_50,
  success_accent: COLOR_GREEN_100,

  error_primary: COLOR_RED_900,
  error_background: COLOR_RED_50,
  error_accent: COLOR_RED_100,

  warning_primary: COLOR_YELLOW_900,
  warning_background: COLOR_YELLOW_50,
  warning_accent: COLOR_YELLOW_100,

  info_primary: COLOR_INDIGO_900,
  info_background: COLOR_INDIGO_50,
  info_accent: COLOR_INDIGO_100,
};

export interface PlayStateColorsType {
  stop: string
  wait: string
  go: string
  alt: string
}
export const PlayStateColors: PlayStateColorsType = {
  stop: "#d90000",
  wait: "#d96c00",
  go: "#00a653",
  alt: "#0000d9",
}