export enum GameRoutes {
    Redirect = 'Redirect',
    Loading = 'Loading',
    Lobby = 'Lobby',
    Settings = 'Settings',
    CardSubmission = 'CardSubmission',
    TeamAssignment = 'TeamAssignment',
    TeamNameCreation = 'TeamNameCreation',
    ActivePlay = 'ActivePlay',
    Ended = 'Ended',
  }
  
  export type GameNavigatorParams = {
    [GameRoutes.Loading]: undefined;
    [GameRoutes.Lobby]: { joinCode: string };
    [GameRoutes.Settings]: { joinCode: string, showShareCode: boolean, showHostControls: boolean };
    [GameRoutes.CardSubmission]: { joinCode: string };
    [GameRoutes.TeamAssignment]: { joinCode: string };
    [GameRoutes.TeamNameCreation]: { joinCode: string };
    [GameRoutes.ActivePlay]: { joinCode: string };
    [GameRoutes.Ended]: { joinCode: string };
  };