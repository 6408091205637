import { Winner } from "./types"
import { positions } from "./constants"
import { MotiView } from "moti"
import { View, Text } from "react-native"
import { theme } from "@common/theme"
import { PlayerChip } from "@common/components/PlayerChip"

interface Props {
  readonly winners: Winner[]
  readonly winner: Winner
  readonly index: number
}

export function WinnerCard({ winners, winner, index }: Props) {
  return (
    <MotiView
      key={winner.id}
      from={{ opacity: 0, translateY: -100 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{
        delay: 1000 * (winners.length - winner.place + 1),
        type: "timing",
        duration: 500,
      }}
      style={{ flex: 1 }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          margin: 3,
          borderRadius: 10,
          borderWidth: 1,
          backgroundColor: "#fff",
          borderColor: theme.color.accent_secondary,
          shadowColor: "rgba(0,0,0,0.5)",
          shadowOffset: {
            width: 0,
            height: 1,
          },
        }}
      >
        <View
          style={{
            justifyContent: "center",
            marginLeft: 20,
            width: "10%",
          }}
        >
          <Text>{positions[winner.place]}</Text>
        </View>

        <View
          style={{
            justifyContent: "center",
            marginLeft: 10,
          }}
        >
          <PlayerChip teamColor={winner.team?.team_color} showText={false}>
            {winner.username}
          </PlayerChip>
        </View>

        {/* <img
          src={winner.avatar}
          alt=""
          className="rounded-full overflow-hidden border border-gray-200 shadow-sm w-11 h-11 mr-3"
        /> */}

        <View
          style={{
            flexGrow: 1,
            justifyContent: "center",
            marginLeft: 10,
          }}
        >
          <Text>{winner.username || "No name"}</Text>
          <Text>{winner.score || "0"} points</Text>
        </View>
      </View>
    </MotiView>
  )
}
