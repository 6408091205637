import React from "react"
import { View, StyleSheet } from "react-native"
import { ButtonGroup } from "react-native-elements"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import {
  GameStateEnum,
  useCreateTurnMutation,
  useUpdateGameStateMutation,
  useUpdateTeamNameMutation,
} from "@gql"
import { nextPlayerForNextTeam } from "@common/utils/turn"
import { useTranslation } from "react-i18next"
import { grid, theme, vertGrid } from "@common/theme"
import { Text, Button, SafeAreaView } from "@common/components"
import { adjectives, pluralNouns } from "@assets/texts/words"
import _ from "lodash"
import { TeamColor } from "@common/utils/team"
import { usePlaySound } from "@common/hooks/usePlaySound"

export const TeamNameCreation = () => {
  const { t } = useTranslation()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const [updateGameState] = useUpdateGameStateMutation()
  const [createFirstTurn] = useCreateTurnMutation()
  const [updateTeamName] = useUpdateTeamNameMutation()
  const [selectedAdjectiveIndex, setSelectedAdjectiveIndex] =
    React.useState(null)
  const [selectedPluralNounIndex, setSelectedPluralNounIndex] =
    React.useState(null)
  const playClickSound = usePlaySound("click")

  const gamePlayer = currentGame.players.find(
    (player) => player.client_uuid === currentPlayer.client_uuid
  )
  const isCaptain = gamePlayer?.team_sequence === 0

  const myCaptain = currentGame.players.find(
    (player) =>
      player.team_sequence === 0 && player.team_id === gamePlayer?.team_id
  )

  const otherCaptain = currentGame.players.find(
    (player) =>
      player.team_sequence === 0 && player.team_id != gamePlayer?.team_id
  )

  const sampledAdjectives = React.useMemo(
    () => _.sampleSize(adjectives, 3).map((word) => _.upperFirst(word)),
    []
  )

  const sampledPluralNoun = React.useMemo(
    () => _.sampleSize(pluralNouns, 3).map((word) => _.upperFirst(word)),
    []
  )

  const teamsWithName = currentGame.teams.filter((team) => team.name !== null)
  const bothTeamsDone = teamsWithName.length == 2
  const currentTeamName = gamePlayer?.team?.name
  const isHost = currentPlayer.role === PlayerRole.Host

  let subTitle: string
  if (!currentTeamName) {
    if (isCaptain) {
      subTitle = t(
        "teams.captainSubtitle",
        "You are team captain! Pick a team name."
      )
    } else {
      subTitle = t(
        "teams.waitingOnCaptainSubtitle",
        "Your team captain " + myCaptain?.username + " is deciding."
      )
    }
  } else {
    if (bothTeamsDone) {
      if (isHost) {
        subTitle = t(
          "teams.readyUpSubtitle",
          "Waiting for the host (you) to start the game."
        )
      } else {
        subTitle = t(
          "teams.waitingOnHostSubtitle",
          "Waiting for the host to start the game."
        )
      }
    } else {
      subTitle = t(
        "teams.waitingOnHostSubtitle",
        "Waiting for the other captain, " + otherCaptain?.username + "."
      )
    }
  }

  let teamName: string
  const filler = "_____"
  if (isCaptain) {
    teamName = `The ${
      selectedAdjectiveIndex !== null
        ? sampledAdjectives[selectedAdjectiveIndex]
        : filler
    } ${
      selectedPluralNounIndex !== null
        ? sampledPluralNoun[selectedPluralNounIndex]
        : filler
    }`
  } else {
    if (currentTeamName) {
      teamName = currentTeamName
    } else {
      teamName = `The ${filler} ${filler}`
    }
  }

  React.useEffect(() => {
    if (
      isCaptain &&
      selectedAdjectiveIndex !== null &&
      selectedPluralNounIndex !== null
    ) {
      updateTeamName({
        variables: {
          id: gamePlayer.team_id,
          name: teamName,
        },
      })
    }
  }, [teamName])

  return (
    <View style={styles.teamNameCreationContainer}>
      <SafeAreaView style={{ flex: 1, flexDirection: "column" }}>
        <Text variant="h1" fontVariant="LuckiestGuy" style={styles.title}>
          {t("teams.title", "Team Names")}
        </Text>
        <Text style={styles.subTitle}>{subTitle}</Text>

        <View style={{ flex: 1, justifyContent: "center" }}>
          <Text
            variant="h1"
            fontVariant="ComicNeueBold"
            style={[
              styles.teamTitle,
              {
                color: gamePlayer?.team?.team_color
                  ? TeamColor[gamePlayer?.team?.team_color]
                  : theme.color.pastel_yellow,
              },
            ]}
          >
            {teamName}
          </Text>
          {isCaptain && (
            <>
              <ButtonGroup
                buttons={sampledAdjectives}
                selectedIndex={selectedAdjectiveIndex}
                textStyle={styles.buttonGroupText}
                containerStyle={styles.buttonGroupContainer}
                selectedButtonStyle={styles.selectedButton}
                selectedTextStyle={styles.selectedButtonText}
                innerBorderStyle={{
                  width: 1,
                  color: theme.color.pastel_green,
                }}
                onPress={(value) => {
                  playClickSound()
                  setSelectedAdjectiveIndex(value)
                }}
              />
              <ButtonGroup
                buttons={sampledPluralNoun}
                selectedIndex={selectedPluralNounIndex}
                textStyle={styles.buttonGroupText}
                containerStyle={styles.buttonGroupContainer}
                selectedButtonStyle={styles.selectedButton}
                selectedTextStyle={styles.selectedButtonText}
                innerBorderStyle={{
                  width: 1,
                  color: theme.color.pastel_green,
                }}
                onPress={(value) => {
                  playClickSound()
                  setSelectedPluralNounIndex(value)
                }}
              />
            </>
          )}
        </View>

        {currentPlayer.role === PlayerRole.Host && (
          <>
            <View style={styles.buttonContainer}>
              <Button
                block
                disabled={!bothTeamsDone}
                onPress={async () => {
                  await createFirstTurn({
                    variables: {
                      gameId: currentGame.id,
                      playerId: nextPlayerForNextTeam(
                        null,
                        currentGame.turns,
                        currentGame.players
                      ).id,
                      roundId: currentGame.rounds[0].id,
                    },
                  })
                  updateGameState({
                    variables: {
                      id: currentGame.id,
                      state: GameStateEnum.ActivePlay,
                    },
                  })
                }}
                textStyle={styles.buttonText}
              >
                {t("teams.startGameButton", "Start Game")}
              </Button>
            </View>
          </>
        )}
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    textAlign: "center",
    margin: 10,
  },
  subTitle: {
    ...theme.fonts.h3,
    textAlign: "center",
  },
  buttonContainer: {
    marginVertical: vertGrid(2),
    marginHorizontal: grid(2),
  },
  buttonText: {
    ...theme.fonts.h2,
  },
  bottomText: {
    margin: 10,
  },
  teamNameCreationContainer: {
    flex: 1,
    backgroundColor: theme.color.pastel_teal,
  },
  teamTitle: {
    textAlign: "center",
    color: theme.color.pastel_yellow,
    marginBottom: vertGrid(2),
    fontWeight: "600",
  },
  buttonGroupContainer: {
    height: 50,
    marginBottom: vertGrid(2),
    backgroundColor: "transparent",
    borderColor: theme.color.pastel_green,
  },
  buttonGroupText: {
    fontSize: 16,
  },
  selectedButton: {
    backgroundColor: theme.color.pastel_green,
  },
  selectedButtonText: {
    color: theme.color.text_primary,
  },
})
