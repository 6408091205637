import {
  StackCardInterpolatedStyle,
  StackCardInterpolationProps,
} from '@react-navigation/stack/src/types';
import { Animated } from 'react-native';
const { add, multiply } = Animated;

interface IProps {
  // The navigation stack index of the page under your modal.
  // This can be found with a quick console.log of index in the interpolator below.
  baseIndex: number;
  topOffset?: number;
  customHeight?: number;
}

// Based off of forModalPresentationIOS interpolator in @react-navigation
/**
 *
 * @param param0 customHeight
 * @usage define cutom card style modeal height (distance from the top),
 * gestureResponseDistance must be set to registered the starting point of the gesture control, default is 'vertical: 125', horizontal: 25.
 */
export function drawerInterpolator({
  index,
  current,
  next,
  inverted,
  layouts: { screen },
  insets,
  customHeight,
  baseIndex = 0,
  topOffset = 0,
}: StackCardInterpolationProps & IProps): StackCardInterpolatedStyle {
  const statusBarHeight = customHeight ?? insets.top;
  const aspectRatio = screen.height / screen.width;

  const progress = add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: 'clamp',
        })
      : 0
  );

  const translateY = multiply(
    progress.interpolate({
      inputRange: [0, 1, 2],
      outputRange: [
        screen.height,
        index === baseIndex ? 0 : topOffset,
        0 - topOffset * aspectRatio,
      ],
    }),
    inverted
  );

  const overlayOpacity = progress.interpolate({
    inputRange: [0, 1, 1.0001, 2],
    outputRange: [0, 0.5, 1, 1],
  });

  return {
    cardStyle: {
      overflow: 'hidden',
      borderTopLeftRadius: index === baseIndex ? 0 : 22,
      borderTopRightRadius: index === baseIndex ? 0 : 22,
      marginTop: index === baseIndex ? 0 : statusBarHeight,
      marginBottom: index === baseIndex ? 0 : topOffset,
      // only translate y for the modal and not the previous screen
      transform: !next ? [{ translateY }] : undefined,
    },
    overlayStyle: { opacity: overlayOpacity },
  };
}