import React from "react"
import { View, TouchableOpacity, Text, StyleSheet } from "react-native"
// import CloseIcon from "common_images/close_new_24px.svg"
import { grid, theme } from "@common/theme"
import { Handlebar } from "../Handlerbar/Handlebar"
import { Ionicons } from "@expo/vector-icons"

interface IProps {
  title?: string
  leftTitle?: string
  leftOnPressed?: () => void
  onDismiss?: () => void
  showLineSeparator?: boolean
  showHandleBar?: boolean
  testID?: string
}

export const ModalHeader: React.FC<IProps> = ({
  title,
  leftTitle,
  leftOnPressed,
  onDismiss,
  showLineSeparator = true,
  showHandleBar = true,
}) => {
  return (
    <>
      {showHandleBar && <Handlebar />}
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <TouchableOpacity
            onPress={leftOnPressed}
            testID={"modal:header:left_button"}
          >
            <Text style={styles.leftText} testID={"modal:header:left_text"}>
              {leftTitle}
            </Text>
          </TouchableOpacity>
          <Text style={styles.title} testID={"modal:header:title"}>
            {title}
          </Text>
          {onDismiss ? (
            <TouchableOpacity
              onPress={onDismiss}
              testID={"modal:header:close"}
              accessibilityRole="image"
              accessibilityLabel="Close icon"
              accessibilityHint="Press to close current screen"
            >
              <Ionicons color="black" name="close" size={24} />
            </TouchableOpacity>
          ) : (
            <View></View>
          )}
        </View>
      </View>
      {showLineSeparator && <View style={styles.headerSeparator} />}
    </>
  )
}

const styles = StyleSheet.create({
  closeIcon: { alignSelf: "center" },
  container: {
    flexDirection: "column",
    marginLeft: grid(2),
    marginRight: grid(2),
    marginTop: 30,
  },
  headerContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerSeparator: {
    backgroundColor: theme.color.stroke_divider,
    borderWidth: 0.3,
    marginTop: 30,
  },
  leftText: {
    alignSelf: "center",
    color: theme.color.text_secondary,
    ...theme.fonts.h4,
  },
  title: {
    alignSelf: "center",
    color: theme.color.text_primary,
    marginRight: 1,
    ...theme.fonts.h3,
  },
})
