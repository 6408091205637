import React from "react"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { GameRoutes } from "@common/routes"
import { theme } from "@common/theme"
import { FontAwesome } from "@expo/vector-icons"
import { GameCardPlayStyleEnum } from "@gql"
import { useNavigation } from "@react-navigation/native"
import { TouchableOpacity, ViewStyle } from "react-native"

interface IProps {
  showShareCode?: boolean
  style?: ViewStyle
}

export const SettingsGear: React.FC<IProps> = ({
  showShareCode = false,
  style = {},
}) => {
  const navigation = useNavigation<any>()
  const currentGame = React.useContext(CurrentGameContext)
  const [cardPlayStyle, setCardPlayStyle] = React.useState(
    GameCardPlayStyleEnum.PlayersSubmitWords
  )

  React.useEffect(() => {
    setCardPlayStyle(currentGame.card_play_style)
  }, [currentGame.card_play_style])

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate(GameRoutes.Settings, {
          showShareCode: showShareCode,
          joinCode: currentGame.join_code?.toLocaleUpperCase(),
          cardPlayStyle,
        })
      }}
      style={style}
    >
      <FontAwesome name="gear" size={20} color={theme.color.text_primary} />
    </TouchableOpacity>
  )
}
