import React from "react"
import { View } from "react-native"
import type { StyleProp, ViewStyle } from "react-native"
import Svg, { Path } from "react-native-svg"
import { useCountdown, getWrapperStyle, timeStyle } from "./helper"
import type { Props } from "./types"

const CountdownCircleTimer = (props: Props) => {
  const {
    children,
    remainingTime,
    strokeLinecap,
    trailColor,
    trailStrokeWidth,
  } = props
  const {
    path,
    pathLength,
    stroke,
    strokeDashoffset,
    elapsedTime,
    size,
    strokeWidth,
  } = useCountdown(props)

  return (
    <View style={getWrapperStyle(size) as StyleProp<ViewStyle>}>
      <Svg width={size} height={size}>
        <Path
          d={path}
          fill="none"
          stroke={trailColor ?? "#d9d9d9"}
          strokeWidth={trailStrokeWidth ?? strokeWidth}
        />
        <Path
          d={path}
          fill="none"
          stroke={stroke}
          strokeLinecap={strokeLinecap ?? "round"}
          strokeWidth={strokeWidth}
          strokeDasharray={pathLength}
          strokeDashoffset={strokeDashoffset}
        />
      </Svg>
      <View style={timeStyle as StyleProp<ViewStyle>}>
        {typeof children === "function"
          ? children({ remainingTime, elapsedTime, color: stroke })
          : children}
      </View>
    </View>
  )
}

CountdownCircleTimer.displayName = "CountdownCircleTimer"

export { CountdownCircleTimer, useCountdown }
