import { useLazyQuery } from "@apollo/react-hooks"
import {
  CurrentPlayerQuery,
  useJoinGameMutation,
  GameByJoinCodeDocument,
  CurrentPlayerDocument,
} from "@gql"
import { createContext, useContext, useState, useEffect } from "react"
import "react-native-get-random-values"
import { v4 as uuidv4 } from "uuid"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { CurrentAuthContext } from "./CurrentAuthService"
import { useServerTimeOffset } from "../hooks/useServerTimeOffset"
import { flashMessage } from "@common/components"

const asyncStorageKey = "player.client_uuid"

export const clientUuid = async (): Promise<string> => {
  const value = await AsyncStorage.getItem(asyncStorageKey)
  if (value !== null) {
    return value
  } else {
    const uuid: string = uuidv4()
    await AsyncStorage.setItem(asyncStorageKey, uuid)
    return uuid
  }
}

export const setClientUuid = async (uuid: string) => {
  await AsyncStorage.setItem(asyncStorageKey, uuid)
}

export enum PlayerRole {
  Participant = 0,
  Host,
}

export type CurrentPlayerContextType = CurrentPlayerQuery["players"][0] & {
  role: PlayerRole
  serverTimeOffset: number
}

export const CurrentPlayerContext = createContext<CurrentPlayerContextType>({
  id: "",
  role: PlayerRole.Participant,
  serverTimeOffset: 0,
  game: {
    id: "",
  },
})

export const CurrentPlayerProvider = (props: {
  joinCode: string
  children: React.ReactNode
}) => {
  const currentAuth = useContext(CurrentAuthContext)
  const serverTimeOffset = useServerTimeOffset()
  const [currentPlayer, setCurrentPlayer] = useState<
    CurrentPlayerQuery["players"][0] | null
  >(null)
  const [clientId, setClientId] = useState("")

  // https://github.com/apollographql/react-apollo/issues/3505#issuecomment-568112061
  const [skipCallback, setSkipCallback] = useState(false)
  const [joinGame] = useJoinGameMutation()
  const [signUp] = useLazyQuery(GameByJoinCodeDocument, {
    variables: { joinCode: props.joinCode.toLocaleUpperCase() },
    onCompleted: async (data) => {
      if (skipCallback) {
        return
      }
      if (data?.games[0]) {
        try {
          const registration = await joinGame({
            variables: {
              gameId: data.games[0].id,
              clientUuid: clientId,
            },
            context: {
              headers: {
                "X-Hasura-Role": "anonymous",
              },
            },
          })
          if (registration.data?.joinGame) {
            await currentAuth.setJwtToken(registration.data.joinGame.jwt_token)
            setSkipCallback(true)
          }
        } catch (err) {
          console.log("err")
          // cannot join game
          // TODO: add rn version
          // setRedirectRoute(
          //   generatePath(routes.game.pending, {
          //     joinCode: props.joinCode.toLocaleUpperCase(),
          //   })
          // )
        }
      } else {
        // TODO: add rn version
        flashMessage("simple info", "info")
        // cannot find game
        // notification.send(
        //   t(
        //     "invalidJoinCode",
        //     "Cannot find game {{ joinCode }}. Double check the url!",
        //     { joinCode: props.joinCode.toLocaleUpperCase() }
        //   ) + " 👀"
        // )
        // setRedirectRoute(routes.root)
      }
    },
    onError: (_) => {
      // TODO: add rn version
      console.error("onError")
      // setRedirectRoute(routes.root)
    },
  })

  const [signIn, { loading: loadingSignIn }] = useLazyQuery(
    CurrentPlayerDocument,
    {
      variables: {
        joinCode: props.joinCode.toLocaleUpperCase(),
        clientUuid: clientId,
      },
      onCompleted: async (data) => {
        if (data?.players[0]) {
          setCurrentPlayer(data?.players[0])
        } else {
          await currentAuth.setJwtToken(null)
        }
      },
      onError: (_) => {
        currentAuth.setJwtToken(null)
      },
    }
  )

  // get client uuid
  useEffect(() => {
    async function getMyClientUuid() {
      const id = await clientUuid()
      setClientId(id)
    }

    getMyClientUuid()
  }, [])

  // sign in
  useEffect(() => {
    if (clientId && currentAuth.jwtToken) {
      signIn()
    }
  }, [clientId, currentAuth.jwtToken])

  // sign up
  useEffect(() => {
    if (clientId && !loadingSignIn && !currentPlayer) {
      signUp({
        context: {
          headers: {
            "X-Hasura-Role": "anonymous",
          },
        },
      })
    }
  }, [clientId, loadingSignIn, currentPlayer])

  // if (redirectRoute) {
  // TODO: add rn version
  // return <Redirect to={redirectRoute}></Redirect>
  // }

  return currentPlayer?.game?.host?.id ? (
    <CurrentPlayerContext.Provider
      value={{
        ...currentPlayer,
        role:
          currentPlayer.id === currentPlayer.game.host.id
            ? PlayerRole.Host
            : PlayerRole.Participant,
        serverTimeOffset,
      }}
    >
      {props.children}
    </CurrentPlayerContext.Provider>
  ) : null
}
