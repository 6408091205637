import React from "react"
import { View, StyleSheet, Dimensions } from "react-native"
import { PlayerChip } from "@common/components/PlayerChip"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import { CurrentGameSubscription } from "@gql"
import { nextPlayerForNextTeam } from "@common/utils/turn"
import { Trans, useTranslation } from "react-i18next"
import { Text, SafeAreaView } from "@common/components"
import {
  grid,
  vertGrid,
  PlayStateColors,
  PlayStateColorsType,
  theme,
} from "@common/theme"
import { TeamColor } from "@common/utils/team"

const { width } = Dimensions.get("window")

type Player = CurrentGameSubscription["games"][0]["players"][0]
type Props = {
  activePlayer: Player
  activeTurn: CurrentGameSubscription["games"][0]["turns"][0]
}

export type PlayState = keyof PlayStateColorsType

interface PlayStateBadgeProps {
  state: PlayState
  title?: string
  description?: string
}

const PlayStateBadge: React.FC<PlayStateBadgeProps> = ({
  state,
  title = "",
  description = "",
}) => (
  <View style={styles.badgeContainer}>
    <View style={[styles.badge, { backgroundColor: PlayStateColors[state] }]}>
      <Text
        variant="h1"
        fontVariant="LuckiestGuy"
        style={[styles.helperText, { color: theme.color.text_secondary }]}
        numberOfLines={1}
        adjustsFontSizeToFit={true}
      >
        {title}
      </Text>
      <Text
        variant="h3"
        fontVariant="ComicNeueBold"
        style={[styles.helperText, { color: theme.color.text_secondary }]}
      >
        {description}
      </Text>
    </View>
  </View>
)

interface UsernameTeamStatusProps {
  player: Player
  title?: string
  footer?: React.ReactElement | null
}

const UsernameTeamStatus: React.FC<UsernameTeamStatusProps> = ({
  player,
  title = "",
  footer,
}) => (
  <>
    <Text fontVariant="Bold" style={styles.helperText}>
      {title}
    </Text>
    <Text
      variant="h3"
      fontVariant="LuckiestGuy"
      style={[
        styles.helperText,
        player.team?.team_color
          ? { color: TeamColor[player.team?.team_color] }
          : {},
      ]}
    >
      {player.team?.name}
    </Text>
    <PlayerChip
      username={player?.username ? player.username : ""}
      teamColor={player.team?.team_color}
    ></PlayerChip>
    {footer}
  </>
)

export function YourTeamTurnContent(props: Props) {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const nextActivePlayer = nextPlayerForNextTeam(
    props.activePlayer,
    currentGame.turns,
    currentGame.players
  )

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.activeTurn.started_at !== null ? (
        <Trans t={t} i18nKey="play.yourTeam.context.active">
          <PlayStateBadge state="go" title={"Guess!"} />
          <View style={styles.bubble}>
            <UsernameTeamStatus
              player={props.activePlayer}
              title={"Currently up: "}
            />
          </View>
        </Trans>
      ) : (
        <Trans t={t} i18nKey="play.yourTeam.context.inactive">
          <PlayStateBadge
            state="wait"
            title={"Get Ready!"}
            description={`You're guessing ${props.activePlayer.username}'s clues!`}
          />
          <View style={styles.bubble}>
            <UsernameTeamStatus
              player={props.activePlayer}
              title={"About to start: "}
            />
          </View>
        </Trans>
      )}

      <View style={styles.bubble}>
        <Trans t={t} i18nKey="play.yourTeam.context.nextTurn">
          <UsernameTeamStatus player={nextActivePlayer} title={"Next up:"} />
        </Trans>
      </View>
    </View>
  )
}

export function OtherTeamContent(props: Props) {
  const { t } = useTranslation()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const nextActivePlayer = nextPlayerForNextTeam(
    props.activePlayer,
    currentGame.turns,
    currentGame.players
  )

  return (
    <SafeAreaView>
      <View style={{ flexDirection: "column" }}>
        {props.activeTurn.started_at !== null ? (
          <Trans t={t} i18nKey="play.otherTeam.context.active">
            <PlayStateBadge
              state="alt"
              title={"Pay attention!"}
              description={
                "You may need to remember these words in future rounds."
              }
            />
            <View style={styles.bubble}>
              <UsernameTeamStatus
                player={props.activePlayer}
                title={"Currently Up:"}
              />
            </View>
          </Trans>
        ) : (
          <Trans t={t} i18nKey="play.otherTeam.context.inactive">
            <PlayStateBadge
              state="stop"
              title={"Relax!"}
              description={`It's ${props.activePlayer.username}'s from the other team's turn!`}
            />
            <View style={styles.bubble}>
              <UsernameTeamStatus
                player={props.activePlayer}
                title={"Current Turn:"}
              />
            </View>
          </Trans>
        )}

        <View style={styles.bubble}>
          <Trans t={t} i18nKey="play.otherTeam.context.nextTurn">
            <UsernameTeamStatus
              player={nextActivePlayer}
              title={"Next up:"}
              footer={
                nextActivePlayer.id === currentPlayer.id ? (
                  <Text variant="footnote" style={styles.helperText}>
                    {"(that's you!)"}
                  </Text>
                ) : null
              }
            />
          </Trans>
        </View>
      </View>
    </SafeAreaView>
  )
}

const newRatio = 3 / 5
const styles = StyleSheet.create({
  bubble: {
    backgroundColor: theme.color.background_primary,
    borderRadius: 10,
    padding: grid(1),
    marginVertical: vertGrid(2),
    alignSelf: "center",
    width: "60%",
  },
  badgeContainer: {
    width: width,
    alignItems: "center",
    paddingVertical: Math.min(
      width * newRatio * Math.tan(15 * (Math.PI / 180)) * newRatio,
      50
    ),
  },
  badge: {
    borderWidth: 3,
    transform: [{ rotate: "-15deg" }],
    width: "60%",
    maxWidth: 400,
    padding: grid(1),
    borderRadius: 10,
    borderColor: theme.color.background_primary,
  },
  helperText: {
    textAlign: "center",
  },
})
