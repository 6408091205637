import React, { createContext } from "react"
import { Fonts } from "../theme/Fonts"
import { theme } from "../theme/theme"

export const Context = createContext({})

// wrapper for all components for all Storybook stories
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Fonts>
      <Context.Provider value={theme}>{children}</Context.Provider>
    </Fonts>
  )
}
