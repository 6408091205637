import { Winner } from "./types"
import { positions } from "./constants"
import { MotiView } from "moti"
import { Text, View } from "react-native"
import { PlayerChip } from "@common/components/PlayerChip"
import { TeamColor } from "@common/utils/team"
import { TeamColorsEnum } from "@gql"
import { theme } from "@common/theme"

interface Props {
  readonly podium: Winner[]
  readonly winner: Winner
  readonly highestScore: number
  readonly index: number
}

export function PodiumStep({ podium, winner, highestScore, index }: Props) {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <MotiView
        // custom={index}
        from={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1000 * (podium.length - winner.place + 2),
          // default settings for all style values
          type: "timing",
          duration: 1000,
        }}
      >
        <PlayerChip
          teamColor={winner.team?.team_color}
          textColor={theme.color.text_secondary}
        >
          {winner.username}
        </PlayerChip>
      </MotiView>
      <MotiView
        from={{
          height: 0,
          borderWidth: 0,
        }}
        animate={{
          height: 200 * (winner.score / highestScore),
          borderWidth: 1,
        }}
        transition={{
          type: "timing",
          duration: 800,
          delay: 1000 * (podium.length - winner.place + 1),
        }}
        style={{
          overflow: "hidden",
          backgroundColor: winner.team
            ? TeamColor[winner.team.team_color as TeamColorsEnum]
            : "gold",
          marginBottom: -1,
          justifyContent: "flex-end",
          opacity: 0.1 + (podium.length - winner.place) / podium.length,
          width: "80%",
          borderColor: "white",
          borderBottomColor: "transparent",
        }}
      >
        <Text style={{ color: "#fff", fontWeight: "500", textAlign: "center" }}>
          {positions[winner.place]}
        </Text>
      </MotiView>
    </View>
  )
}
