import React from "react"
import { ColorValue, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

type FilledStatusProps = {
  color: ColorValue
  header?: boolean
}

export const FilledStatus: React.FC<FilledStatusProps> = ({
  color,
  header = true,
  ...props
}) => {
  const insets = useSafeAreaInsets()

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: color,
        height: header ? insets.top : insets.bottom,
      }}
    ></View>
  )
}
