export const adjectives = ["abandoned",
"able",
"absolute",
"adorable",
"adventurous",
"academic",
"acceptable",
"acclaimed",
"accomplished",
"accurate",
"aching",
"acidic",
"acrobatic",
"active",
"actual",
"adept",
"admirable",
"admired",
"adolescent",
"adorable",
"adored",
"advanced",
"afraid",
"affectionate",
"aged",
"aggravating",
"aggressive",
"agile",
"agitated",
"agonizing",
"agreeable",
"ajar",
"alarmed",
"alarming",
"alert",
"alienated",
"alive",
"all",
"altruistic",
"amazing",
"ambitious",
"ample",
"amused",
"amusing",
"anchored",
"ancient",
"angelic",
"angry",
"anguished",
"animated",
"annual",
"another",
"antique",
"anxious",
"any",
"apprehensive",
"appropriate",
"apt",
"arctic",
"arid",
"aromatic",
"artistic",
"ashamed",
"assured",
"astonishing",
"athletic",
"attached",
"attentive",
"attractive",
"austere",
"authentic",
"authorized",
"automatic",
"avaricious",
"average",
"aware",
"awesome",
"awful",
"awkward",
"babyish",
"bad",
"back",
"baggy",
"bare",
"barren",
"basic",
"beautiful",
"belated",
"beloved",
"beneficial",
"better",
"best",
"bewitched",
"big",
"big-hearted",
"biodegradable",
"bite-sized",
"bitter",
"black",
"black-and-white",
"bland",
"blank",
"blaring",
"bleak",
"blind",
"blissful",
"blond",
"blue",
"blushing",
"bogus",
"boiling",
"bold",
"bony",
"boring",
"bossy",
"both",
"bouncy",
"bountiful",
"bowed",
"brave",
"breakable",
"brief",
"bright",
"brilliant",
"brisk",
"broken",
"bronze",
"brown",
"bruised",
"bubbly",
"bulky",
"bumpy",
"buoyant",
"burdensome",
"burly",
"bustling",
"busy",
"buttery",
"buzzing",
"calculating",
"calm",
"candid",
"canine",
"capital",
"carefree",
"careful",
"careless",
"caring",
"cautious",
"cavernous",
"celebrated",
"charming",
"cheap",
"cheerful",
"cheery",
"chief",
"chilly",
"chubby",
"circular",
"classic",
"clean",
"clear",
"clear-cut",
"clever",
"close",
"closed",
"cloudy",
"clueless",
"clumsy",
"cluttered",
"coarse",
"cold",
"colorful",
"colorless",
"colossal",
"comfortable",
"common",
"compassionate",
"competent",
"complete",
"complex",
"complicated",
"composed",
"concerned",
"concrete",
"confused",
"conscious",
"considerate",
"constant",
"content",
"conventional",
"cooked",
"cool",
"cooperative",
"coordinated",
"corny",
"corrupt",
"costly",
"courageous",
"courteous",
"crafty",
"crazy",
"creamy",
"creative",
"creepy",
"criminal",
"crisp",
"critical",
"crooked",
"crowded",
"cruel",
"crushing",
"cuddly",
"cultivated",
"cultured",
"cumbersome",
"curly",
"curvy",
"cute",
"cylindrical",
"damaged",
"damp",
"dangerous",
"dapper",
"daring",
"darling",
"dark",
"dazzling",
"dead",
"deadly",
"deafening",
"dear",
"dearest",
"decent",
"decimal",
"decisive",
"deep",
"defenseless",
"defensive",
"defiant",
"deficient",
"definite",
"definitive",
"delayed",
"delectable",
"delicious",
"delightful",
"delirious",
"demanding",
"dense",
"dental",
"dependable",
"dependent",
"descriptive",
"deserted",
"detailed",
"determined",
"devoted",
"different",
"difficult",
"digital",
"diligent",
"dim",
"dimpled",
"dimwitted",
"direct",
"disastrous",
"discrete",
"disfigured",
"disgusting",
"disloyal",
"dismal",
"distant",
"downright",
"dreary",
"dirty",
"disguised",
"dishonest",
"dismal",
"distant",
"distinct",
"distorted",
"dizzy",
"dopey",
"doting",
"double",
"downright",
"drab",
"drafty",
"dramatic",
"dreary",
"droopy",
"dry",
"dual",
"dull",
"dutiful",
"each",
"eager",
"earnest",
"early",
"easy",
"easy-going",
"ecstatic",
"edible",
"educated",
"elaborate",
"elastic",
"elated",
"elderly",
"electric",
"elegant",
"elementary",
"elliptical",
"embarrassed",
"embellished",
"eminent",
"emotional",
"empty",
"enchanted",
"enchanting",
"energetic",
"enlightened",
"enormous",
"enraged",
"entire",
"envious",
"equal",
"equatorial",
"essential",
"esteemed",
"ethical",
"euphoric",
"even",
"evergreen",
"everlasting",
"every",
"evil",
"exalted",
"excellent",
"exemplary",
"exhausted",
"excitable",
"excited",
"exciting",
"exotic",
"expensive",
"experienced",
"expert",
"extraneous",
"extroverted",
"extra-large",
"extra-small",
"fabulous",
"failing",
"faint",
"fair",
"faithful",
"fake",
"false",
"familiar",
"famous",
"fancy",
"fantastic",
"far",
"faraway",
"far-flung",
"far-off",
"fast",
"fat",
"fatal",
"fatherly",
"favorable",
"favorite",
"fearful",
"fearless",
"feisty",
"feline",
"female",
"feminine",
"few",
"fickle",
"filthy",
"fine",
"finished",
"firm",
"first",
"firsthand",
"fitting",
"fixed",
"flaky",
"flamboyant",
"flashy",
"flat",
"flawed",
"flawless",
"flickering",
"flimsy",
"flippant",
"flowery",
"fluffy",
"fluid",
"flustered",
"focused",
"fond",
"foolhardy",
"foolish",
"forceful",
"forked",
"formal",
"forsaken",
"forthright",
"fortunate",
"fragrant",
"frail",
"frank",
"frayed",
"free",
"French",
"fresh",
"frequent",
"friendly",
"frightened",
"frightening",
"frigid",
"frilly",
"frizzy",
"frivolous",
"front",
"frosty",
"frozen",
"frugal",
"fruitful",
"full",
"fumbling",
"functional",
"funny",
"fussy",
"fuzzy",
"gargantuan",
"gaseous",
"general",
"generous",
"gentle",
"genuine",
"giant",
"giddy",
"gigantic",
"gifted",
"giving",
"glamorous",
"glaring",
"glass",
"gleaming",
"gleeful",
"glistening",
"glittering",
"gloomy",
"glorious",
"glossy",
"glum",
"golden",
"good",
"good-natured",
"gorgeous",
"graceful",
"gracious",
"grand",
"grandiose",
"granular",
"grateful",
"grave",
"gray",
"great",
"greedy",
"green",
"gregarious",
"grim",
"grimy",
"gripping",
"grizzled",
"gross",
"grotesque",
"grouchy",
"grounded",
"growing",
"growling",
"grown",
"grubby",
"gruesome",
"grumpy",
"guilty",
"gullible",
"gummy",
"hairy",
"half",
"handmade",
"handsome",
"handy",
"happy",
"happy-go-lucky",
"hard",
"hard-to-find",
"harmful",
"harmless",
"harmonious",
"harsh",
"hasty",
"hateful",
"haunting",
"healthy",
"heartfelt",
"hearty",
"heavenly",
"heavy",
"hefty",
"helpful",
"helpless",
"hidden",
"hideous",
"high",
"high-level",
"hilarious",
"hoarse",
"hollow",
"homely",
"honest",
"honorable",
"honored",
"hopeful",
"horrible",
"hospitable",
"hot",
"huge",
"humble",
"humiliating",
"humming",
"humongous",
"hungry",
"hurtful",
"husky",
"icky",
"icy",
"ideal",
"idealistic",
"identical",
"idle",
"idiotic",
"idolized",
"ignorant",
"ill",
"illegal",
"ill-fated",
"ill-informed",
"illiterate",
"illustrious",
"imaginary",
"imaginative",
"immaculate",
"immaterial",
"immediate",
"immense",
"impassioned",
"impeccable",
"impartial",
"imperfect",
"imperturbable",
"impish",
"impolite",
"important",
"impossible",
"impractical",
"impressionable",
"impressive",
"improbable",
"impure",
"inborn",
"incomparable",
"incompatible",
"incomplete",
"inconsequential",
"incredible",
"indelible",
"inexperienced",
"indolent",
"infamous",
"infantile",
"infatuated",
"inferior",
"infinite",
"informal",
"innocent",
"insecure",
"insidious",
"insignificant",
"insistent",
"instructive",
"insubstantial",
"intelligent",
"intent",
"intentional",
"interesting",
"internal",
"international",
"intrepid",
"ironclad",
"irresponsible",
"irritating",
"itchy",
"jaded",
"jagged",
"jam-packed",
"jaunty",
"jealous",
"jittery",
"joint",
"jolly",
"jovial",
"joyful",
"joyous",
"jubilant",
"judicious",
"juicy",
"jumbo",
"junior",
"jumpy",
"juvenile",
"kaleidoscopic",
"keen",
"key",
"kind",
"kindhearted",
"kindly",
"klutzy",
"knobby",
"knotty",
"knowledgeable",
"knowing",
"known",
"kooky",
"kosher",
"lame",
"lanky",
"large",
"last",
"lasting",
"late",
"lavish",
"lawful",
"lazy",
"leading",
"lean",
"leafy",
"left",
"legal",
"legitimate",
"light",
"lighthearted",
"likable",
"likely",
"limited",
"limp",
"limping",
"linear",
"lined",
"liquid",
"little",
"live",
"lively",
"livid",
"loathsome",
"lone",
"lonely",
"long",
"long-term",
"loose",
"lopsided",
"lost",
"loud",
"lovable",
"lovely",
"loving",
"low",
"loyal",
"lucky",
"lumbering",
"luminous",
"lumpy",
"lustrous",
"luxurious",
"mad",
"made-up",
"magnificent",
"majestic",
"major",
"male",
"mammoth",
"married",
"marvelous",
"masculine",
"massive",
"mature",
"meager",
"mealy",
"mean",
"measly",
"meaty",
"medical",
"mediocre",
"medium",
"meek",
"mellow",
"melodic",
"memorable",
"menacing",
"merry",
"messy",
"metallic",
"mild",
"milky",
"mindless",
"miniature",
"minor",
"minty",
"miserable",
"miserly",
"misguided",
"misty",
"mixed",
"modern",
"modest",
"moist",
"monstrous",
"monthly",
"monumental",
"moral",
"mortified",
"motherly",
"motionless",
"mountainous",
"muddy",
"muffled",
"multicolored",
"mundane",
"murky",
"mushy",
"musty",
"muted",
"mysterious",
"naive",
"narrow",
"nasty",
"natural",
"naughty",
"nautical",
"near",
"neat",
"necessary",
"needy",
"negative",
"neglected",
"negligible",
"neighboring",
"nervous",
"new",
"next",
"nice",
"nifty",
"nimble",
"nippy",
"nocturnal",
"noisy",
"nonstop",
"normal",
"notable",
"noted",
"noteworthy",
"novel",
"noxious",
"numb",
"nutritious",
"nutty",
"obedient",
"obese",
"oblong",
"oily",
"oblong",
"obvious",
"occasional",
"odd",
"oddball",
"offbeat",
"offensive",
"official",
"old",
"old-fashioned",
"only",
"open",
"optimal",
"optimistic",
"opulent",
"orange",
"orderly",
"organic",
"ornate",
"ornery",
"ordinary",
"original",
"other",
"our",
"outlying",
"outgoing",
"outlandish",
"outrageous",
"outstanding",
"oval",
"overcooked",
"overdue",
"overjoyed",
"overlooked",
"palatable",
"pale",
"paltry",
"parallel",
"parched",
"partial",
"passionate",
"past",
"pastel",
"peaceful",
"peppery",
"perfect",
"perfumed",
"periodic",
"perky",
"personal",
"pertinent",
"pesky",
"pessimistic",
"petty",
"phony",
"physical",
"piercing",
"pink",
"pitiful",
"plain",
"plaintive",
"plastic",
"playful",
"pleasant",
"pleased",
"pleasing",
"plump",
"plush",
"polished",
"polite",
"political",
"pointed",
"pointless",
"poised",
"poor",
"popular",
"portly",
"posh",
"positive",
"possible",
"potable",
"powerful",
"powerless",
"practical",
"precious",
"present",
"prestigious",
"pretty",
"precious",
"previous",
"pricey",
"prickly",
"primary",
"prime",
"pristine",
"private",
"prize",
"probable",
"productive",
"profitable",
"profuse",
"proper",
"proud",
"prudent",
"punctual",
"pungent",
"puny",
"pure",
"purple",
"pushy",
"putrid",
"puzzled",
"puzzling",
"quaint",
"qualified",
"quarrelsome",
"quarterly",
"queasy",
"querulous",
"questionable",
"quick",
"quick-witted",
"quiet",
"quintessential",
"quirky",
"quixotic",
"quizzical",
"radiant",
"ragged",
"rapid",
"rare",
"rash",
"raw",
"recent",
"reckless",
"rectangular",
"ready",
"real",
"realistic",
"reasonable",
"red",
"reflecting",
"regal",
"regular",
"reliable",
"relieved",
"remarkable",
"remorseful",
"remote",
"repentant",
"required",
"respectful",
"responsible",
"repulsive",
"revolving",
"rewarding",
"rich",
"rigid",
"right",
"ringed",
"ripe",
"roasted",
"robust",
"rosy",
"rotating",
"rotten",
"rough",
"round",
"rowdy",
"royal",
"rubbery",
"rundown",
"ruddy",
"rude",
"runny",
"rural",
"rusty",
"sad",
"safe",
"salty",
"same",
"sandy",
"sane",
"sarcastic",
"sardonic",
"satisfied",
"scaly",
"scarce",
"scared",
"scary",
"scented",
"scholarly",
"scientific",
"scornful",
"scratchy",
"scrawny",
"second",
"secondary",
"second-hand",
"secret",
"self-assured",
"self-reliant",
"selfish",
"sentimental",
"separate",
"serene",
"serious",
"serpentine",
"several",
"severe",
"shabby",
"shadowy",
"shady",
"shallow",
"shameful",
"shameless",
"sharp",
"shimmering",
"shiny",
"shocked",
"shocking",
"shoddy",
"short",
"short-term",
"showy",
"shrill",
"shy",
"sick",
"silent",
"silky",
"silly",
"silver",
"similar",
"simple",
"simplistic",
"sinful",
"single",
"sizzling",
"skeletal",
"skinny",
"sleepy",
"slight",
"slim",
"slimy",
"slippery",
"slow",
"slushy",
"small",
"smart",
"smoggy",
"smooth",
"smug",
"snappy",
"snarling",
"sneaky",
"sniveling",
"snoopy",
"sociable",
"soft",
"soggy",
"solid",
"somber",
"some",
"spherical",
"sophisticated",
"sore",
"sorrowful",
"soulful",
"soupy",
"sour",
"Spanish",
"sparkling",
"sparse",
"specific",
"spectacular",
"speedy",
"spicy",
"spiffy",
"spirited",
"spiteful",
"splendid",
"spotless",
"spotted",
"spry",
"square",
"squeaky",
"squiggly",
"stable",
"staid",
"stained",
"stale",
"standard",
"starchy",
"stark",
"starry",
"steep",
"sticky",
"stiff",
"stimulating",
"stingy",
"stormy",
"straight",
"strange",
"steel",
"strict",
"strident",
"striking",
"striped",
"strong",
"studious",
"stunning",
"stupendous",
"stupid",
"sturdy",
"stylish",
"subdued",
"submissive",
"substantial",
"subtle",
"suburban",
"sudden",
"sugary",
"sunny",
"super",
"superb",
"superficial",
"superior",
"supportive",
"sure-footed",
"surprised",
"suspicious",
"svelte",
"sweaty",
"sweet",
"sweltering",
"swift",
"sympathetic",
"tall",
"talkative",
"tame",
"tan",
"tangible",
"tart",
"tasty",
"tattered",
"taut",
"tedious",
"teeming",
"tempting",
"tender",
"tense",
"tepid",
"terrible",
"terrific",
"testy",
"thankful",
"that",
"these",
"thick",
"thin",
"third",
"thirsty",
"this",
"thorough",
"thorny",
"those",
"thoughtful",
"threadbare",
"thrifty",
"thunderous",
"tidy",
"tight",
"timely",
"tinted",
"tiny",
"tired",
"torn",
"total",
"tough",
"traumatic",
"treasured",
"tremendous",
"tragic",
"trained",
"tremendous",
"triangular",
"tricky",
"trifling",
"trim",
"trivial",
"troubled",
"true",
"trusting",
"trustworthy",
"trusty",
"truthful",
"tubby",
"turbulent",
"twin",
"ugly",
"ultimate",
"unacceptable",
"unaware",
"uncomfortable",
"uncommon",
"unconscious",
"understated",
"unequaled",
"uneven",
"unfinished",
"unfit",
"unfolded",
"unfortunate",
"unhappy",
"unhealthy",
"uniform",
"unimportant",
"unique",
"united",
"unkempt",
"unknown",
"unlawful",
"unlined",
"unlucky",
"unnatural",
"unpleasant",
"unrealistic",
"unripe",
"unruly",
"unselfish",
"unsightly",
"unsteady",
"unsung",
"untidy",
"untimely",
"untried",
"untrue",
"unused",
"unusual",
"unwelcome",
"unwieldy",
"unwilling",
"unwitting",
"unwritten",
"upbeat",
"upright",
"upset",
"urban",
"usable",
"used",
"useful",
"useless",
"utilized",
"utter",
"vacant",
"vague",
"vain",
"valid",
"valuable",
"vapid",
"variable",
"vast",
"velvety",
"venerated",
"vengeful",
"verifiable",
"vibrant",
"vicious",
"victorious",
"vigilant",
"vigorous",
"villainous",
"violet",
"violent",
"virtual",
"virtuous",
"visible",
"vital",
"vivacious",
"vivid",
"voluminous",
"wan",
"warlike",
"warm",
"warmhearted",
"warped",
"wary",
"wasteful",
"watchful",
"waterlogged",
"watery",
"wavy",
"wealthy",
"weak",
"weary",
"webbed",
"wee",
"weekly",
"weepy",
"weighty",
"weird",
"welcome",
"well-documented",
"well-groomed",
"well-informed",
"well-lit",
"well-made",
"well-off",
"well-to-do",
"well-worn",
"wet",
"which",
"whimsical",
"whirlwind",
"whispered",
"white",
"whole",
"whopping",
"wicked",
"wide",
"wide-eyed",
"wiggly",
"wild",
"willing",
"wilted",
"winding",
"windy",
"winged",
"wiry",
"wise",
"witty",
"wobbly",
"woeful",
"wonderful",
"wooden",
"woozy",
"wordy",
"worldly",
"worn",
"worried",
"worrisome",
"worse",
"worst",
"worthless",
"worthwhile",
"worthy",
"wrathful",
"wretched",
"writhing",
"wrong",
"wry",
"yawning",
"yearly",
"yellow",
"yellowish",
"young",
"youthful",
"yummy",
"zany",
"zealous",
"zesty",
"zigzag",
]

export const pluralNouns = ["aardvarks",
"airs",
"airplanes",
"airports",
"alarms",
"alligators",
"alphabets",
"ambulances",
"animals",
"ankles",
"armies",
"answers",
"ants",
"antelopes",
"apples",
"arms",
"armchairs",
"arrows",
"asparaguses",
"babies",
"backs",
"backbones",
"bacons",
"badges",
"badgers",
"bags",
"bagpipes",
"baits",
"bakeries",
"balls",
"balloons",
"bamboos",
"bananas",
"bands",
"bandanas",
"bangles",
"banjos",
"banks",
"baseballs",
"baskets",
"basketballs",
"bats",
"baths",
"bathrooms",
"bathtubs",
"batteries",
"battles",
"bays",
"beaches",
"beads",
"beans",
"bears",
"beards",
"beasts",
"beats",
"beauties",
"beavers",
"beds",
"bedrooms",
"bees",
"beefs",
"beetles",
"bells",
"belts",
"benches",
"berets",
"berries",
"bicycles",
"bikes",
"birds",
"birthdays",
"bites",
"blacks",
"blades",
"blankets",
"blocks",
"bloods",
"blouses",
"blows",
"boards",
"boats",
"bobcats",
"bodies",
"bolts",
"bones",
"bonsais",
"books",
"bookcases",
"booklets",
"boots",
"borders",
"bottles",
"bottoms",
"boundaries",
"bows",
"bowlings",
"boxes",
"boys",
"brains",
"brakes",
"branches",
"brasses",
"breads",
"breaks",
"breakfasts",
"breaths",
"bricks",
"bridges",
"broccolis",
"brochures",
"brothers",
"brushes",
"bubbles",
"buckets",
"buildings",
"bulbs",
"bulls",
"bulldozers",
"bumpers",
"buns",
"buss",
"bushes",
"butters",
"buttons",
"cabbages",
"cacti",
"cafes",
"cakes",
"calculators",
"calendars",
"calves",
"calls",
"camels",
"cameras",
"camps",
"candles",
"canoes",
"canvass",
"caps",
"captains",
"cars",
"cards",
"cardboards",
"cardigans",
"carpenters",
"carrots",
"cartoons",
"cats",
"caterpillars",
"cathedrals",
"castles",
"cauliflowers",
"caves",
"CDs",
"ceilings",
"celeries",
"cellos",
"cements",
"cemeteries",
"cereals",
"chains",
"chairs",
"chalks",
"channels",
"characters",
"cheeks",
"cheeses",
"cheetahs",
"cherries",
"chests",
"chests",
"chicks",
"chickens",
"children",
"chimpanzees",
"chins",
"chives",
"chocolates",
"churches",
"cicadas",
"cinemas",
"circles",
"cities",
"clams",
"clarinets",
"clicks",
"clocks",
"closes",
"closets",
"cloths",
"clouds ",
"coaches",
"coals",
"coasts",
"coats",
"cobwebs",
"cockroaches",
"cocoas",
"coffees",
"coils",
"coins",
"cokes",
"colds",
"collars",
"colleges",
"colts",
"combs",
"comics",
"commas",
"computers",
"copies",
"corns",
"costs",
"cottons",
"couches",
"cougars",
"countries",
"courses",
"courts",
"cousins",
"cows",
"crabs",
"cracks",
"crackers",
"crates",
"crayfishes",
"crayons",
"creams",
"creeks",
"crickets",
"crocodiles",
"crops",
"crows",
"crowds",
"crowns",
"cucumbers",
"cups",
"cupboards",
"curtains",
"curves",
"cushions",
"cyclones",
"dads",
"daffodils",
"daisies",
"dances",
"daughters",
"days",
"deeds",
"denims",
"dentists",
"deserts",
"desks",
"desserts",
"detectives",
"dews",
"diamonds",
"dictionaries",
"dinghies",
"dinosaurs",
"diets",
"dishes",
"dogs",
"dolls",
"dollars",
"doors",
"dragons",
"dragonflies",
"drains",
"drawers",
"dreams",
"dresses",
"dressers",
"drills",
"drinks",
"drums",
"dryers",
"ducks",
"ducklings",
"dungeons",
"dusts",
"eagles",
"ears",
"earths",
"earthquakes",
"eels",
"eggs",
"eggplants",
"elbows",
"elephants",
"energies",
"engines",
"equipment",
"evenings",
"eyes",
"eyebrows",
"faces",
"facts",
"factories",
"fairie",
"families",
"fans",
"fangs",
"farms",
"fears",
"feasts",
"feathers",
"feet",
"ferryboats",
"fields",
"fights",
"fingers",
"fires",
"fireplaces",
"fishes",
"flags",
"flames",
"floods",
"floors",
"flowers",
"flutes",
"flies",
"foams",
"fogs",
"foods",
"foots",
"footballs",
"foreheads",
"forests",
"forks",
"fountains",
"foxes",
"frames",
"freckles",
"freezers",
"frogs",
"frosts",
"fruits",
"fuels",
"furs",
"furniture",
"games",
"garages",
"gardens",
"garlics",
"gases",
"gates",
"gears",
"ghosts",
"giraffes",
"girls",
"glasses",
"gloves",
"glues",
"goals",
"goats",
"gold",
"goldfishes",
"golfs",
"gorillas",
"governments",
"grapes",
"grasses",
"grasshoppers",
"greases",
"grills",
"groups",
"guitars",
"gums",
"gyms",
"gymnasts",
"hails",
"hairs",
"haircuts",
"halls",
"hamburgers",
"hammers",
"hamsters",
"hands",
"handballs",
"handles",
"hardware",
"harmonicas",
"harmonies",
"hats",
"hates",
"hawks",
"heads",
"headlights",
"health",
"hearts",
"heats",
"heavens",
"hedges",
"heights",
"helicopters",
"helmets",
"helps",
"hens",
"hills",
"hips",
"hippopotam",
"histories",
"holes",
"holidays",
"homes",
"honeys",
"hoods",
"hooks",
"horns",
"horses",
"hoses",
"hospitals",
"hours",
"houses",
"hurricanes",
"hyenas",
"ices",
"icicles",
"ideas",
"inks",
"insects",
"instruments",
"Internets",
"inventions",
"irons",
"islands",
"jackets",
"jaguars",
"jails",
"jams",
"jars",
"jaws",
"jeans",
"jeeps",
"jellies",
"jellyfishes",
"jets",
"jewels",
"jokes",
"journeys",
"judges",
"judos",
"juices",
"jumps",
"jumpers",
"kangaroos",
"karats",
"kayaks",
"kettles",
"keys",
"keyboards",
"kicks",
"kisses",
"kitchens",
"kites",
"kittens",
"knees",
"knifes",
"knights",
"knots",
"laces",
"ladybugs",
"lakes",
"lambs",
"lamps",
"lands",
"lasagnas",
"laughs ",
"leafs",
"leathers",
"leeks",
"legs",
"lemonades",
"leopards",
"letters",
"lettuces",
"libraries",
"lifts",
"lights ",
"lilies",
"lines",
"lions",
"lips",
"lipsticks",
"liquids",
"lists",
"litters",
"lizards",
"loafs",
"lobsters",
"locks",
"lockets",
"locusts",
"looks",
"lotions",
"loves",
"lunches",
"lynxes",
"macaronies",
"machines",
"magazines",
"magics",
"magicians",
"mails",
"mailboxes",
"mailmen",
"makeups",
"maps",
"marbles",
"marks",
"markets",
"mascaras",
"masks",
"matches",
"meals",
"meats",
"mechanics",
"medicines",
"memories",
"men",
"menus",
"messages",
"metals",
"mice",
"middles",
"milks",
"milkshakes",
"mints",
"minutes",
"mirrors",
"mists",
"mistakes",
"mittens",
"Mondays",
"moneys",
"monkeys",
"months",
"moons",
"mornings",
"mosquitos",
"motorboats",
"motorcycles",
"mountains",
"mouses",
"moustaches",
"mouths",
"musics",
"mustards",
"nails",
"names",
"napkins",
"necks",
"needles",
"nests",
"nets",
"news",
"nights",
"noises",
"noodles",
"noses",
"notes",
"notebooks",
"numbers",
"nuts",
"oaks",
"oatmeals",
"oceans",
"octopuses",
"offices",
"oils",
"olives",
"onions",
"oranges",
"orchestras",
"ostriches",
"otters",
"ovens",
"owls",
"oxen",
"oxygens",
"oysters",
"packets",
"pages",
"pails",
"pains",
"paints",
"pairs",
"pajamas",
"pamphlets",
"pans",
"pancakes",
"pandas",
"pansies",
"panthers",
"pants",
"papers",
"parcels",
"parents",
"parks",
"parrots",
"parties",
"pastas",
"pastes",
"pastries",
"patches",
"paths",
"peas",
"peaches",
"peanuts",
"pears",
"pedestrians",
"pelicans",
"pens",
"pencils",
"peppers",
"perfumes",
"persons",
"pests",
"pets",
"phones",
"pianos",
"pickles",
"pictures",
"pies",
"pigs",
"pigeons",
"pillows",
"pilots",
"pimples",
"pins",
"pipes",
"pizzas",
"planes",
"plants",
"plantations",
"plastics",
"plates",
"playgrounds",
"plots",
"pockets",
"poisons",
"polices",
"policemen",
"pollutions",
"ponds",
"popcorns",
"poppies",
"porcupines",
"postages",
"postboxes",
"pots",
"potatoes",
"poultries",
"powders",
"powers",
"prices",
"printers",
"prisons",
"pumpkins",
"puppies",
"pyramids",
"queens",
"questions",
"quick sands",
"quills",
"quilts",
"rabbits",
"radios",
"radishes",
"rafts",
"rails",
"railways",
"rains",
"rainbows",
"raincoats",
"rainstorms",
"rakes",
"rats",
"raviolis",
"rays",
"recorders",
"rectangles",
"refrigerators",
"reindeers",
"relativess",
"restaurants",
"rewards",
"rhinoceroses",
"races",
"riddles",
"rings",
"rivers",
"roads",
"roasts",
"rocks",
"rolls",
"roofs",
"rooms",
"roosters",
"roses",
"rowboats",
"rubbers",
"sacks",
"sails",
"sailboats",
"sailors",
"salads",
"salmons",
"salts",
"sands",
"sandwiches",
"sardines",
"sauces",
"sausages",
"saws",
"saxophones",
"scarecrows",
"scarfs",
"schools",
"scissors",
"scooters",
"scorpions",
"screws",
"screwdrivers",
"seas",
"seagulls",
"seals",
"seaplanes",
"seashores",
"seasons",
"seats",
"seconds",
"seeds",
"sentences",
"servants",
"shades",
"shadows",
"shallots",
"shampoos",
"sharks",
"shears",
"sheep",
"sheets",
"shelves",
"shells",
"shields",
"ships",
"shirts",
"shoes",
"shoemakers",
"shops",
"shorts",
"shoulders",
"shovels",
"shoes",
"sides",
"sidewalks",
"signs",
"signatures",
"silks",
"silvers",
"singers",
"sinks",
"sisters",
"skins",
"skirts",
"skies",
"sleds",
"slippers",
"slopes",
"smokes",
"snails",
"snakes",
"sneezes",
"snows",
"snowflakes",
"snowme",
"soaps",
"soccer balls",
"socks",
"sofas",
"softballs",
"soldiers",
"sons",
"songs",
"sounds",
"soups",
"soybeans",
"spaces",
"spades",
"spaghettis",
"sparks",
"sparrows",
"spears",
"speedboats",
"spiders",
"spikes",
"spinaches",
"sponges",
"spoons",
"spots",
"sprouts",
"spies",
"squares",
"squashes",
"squids",
"squirrels",
"stages",
"staircases",
"stamps",
"stars",
"stations",
"steams",
"steels",
"stems",
"steps",
"stews",
"sticks",
"stitches",
"stingers",
"stomachs",
"stones",
"stools",
"signs",
"stopwatche",
"stores",
"storms",
"stories",
"stoves",
"strangers",
"straws",
"streams",
"strings",
"submarines",
"sugars",
"suits",
"summers",
"suns",
"sunshines",
"sunflowers",
"supermarkets",
"surfboards",
"surnames",
"surprises",
"sushis",
"swallows",
"swamps",
"swans",
"sweaters",
"sweatshirts",
"sweets",
"swings",
"switches",
"swords",
"swordfishes",
"syrups",
"tables",
"tabletops",
"tadpoles",
"tails",
"targets",
"taxes",
"taxis",
"teas",
"teachers",
"teams",
"teeth",
"televisions",
"tents",
"textbooks",
"theaters",
"thistles",
"thoughts",
"threads",
"throats",
"thrones",
"thumbs",
"thunders",
"thunderstorms",
"tickets",
"ties",
"tigers",
"tiles",
"times",
"tires",
"toads",
"toasts",
"toes",
"toilets",
"tomatoes",
"tongues",
"toothbrushes",
"toothpastes",
"tops",
"tornados",
"tortoises",
"towers",
"towns",
"toys",
"tractors",
"traffics",
"trails",
"trains",
"transports",
"trays",
"trees",
"triangles",
"tricks",
"trips",
"trombones",
"troubles",
"trousers",
"trucks",
"trumpets",
"trunks",
"tubs",
"tubas",
"tugboats",
"tulips",
"tunas",
"tunes",
"Turkeys",
"turnips",
"turtles",
"TVs",
"twigs",
"twilights",
"twines",
"umbrellas",
"valleys",
"vans",
"vases",
"vegetables",
"veils",
"veins",
"vessels",
"vests",
"violins",
"volcanoes",
"volleyballs",
"vultures",
"walls",
"wallabies",
"walruses",
"washers",
"wasps",
"wastes",
"watches",
"watchmakers",
"waters",
"waterfalls",
"waves",
"waxes",
"weapons",
"weasels",
"weathers",
"wedges",
"whales",
"wheels",
"whips",
"whistles",
"wildernesses",
"willows",
"winds",
"windows",
"windscreens",
"wings",
"winters",
"wires",
"wishes",
"witches",
"wolfs",
"wome",
"woods",
"wools",
"words",
"workshops",
"worms",
"wounds",
"wrens",
"wrenches",
"wrinkles",
"wrists",
"xylophones",
"yachts",
"yaks",
"yards",
"yogurts",
"zebras",
"zippers",
"zoos",
]