import React from "react"
import { MotiView } from "moti"
import { Dimensions } from "react-native"
import Bubble from "@common/images/bubble.svg"
import useInterval from "@common/utils/useInterval"

const { height, width } = Dimensions.get("window")
const SCALE_BUBBLE = { min: 0.1, max: 2 }
const INTERVAL_DELAY = 400

export const useBubbles = () => {
  const [currentBubbles, setCurrentBubbles] = React.useState(0)
  const [bubblesList, setBubblesList] = React.useState(new Array(20))

  const addBubbleToScreen = () => {
    // If current find in last bubble in list
    let bubbleIndex = currentBubbles
    if (bubbleIndex >= bubblesList.length) {
      bubbleIndex = 0
      setCurrentBubbles(0)
    }

    // Get random point to instance bubble
    const xPosition = Math.floor(Math.random() * width)
    // Get random scale of bubble
    const scale = (Math.random() + SCALE_BUBBLE.min) * SCALE_BUBBLE.max

    let newArr = [...bubblesList]
    // Reset next bubble in list
    newArr[bubbleIndex + 1 < bubblesList.length ? bubbleIndex + 1 : 0] = null

    // New assign bubble in current index in bubble list
    newArr[bubbleIndex] = (
      <MotiView
        key={bubbleIndex}
        from={{
          translateY: 0,
          scale: scale,
        }}
        animate={{
          translateY: -height - width * 0.03 * 2 * 2,
        }}
        transition={{
          type: "timing",
          duration: Math.max(20000 * Math.random(), 5000),
        }}
        style={{
          position: "absolute",
          aspectRatio: 1,
          width: "3%",
          bottom: 0,
          right: xPosition,
        }}
      >
        <Bubble />
      </MotiView>
    )
    setBubblesList(newArr)

    // Plus current index bubble
    setCurrentBubbles(bubbleIndex + 1)
  }

  useInterval(() => {
    if (Math.random() < 0.3) {
      addBubbleToScreen()
    }
  }, INTERVAL_DELAY)

  return bubblesList
}
