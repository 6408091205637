import React from "react"
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import {
  GameCardPlayStyleEnum,
  useAddRoundsMutation,
  useUpdateAllRoundsMutation,
  useDeleteRoundsMutation,
  CurrentGameSubscription,
} from "@gql"
import { RoundSettings } from "@lobby/components/RoundSettings"
import { SettingsSummary } from "@lobby/components/SettingsSummary"
import { useTranslation } from "react-i18next"
import { grid, theme, vertGrid } from "@common/theme"
import { Divider } from "react-native-elements/dist/divider/Divider"
import { useForm } from "react-hook-form"
import { useUpdateGameSettingsMutation } from "@gql"
import { SettingsNumericInput } from "@lobby/inputs/SettingsNumericInput"
import { SettingsTextInput } from "@lobby/inputs/SettingsTextInput"
import { SettingsSwitchInput } from "@lobby/inputs/SettingsSwitchInput"
import _ from "lodash"
import { Text, Button, flashMessage } from "@common/components"

export interface SettingsForm {
  num_entries_per_player: string
  starting_letter: string
  screen_cards: boolean
  seconds_per_turn: string
  allow_card_skips: boolean
  rounds: CurrentGameSubscription["games"][0]["rounds"]
}
export type SettingsFormNames =
  | "num_entries_per_player"
  | "starting_letter"
  | "screen_cards"
  | "seconds_per_turn"
  | "allow_card_skips"
  | "rounds"

export interface IProps {
  onDismiss: () => void
}

export const SettingsForm: React.FC<IProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const [updateGameSettings] = useUpdateGameSettingsMutation()
  const [updateAllRounds] = useUpdateAllRoundsMutation()
  const [deleteRounds] = useDeleteRoundsMutation()
  const [addRounds] = useAddRoundsMutation()

  const canConfigureSettings = currentPlayer.role === PlayerRole.Host

  const numEntriesPerPlayer = String(currentGame.num_entries_per_player || "")
  const startingLetter = currentGame.starting_letter || ""
  const screenCard = Boolean(currentGame.screen_cards)
  const secondsPerTurn = String(currentGame.seconds_per_turn || "")
  const allowCardSkips = Boolean(currentGame.allow_card_skips)
  const rounds = currentGame.rounds

  const methods = useForm<SettingsForm>({
    defaultValues: {
      num_entries_per_player: numEntriesPerPlayer,
      starting_letter: startingLetter,
      screen_cards: screenCard,
      seconds_per_turn: secondsPerTurn,
      allow_card_skips: allowCardSkips,
      rounds: rounds,
    },
  })

  React.useEffect(() => {
    methods.setValue("num_entries_per_player", numEntriesPerPlayer)
  }, [numEntriesPerPlayer])

  React.useEffect(() => {
    methods.setValue("starting_letter", startingLetter)
  }, [startingLetter])

  React.useEffect(() => {
    methods.setValue("screen_cards", screenCard)
  }, [screenCard])

  React.useEffect(() => {
    methods.setValue("seconds_per_turn", secondsPerTurn)
  }, [secondsPerTurn])

  React.useEffect(() => {
    methods.setValue("allow_card_skips", allowCardSkips)
  }, [allowCardSkips])

  const onSubmit = async (data: SettingsForm) => {
    const { rounds: dataRound, ...rest } = data

    var curRounds = [...currentGame.rounds]
    const newRounds = [...data.rounds]

    const toDelete = _.differenceWith(curRounds, newRounds, _.isEqual)
    const toAdd = _.differenceWith(newRounds, curRounds, _.isEqual)
    const currentLength = curRounds.length

    const newRoundsWithId = await addRounds({
      variables: {
        objects: toAdd.map((updatedRound, updatedIndex) => {
          return {
            game_id: currentGame.id,
            value: _.lowerFirst(updatedRound.value),
            order_sequence: currentLength + updatedIndex,
          }
        }),
      },
    })

    let addedRounds = newRoundsWithId.data?.insert_rounds?.returning
    newRounds.forEach((round) => {
      if (round.id === null && addedRounds?.length) {
        const foundIndex = _.findIndex(addedRounds, {
          value: _.lowerFirst(round.value),
        })

        round.id = addedRounds[foundIndex].id
        addedRounds?.splice(foundIndex, 1)
      }
    })

    await deleteRounds({
      variables: {
        ids: toDelete.map((round) => round.id),
        gameId: currentGame.id,
        rounds: newRounds.map((updatedRound, updatedIndex) => {
          return {
            id: updatedRound.id,
            value: updatedRound.value,
            order_sequence: updatedIndex,
          }
        }),
      },
    })

    await updateGameSettings({
      variables: {
        id: currentGame.id,
        input: {
          num_entries_per_player: parseInt(rest.num_entries_per_player),
          starting_letter: rest.starting_letter,
          screen_cards: rest.screen_cards,
          seconds_per_turn: parseInt(rest.seconds_per_turn),
          allow_card_skips: rest.allow_card_skips,
        },
      },
    })

    flashMessage("Settings Saved!", "success")

    onDismiss()
  }

  return (
    <>
      <View style={[styles.container, { paddingBottom: vertGrid(1) }]}>
        <SettingsSummary
          numEntriesPerPlayer={Number(methods.watch("num_entries_per_player"))}
          startingLetter={methods.watch("starting_letter")}
          screenCard={methods.watch("screen_cards")}
          secondsPerTurn={Number(methods.watch("seconds_per_turn"))}
          allowCardSkips={methods.watch("allow_card_skips")}
          rounds={methods.watch("rounds")}
        />
      </View>
      <Divider color={theme.color.accent_primary} />
      <ScrollView style={[styles.scrollContainer, { flex: 1 }]}>
        <Text style={styles.settingsTitle}>
          {t("settings.cards.heading", "Cards")}
        </Text>
        {/* Only option for now TODO: add packs */}
        {currentGame.card_play_style ===
          GameCardPlayStyleEnum.PlayersSubmitWords && (
          <>
            <View style={styles.settingsContainer}>
              <Text>
                {t(
                  "settings.cards.submissionsPerPlayer.helper",
                  "Usually {{ range }} depending on group size",
                  { range: "4-6" }
                )}
              </Text>
              <SettingsNumericInput
                methods={methods}
                name="num_entries_per_player"
                editable={canConfigureSettings}
                placeholder={t(
                  "settings.cards.submissionsPerPlayer.label",
                  "Submissions Per Player"
                )}
                min={1}
                max={10}
                step={1}
              />
            </View>
            <View style={styles.settingsContainer}>
              <Text>
                {t(
                  "settings.cards.letter.helper",
                  "One style of play is that all words or phrases must start with the same letter."
                )}{" "}
                {t("settings.cards.letter.helperHost", "Ask your group!")}
              </Text>
              <TouchableOpacity
                onPress={() => {
                  const randomLetter =
                    _.sample(Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ")) || "A"
                  methods.setValue("starting_letter", randomLetter)
                }}
              >
                <Text style={{ color: "blue" }}>
                  {" "}
                  {t(
                    "settings.cards.letter.generateRandom",
                    "Generate random letter"
                  )}
                </Text>
              </TouchableOpacity>
              <SettingsTextInput
                methods={methods}
                name="starting_letter"
                editable={canConfigureSettings}
              />
            </View>
            <View style={styles.settingsContainer}>
              <SettingsSwitchInput
                methods={methods}
                name="screen_cards"
                placeholder={t(
                  "settings.turns.skipLabel",
                  "Allow host to screen cards"
                )}
                editable={canConfigureSettings}
              />
            </View>
          </>
        )}
        <View style={styles.settingsContainer}>
          <Text style={styles.settingsTitle}>
            {t("settings.turns.heading", "Turns")}
          </Text>
        </View>
        <View style={styles.settingsContainer}>
          <Text>
            {t(
              "settings.turns.secondsPerTurn.helper",
              "Usually between {{ secondsLowerBound }} or {{ secondsUpperBound }} seconds",
              {
                secondsLowerBound: 30,
                secondsUpperBound: 60,
              }
            )}
          </Text>
          <SettingsNumericInput
            methods={methods}
            name="seconds_per_turn"
            editable={canConfigureSettings}
            placeholder={t(
              "settings.turns.secondsPerTurn.label",
              "Seconds Per Turn"
            )}
            min={30}
            max={180}
            step={5}
          />
        </View>
        <View style={styles.settingsContainer}>
          <SettingsSwitchInput
            methods={methods}
            name="allow_card_skips"
            placeholder={t(
              "settings.turns.skipLabel",
              "Allow card skips during turn"
            )}
            editable={canConfigureSettings}
          />
        </View>
        <Text style={styles.settingsTitle}>
          {t("settings.rounds.heading", "Rounds")}
        </Text>
        <Text>
          {currentPlayer.role === PlayerRole.Host &&
            t(
              "settings.rounds.description",
              "You can add, remove, or reorder rounds (long press). By default, cards submitted will be re-used across rounds of Taboo, Charades, and Password."
            )}
        </Text>
        <RoundSettings
          methods={methods}
          name="rounds"
          editable={canConfigureSettings}
        />
        {/* {currentPlayer.role === PlayerRole.Host ? (
        <ControllableRoundSettings />
      ) : (
        <RoundSettings />
      )} */}
      </ScrollView>
      <View>
        <Divider color={theme.color.accent_primary} />
        <Button
          block
          textVariant="h2"
          style={styles.buttonContainer}
          onPress={methods.handleSubmit(onSubmit)}
        >
          {t("general.save", "Save")}
        </Button>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 10,
    marginVertical: vertGrid(2),
  },
  buttonText: {
    ...theme.fonts.h2,
    color: theme.color.text_secondary,
  },
  container: {
    paddingHorizontal: grid(1),
  },
  scrollContainer: {
    paddingHorizontal: grid(1),
    paddingVertical: vertGrid(5),
  },
  detail: {
    ...theme.fonts.p,
    color: theme.color.text_caption,
    marginTop: 24,
  },
  header: {
    ...theme.fonts.h1,
    color: theme.color.text_primary,
    marginTop: 80,
  },
  marginBottom: {
    marginBottom: 24,
  },
  safeAreaContainer: {
    backgroundColor: theme.color.background_primary,
    flex: 1,
  },
  settingsContainer: {
    margin: 10,
  },
  settingsTitle: {
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
  },
})
