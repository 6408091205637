import { TextStyle, ViewStyle } from "react-native"

type Style = TextStyle | TextStyle[] | ViewStyle | ViewStyle[] | undefined

export const makeStyleArray = (style: Style) => {
  if(!style) return [{}]
  if (!Array.isArray(style)) return [style]

  return style
}
