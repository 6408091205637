import React from 'react'
import { getPathProps, getStartAt } from './utils'
import type { Props, ColorFormat } from './types'
import useInterval from "@common/utils/useInterval"

const linearEase = (
  time: number,
  start: number,
  goal: number,
  duration: number
) => {
  if (duration === 0) {
    return start
  }

  const currentTime = time / duration
  return start + goal * currentTime
}

const getRGB = (color: string) =>
  color
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16)) ?? []

const getStroke = (props: Props, remainingTime: number): ColorFormat => {
  const { colors, colorsTime, isSmoothColorTransition = true } = props
  if (typeof colors === 'string') {
    return colors
  }

  const index =
    colorsTime?.findIndex(
      (time, i) => time >= remainingTime && remainingTime >= colorsTime[i + 1]
    ) ?? -1

  if (!colorsTime || index === -1) {
    return colors[0]
  }

  if (!isSmoothColorTransition) {
    return colors[index]
  }

  const currentTime = colorsTime[index] - remainingTime
  const currentDuration = colorsTime[index] - colorsTime[index + 1]
  const startColorRGB = getRGB(colors[index])
  const endColorRGB = getRGB(colors[index + 1])

  return `rgb(${startColorRGB
    .map(
      (color, index) =>
        linearEase(
          currentTime,
          color,
          endColorRGB[index] - color,
          currentDuration
        ) | 0
    )
    .join(',')})`
}

export const useCountdown = (props: Props) => {
  const {
    startDuration,
    remainingTime,
    size = 180,
    strokeWidth = 12,
    trailStrokeWidth,
    rotation = 'clockwise',
  } = props
  const maxStrokeWidth = Math.max(strokeWidth, trailStrokeWidth ?? 0)
  const { path, pathLength } = getPathProps(size, maxStrokeWidth, rotation)
  const [accurateRemainingTime, setaccurateRemainingTime] = React.useState(remainingTime)

  React.useEffect(() => {
    setaccurateRemainingTime(remainingTime)
  }, [remainingTime])

  const FRAMES_PER_SECOND = 60
  const INTERVAL_DELAY = 1000 / FRAMES_PER_SECOND
  useInterval(() => {
    const newTime = accurateRemainingTime - ( 1 / FRAMES_PER_SECOND)
    if(newTime <= 0) {
      setaccurateRemainingTime(0)
    } else if (startDuration !== remainingTime && newTime > remainingTime - 1 ) {
      setaccurateRemainingTime(newTime)
    }
  }, INTERVAL_DELAY)

  const elapsedTime = startDuration - accurateRemainingTime

  return {
    elapsedTime,
    path,
    pathLength,
    rotation,
    size,
    stroke: getStroke(props, accurateRemainingTime),
    strokeDashoffset: linearEase(elapsedTime, 0, pathLength, startDuration),
    strokeWidth,
  }
}