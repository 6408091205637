import React from "react"
import {
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from "react-native"

export const KeyboardAvoidingView: React.FC<KeyboardAvoidingViewProps> = ({
  children,
  ...props
}) => {
  return Platform.OS === "ios" ? (
    <RNKeyboardAvoidingView behavior={"padding"} {...props}>
      {children}
    </RNKeyboardAvoidingView>
  ) : (
    <>{children}</>
  )
}
