import React from "react"
import { View, StyleSheet, ScrollView } from "react-native"
import { PlayerArena } from "@common/components/PlayerArena"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import {
  GameCardPlayStyleEnum,
  GameStateEnum,
  useLoadWordsMutation,
  useRemovePlayerMutation,
  useUpdateAllPlayersMutation,
  useUpdateGameStateMutation,
} from "@gql"
import { parseWordList } from "@common/utils/cards"
import { teamsWithSequence } from "@common/utils/team"
import { filter, find, isEmpty, reject } from "lodash"
import { useTranslation } from "react-i18next"
import { theme, vertGrid } from "@common/theme"
import { Text, Button } from "@common/components"
import { Tooltip } from "react-native-elements/dist/tooltip/Tooltip"
import { Octicons } from "@expo/vector-icons"

export const WaitingRoom = (props: {
  cardPlayStyle: GameCardPlayStyleEnum
}) => {
  const { t } = useTranslation()
  const MIN_NUMBER_OF_PLAYERS = 4
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const [wordList, setWordList] = React.useState("")
  const [updateGameState] = useUpdateGameStateMutation()
  const [removePlayer] = useRemovePlayerMutation()
  const [loadWords] = useLoadWordsMutation()
  const [updateAllPlayers] = useUpdateAllPlayersMutation()

  const playersWithUsernames =
    reject(currentGame.players, (player) => isEmpty(player.username)) || []
  const playersWithoutUsernames =
    filter(currentGame.players, (player) => isEmpty(player.username)) || []
  const canSeeStartGameButton = currentPlayer.role === PlayerRole.Host
  const canStartGame =
    canSeeStartGameButton &&
    currentGame.seconds_per_turn &&
    find(playersWithUsernames, (player) => player.id === currentPlayer.id) &&
    playersWithUsernames.length >= MIN_NUMBER_OF_PLAYERS &&
    ((props.cardPlayStyle === GameCardPlayStyleEnum.PlayersSubmitWords &&
      currentGame.num_entries_per_player) ||
      (props.cardPlayStyle === GameCardPlayStyleEnum.HostProvidesWords &&
        Boolean(wordList)))

  const handleSubmit = async () => {
    await Promise.all(
      playersWithoutUsernames.map((player) => {
        return removePlayer({
          variables: {
            id: player.id,
          },
        })
      })
    )
    if (
      props.cardPlayStyle === GameCardPlayStyleEnum.HostProvidesWords &&
      wordList
    ) {
      await loadWords({
        variables: {
          objects: parseWordList(wordList).map((word) => {
            return {
              word,
              game_id: currentGame.id,
              player_id: currentPlayer.id,
              is_allowed: true,
            }
          }),
        },
      })
      const players = teamsWithSequence(currentGame.players, currentGame.teams)
      await updateAllPlayers({
        variables: {
          gameId: currentGame.id,
          players: players.map(({ id, team_id, team_sequence }) => ({
            id,
            team_id,
            team_sequence,
          })),
        },
      })
      updateGameState({
        variables: {
          id: currentGame.id,
          state: GameStateEnum.TeamAssignment,
        },
      })
    } else if (
      props.cardPlayStyle === GameCardPlayStyleEnum.PlayersSubmitWords
    ) {
      updateGameState({
        variables: {
          id: currentGame.id,
          state: GameStateEnum.CardSubmission,
        },
      })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          alignItems: "flex-end",
        }}
      >
        {currentPlayer.role === PlayerRole.Host && (
          <Tooltip
            popover={
              <>
                <Text>
                  {t(
                    "lobby.hostHelper.removeHost",
                    "In case someone is switching devices or browsers, you can remove them as the host by long pressing the player."
                  )}
                </Text>
                {/* <Text>
                  {t(
                    "lobby.hostHelper.lateJoining",
                    "Once you start the game, new players cannot join.\n We'll add support for players joining late soon!"
                  )}
                </Text> */}
              </>
            }
            height={150}
            backgroundColor={theme.color.pastel_teal}
          >
            <Octicons color={theme.color.text_primary} name="info" size={20} />
          </Tooltip>
        )}
      </View>
      <ScrollView style={{ flex: 1 }}>
        <PlayerArena
          players={playersWithUsernames}
          hostCanRemovePlayer={currentPlayer.role === PlayerRole.Host}
          textColor={theme.color.text_secondary}
        />
      </ScrollView>
      {canSeeStartGameButton && (
        <>
          {playersWithUsernames.length < 4 && (
            <Text
              variant="footnote"
              style={{ textAlign: "center", color: "white" }}
            >
              {t(
                "lobby.minPlayers",
                `Waiting for at least ${
                  4 - playersWithUsernames.length
                } more players.`
              )}
            </Text>
          )}
          <Button
            block
            textVariant="h2"
            style={styles.buttonContainer}
            onPress={handleSubmit}
            disabled={!canStartGame}
          >
            {t("lobby.everyoneHereButton", "Everyone's Here!")}
          </Button>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginVertical: vertGrid(2),
  },
  buttonText: {
    ...theme.fonts.h2,
  },
  container: {
    display: "flex",
    paddingLeft: "10%",
    paddingRight: "10%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  input: {
    width: "100%",
    height: "20%",
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
    fontSize: 20,
    textAlign: "center",
  },
})
