import React from "react"
import { View } from "react-native"
import {
  CurrentGameSubscription,
  useAcceptCardMutation,
  useRejectCardMutation,
} from "@gql"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { PlayerChip } from "@common/components/PlayerChip"
import { Card } from "react-native-elements"
import { Text } from "@common/components"
import { FontAwesome } from "@expo/vector-icons"
import { TouchableOpacity } from "react-native-gesture-handler"
import { theme, vertGrid } from "@common/theme"

function ScreenCard(props: {
  card: CurrentGameSubscription["games"][0]["cards"][0]
}) {
  const currentGame = React.useContext(CurrentGameContext)
  const [acceptCard] = useAcceptCardMutation()
  const [rejectCard] = useRejectCardMutation()

  const player = currentGame.players.find(
    (player) => player.id === props.card.player_id
  )

  return (
    <Card containerStyle={{ borderRadius: 10, width: "80%" }}>
      <PlayerChip username={player?.username || ""} />
      <Text
        style={{
          borderWidth: 1,
          borderColor: theme.color.accent_secondary,
          padding: vertGrid(2),
          margin: vertGrid(4),
        }}
      >
        {props.card.word}
      </Text>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            rejectCard({
              variables: {
                id: props.card.id,
              },
            })
          }}
        >
          <FontAwesome color="red" name="times-circle" size={48} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            acceptCard({
              variables: {
                id: props.card.id,
              },
            })
          }}
        >
          <FontAwesome color="green" name="check-circle" size={48} />
        </TouchableOpacity>
      </View>
      {/* <IconButton
        color="secondary"
        onClick={() => {
          rejectCard({
            variables: {
              id: props.card.id,
            },
          })
        }}
      >
        <CancelIcon />
      </IconButton>
      <IconButton
        style={{ color: green[600] }}
        onClick={() => {
          acceptCard({
            variables: {
              id: props.card.id,
            },
          })
        }}
      >
        <CheckCircleIcon />
      </IconButton> */}
    </Card>
  )
}

export default ScreenCard
