import React from "react"
import {
  HeaderBackButton as RNEHeaderBackButton,
  HeaderBackButtonProps,
} from "@react-navigation/elements"
import { usePlaySound } from "@common/hooks/usePlaySound"

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({
  ...props
}) => {
  const playClickSound = usePlaySound("click")

  return (
    <RNEHeaderBackButton
      {...props}
      onPress={() => {
        playClickSound()
        props.onPress?.()
      }}
    />
  )
}
