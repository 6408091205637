import { vertGrid } from "@common/theme"
import React from "react"
import { ColorValue, View, ViewStyle, SafeAreaView } from "react-native"
import { FilledStatus } from "../FilledStatus/FilledStatus"

type HeaderProps = {
  header?: React.ReactNode
  headerColor?: ColorValue
  headerDividerColor?: ColorValue
  headerStyle?: ViewStyle
  footer?: React.ReactNode
  footerColor?: ColorValue
  footerDividerColor?: ColorValue
  footerStyle?: ViewStyle
}

export const Layout: React.FC<HeaderProps> = ({
  header = null,
  headerColor,
  headerDividerColor,
  headerStyle,
  footer = null,
  footerColor,
  footerDividerColor,
  footerStyle,
  children,
}) => {
  return (
    <>
      {header && headerColor && <FilledStatus color={headerColor} />}
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        {header && (
          <View
            style={[
              {
                width: "100%",
                backgroundColor: headerColor,
                paddingBottom: vertGrid(2),
                top: 0,
              },
              headerDividerColor
                ? {
                    borderBottomWidth: 1,
                    borderBottomColor: headerDividerColor,
                    marginBottom: vertGrid(2),
                  }
                : {},
              headerStyle,
            ]}
          >
            {header}
          </View>
        )}
        <View style={{ flex: 1 }}>{children}</View>
        {footer && (
          <View
            style={[
              {
                width: "100%",
                backgroundColor: footerColor,
                paddingTop: vertGrid(2),
              },
              footerDividerColor
                ? {
                    borderTopWidth: 1,
                    borderTopColor: footerDividerColor,
                    marginTop: vertGrid(2),
                  }
                : {},
              footerStyle,
            ]}
          >
            {footer}
          </View>
        )}
      </SafeAreaView>
      {footer && footerColor && (
        <FilledStatus color={footerColor} header={false} />
      )}
    </>
  )
}
