import React from "react"
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native"
import { PlayerChipList } from "@common/components/PlayerChipList"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import {
  CurrentGameSubscription,
  Rounds,
  TurnScoringsInsertInput,
  useEndCurrentTurnAndStartNextTurnMutation,
  useStartTurnMutation,
} from "@gql"
import {
  ActiveTurnPlayState,
  drawableCardsWithoutCompletedCardsInActiveTurn,
  nextPlayerForNextTeam,
  nextPlayerForSameTeam,
} from "@common/utils/turn"
import { compact, filter, reject, shuffle } from "lodash"
import { Trans, useTranslation } from "react-i18next"
import { grid, theme, PlayStateColors, vertGrid } from "@common/theme"
import {
  CardStack,
  Card,
  SwipeableCardRef,
} from "@common/components/SwipeableCard"
import { GameRoutes, RootRoutes } from "@common/routes"
import { useNavigation } from "@react-navigation/native"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { Button, Text, CheckBox } from "@common/components"
import { usePlaySound } from "@common/hooks/usePlaySound"

enum ShownCardStatus {
  Complete = "complete",
  Skipped = "skipped",
  Incomplete = "incomplete",
  Incorrect = "incorrect",
}

interface IProps {
  yourTeamPlayers: CurrentGameSubscription["games"][0]["players"]
  cardsInBowl: CurrentGameSubscription["games"][0]["cards"]
  activePlayer: CurrentGameSubscription["games"][0]["players"][0]
  activeTurn: CurrentGameSubscription["games"][0]["turns"][0]
  activeTurnPlayState: ActiveTurnPlayState
  secondsLeft: number
  currentRoundId: Rounds["id"]
  nextRoundId?: Rounds["id"]
  onStart: () => void
  onOutOfCards: () => void
  activeCardIndex: number | null
  setActiveCardIndex: (newIndex: number) => void
}

export const YourTurnContent: React.FC<IProps> = ({
  yourTeamPlayers,
  cardsInBowl,
  activePlayer,
  activeTurn,
  activeTurnPlayState,
  secondsLeft,
  currentRoundId,
  nextRoundId,
  onStart,
  onOutOfCards,
  activeCardIndex,
  setActiveCardIndex,
}) => {
  const { t } = useTranslation()
  const navigation = useNavigation<any>()
  const { showActionSheetWithOptions } = useActionSheet()

  const { serverTimeOffset } = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const [startTurn] = useStartTurnMutation()
  const [endTurn] = useEndCurrentTurnAndStartNextTurnMutation()

  const [startingTurn, setStartingTurn] = React.useState(false)
  const [endingTurn, setEndingTurn] = React.useState(false)
  const [skippingTurn, setSkippingTurn] = React.useState(false)
  const WordCardRef = React.useRef<SwipeableCardRef>(null)
  const isMountRef = React.useRef(true)
  const playClickSound = usePlaySound("click")

  usePlaySound("round_start", true, true, "long")
  const playRoundEnd = usePlaySound("round_end", false, true, "long")
  const playTimer1 = usePlaySound("timer1", false, false, "long")
  const playTimer2 = usePlaySound("timer2", false, false, "long")

  const shuffledCards = React.useMemo(() => {
    return shuffle(cardsInBowl)
  }, [])
  const shownCardsInActiveTurn = React.useRef(new Map())
  const cardWidth = { minWidth: "50%" }

  React.useEffect(() => {
    if (activeCardIndex != null && secondsLeft <= 0) {
      const shownCard = shownCardsInActiveTurn.current.get(
        shuffledCards[activeCardIndex].id
      )
      if (shownCard) {
        shownCardsInActiveTurn.current = new Map(
          shownCardsInActiveTurn.current.set(
            shuffledCards[activeCardIndex].id,
            {
              ...shownCard,
              endedAt: new Date(),
            }
          )
        )
      }
    }
  }, [secondsLeft, activeCardIndex])

  React.useEffect(() => {
    setActiveCardIndex(0)
    shownCardsInActiveTurn.current = new Map([
      [
        shuffledCards[0].id,
        {
          status: ShownCardStatus.Incomplete,
          startedAt: new Date(),
          endedAt: new Date(),
        },
      ],
    ])

    return () => {
      setActiveCardIndex(0)
    }
  }, [activePlayer.id])

  React.useEffect(() => {
    if (secondsLeft <= 10 && secondsLeft > 0) {
      if (secondsLeft % 2 == 0) {
        playTimer1()
      } else {
        playTimer2()
      }
    }
    if (!isMountRef.current && secondsLeft === 0) {
      playRoundEnd()
    }

    isMountRef.current = false
  }, [secondsLeft])

  const onNextCardClick = React.useCallback(
    (status: ShownCardStatus) => {
      if (activeCardIndex != null) {
        // mark the active card as "complete" or "skipped"
        const shownCard = shownCardsInActiveTurn.current.get(
          shuffledCards[activeCardIndex].id
        )
        if (shownCard) {
          shownCardsInActiveTurn.current = new Map(
            shownCardsInActiveTurn.current.set(
              shuffledCards[activeCardIndex].id,
              {
                ...shownCard,
                status: status,
                endedAt: new Date(),
              }
            )
          )
        }

        const nextActiveCardIndex = activeCardIndex + 1
        setActiveCardIndex(nextActiveCardIndex)

        const outOfCards = shuffledCards.length - 1 === activeCardIndex
        if (outOfCards) {
          onOutOfCards()
        } else {
          shownCardsInActiveTurn.current = new Map(
            shownCardsInActiveTurn.current.set(
              shuffledCards[nextActiveCardIndex].id,
              {
                status: ShownCardStatus.Incomplete,
                startedAt: new Date(),
                endedAt: new Date(),
              }
            )
          )
        }
      }
    },
    [activeCardIndex, shuffledCards]
  )

  const yourTeammates = React.useMemo(
    () => reject(yourTeamPlayers, (player) => player.id === activePlayer.id),
    [activePlayer.id, yourTeamPlayers]
  )

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        paddingHorizontal: grid(1),
      }}
    >
      {activeTurnPlayState === ActiveTurnPlayState.Reviewing &&
      secondsLeft > 0 ? (
        <Text>
          {t(
            "play.yourTurn.leftoverSeconds",
            "You'll be starting the next round with {{ count }} second leftover from this turn!",
            {
              count: secondsLeft,
              defaultValue_plural:
                "You'll be starting the next round with {{ count }} seconds leftover from this turn!",
            }
          )}
        </Text>
      ) : null}

      {/* Cards */}
      {[ActiveTurnPlayState.Waiting, ActiveTurnPlayState.Playing].includes(
        activeTurnPlayState
      ) && (
        <View
          style={{
            flex: 1,
            width: "100%",
          }}
        >
          {!!yourTeammates.length && (
            <Trans
              t={t}
              i18nKey="play.yourTurn.context"
              count={yourTeammates.length}
              tOptions={{
                defaultValue_plural:
                  "<0>{{playerUsernames}}</0> from your team are guessing!",
              }}
            >
              <PlayerChipList players={yourTeammates}>
                {{ playerUsernames: null }}
              </PlayerChipList>
              <Text style={{ textAlign: "center" }}>
                {" from your team is guessing!"}
              </Text>
            </Trans>
          )}
          {activeTurnPlayState === ActiveTurnPlayState.Playing ? (
            <CardStack
              activeCardIndex={activeCardIndex}
              cardContainerStyle={[
                styles.content,
                { width: "100%", height: "100%" },
              ]}
              style={styles.content}
              ref={WordCardRef}
              rightSwipeText="Correct"
              leftSwipeText="SKIP"
              renderNoMoreCards={() => (
                <Text
                  style={{ fontWeight: "700", fontSize: 18, color: "gray" }}
                >
                  You finished this set!
                </Text>
              )}
              verticalSwipe={false}
              disableLeftSwipe={!currentGame.allow_card_skips}
              onSwipedLeft={() => onNextCardClick(ShownCardStatus.Skipped)}
              onSwipedRight={() => onNextCardClick(ShownCardStatus.Complete)}
            >
              {shuffledCards.map((card, index) => (
                <Card
                  key={card.id}
                  style={[
                    styles.card,
                    cardWidth,
                    index % 2 == 0 ? styles.card1 : styles.card2,
                    { height: "100%" },
                  ]}
                >
                  <View pointerEvents="none">
                    <Text selectable={false} style={styles.label}>
                      {card.word}
                    </Text>
                  </View>
                </Card>
              ))}
            </CardStack>
          ) : (
            <View style={styles.content}>
              <View style={[styles.card, cardWidth]}>
                <Text style={styles.label}>
                  {t("play.yourTurn.emptyCard", "You'll see cards here!")}
                </Text>
              </View>
            </View>
          )}
        </View>
      )}
      {activeTurnPlayState === ActiveTurnPlayState.Reviewing && (
        <>
          <Text>
            {t(
              "play.yourTurn.reviewHelper.default",
              "Review the cards you went through this turn."
            )}{" "}
            {currentGame.allow_card_skips
              ? t(
                  "play.yourTurn.reviewHelper.withSkips",
                  "If you skipped or missed any, just uncheck them."
                )
              : t(
                  "play.yourTurn.reviewHelper.withoutSkips",
                  "If you missed any, just uncheck them."
                )}
          </Text>
          <ScrollView style={{ width: "100%", margin: 10 }}>
            {[...shownCardsInActiveTurn.current.keys()].map((cardId) => {
              return (
                <View key={cardId}>
                  <CheckBox
                    title={
                      <View style={{ flex: 1 }}>
                        <Text style={{ textAlign: "center" }}>
                          <Text style={{ fontWeight: "bold" }}>
                            {shownCardsInActiveTurn.current.get(cardId)
                              ?.status === ShownCardStatus.Skipped &&
                              t("play.yourTurn.cardButton.skip", "(skipped) ")}
                          </Text>
                          {cardsInBowl.find((card) => card.id === cardId)?.word}
                        </Text>
                      </View>
                    }
                    containerStyle={styles.bubble}
                    checkedColor={theme.color.text_inverse_highlight}
                    defaultValue={
                      shownCardsInActiveTurn.current.get(cardId)?.status ===
                      ShownCardStatus.Complete
                    }
                    handlePress={(checked) => {
                      const shownCard =
                        shownCardsInActiveTurn.current.get(cardId)
                      if (shownCard) {
                        shownCardsInActiveTurn.current = new Map(
                          shownCardsInActiveTurn.current.set(cardId, {
                            ...shownCard,
                            status: checked
                              ? ShownCardStatus.Complete
                              : ShownCardStatus.Incomplete,
                          })
                        )
                      }
                    }}
                  />
                </View>
              )
            })}
          </ScrollView>
        </>
      )}

      {/* Controls */}
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {activeTurnPlayState === ActiveTurnPlayState.Playing && (
          <>
            <View style={styles.buttonContainer}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                {currentGame.allow_card_skips && (
                  <TouchableOpacity
                    style={[styles.button, styles.red]}
                    onPress={() => {
                      playClickSound()
                      WordCardRef.current?.swipeLeft()
                    }}
                  >
                    <Text fontVariant="Bold" style={styles.quickActionText}>
                      SKIP
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {/* {currentGame.allow_card_skips && (
                  <TouchableOpacity
                    style={[styles.button, styles.orange]}
                    onPress={() => {
                      playClickSound()
                      WordCardRef.current?.goBackFromLeft()
                    }}
                  >
                    <Text fontVariant="Bold" >Undo</Text>
                  </TouchableOpacity>
                )} */}
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <TouchableOpacity
                  style={[styles.button, styles.green]}
                  onPress={() => {
                    playClickSound()
                    WordCardRef.current?.swipeRight()
                  }}
                >
                  <Text fontVariant="Bold" style={styles.quickActionText}>
                    CORRECT
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </>
        )}

        {activeTurnPlayState === ActiveTurnPlayState.Waiting && (
          <Button
            textVariant="h3"
            style={{
              paddingHorizontal: grid(1),
              marginRight: grid(1),
            }}
            disabled={skippingTurn}
            onPress={() => {
              setSkippingTurn(true)

              const options = ["Skip", "Cancel"]
              const skipButtonIndex = 0
              const cancelButtonIndex = 1
              showActionSheetWithOptions(
                {
                  options,
                  cancelButtonIndex,
                },
                async (buttonIndex) => {
                  if (buttonIndex == skipButtonIndex) {
                    const response = await endTurn({
                      variables: {
                        currentTurnId: activeTurn.id,
                        completedCardIds: [],
                        gameId: currentGame.id,
                        currentTurnScorings: [],
                        nextTurnplayerId: nextPlayerForSameTeam(
                          activePlayer,
                          currentGame.players
                        ).id,
                        roundId: currentRoundId,
                      },
                    })
                    if (response.errors) {
                      setSkippingTurn(false)
                    }
                  } else {
                    setSkippingTurn(false)
                  }
                }
              )
            }}
          >
            {t("play.yourTurn.skipTurnButton", "Skip")}
          </Button>
        )}

        {activeTurnPlayState === ActiveTurnPlayState.Waiting && (
          <View style={{ flex: 1 }}>
            <Button
              block
              variant="primary"
              textVariant="h2"
              disabled={startingTurn}
              onPress={async () => {
                setStartingTurn(true)
                const response = await startTurn({
                  variables: {
                    currentTurnId: activeTurn.id,
                  },
                })
                if (response.errors) {
                  setStartingTurn(false)
                } else {
                  onStart()
                }
              }}
            >
              {t("play.yourTurn.startTurnButton", "Start Turn")}
            </Button>
          </View>
        )}

        {activeTurnPlayState === ActiveTurnPlayState.Reviewing && (
          <Button
            block
            textVariant="h2"
            disabled={endingTurn}
            onPress={async () => {
              setEndingTurn(true)
              const shownCardIds = [...shownCardsInActiveTurn.current.keys()]
              const completedCardIds = filter(shownCardIds, (cardId) => {
                return (
                  shownCardsInActiveTurn.current.get(cardId)?.status ===
                  ShownCardStatus.Complete
                )
              })
              const continueTurnIntoNewRound =
                completedCardIds.length === shownCardIds.length &&
                drawableCardsWithoutCompletedCardsInActiveTurn(cardsInBowl, [
                  ...shownCardsInActiveTurn.current.keys(),
                ]).length === 0 &&
                secondsLeft !== 0

              const scorings = compact(
                shownCardIds.map<TurnScoringsInsertInput | null>((cardId) => {
                  const card = shownCardsInActiveTurn.current.get(cardId)
                  if (card) {
                    return {
                      turn_id: activeTurn.id,
                      card_id: cardId,
                      score: card.status === ShownCardStatus.Complete ? 1 : 0,
                      status: card.status,
                      started_at: new Date(
                        card.startedAt.getTime() + serverTimeOffset
                      ),
                      ended_at: new Date(
                        card.endedAt.getTime() + serverTimeOffset
                      ),
                    }
                  } else {
                    return null
                  }
                })
              )

              const response = await endTurn({
                variables: {
                  currentTurnId: activeTurn.id,
                  completedCardIds: completedCardIds,
                  gameId: currentGame.id,
                  currentTurnScorings: scorings,
                  roundId: continueTurnIntoNewRound
                    ? nextRoundId
                    : currentRoundId,
                  nextTurnplayerId: continueTurnIntoNewRound
                    ? activePlayer.id
                    : nextPlayerForNextTeam(
                        activePlayer,
                        currentGame.turns,
                        currentGame.players
                      ).id,
                  nextTurnSecondsPerTurnOverride: continueTurnIntoNewRound
                    ? Math.round(Number(secondsLeft))
                    : null,
                },
              })
              if (response.errors) {
                setEndingTurn(false)
              } else if (continueTurnIntoNewRound) {
                // used to emulate reload
                navigation.replace(RootRoutes.GameNavigator, {
                  screen: GameRoutes.ActivePlay,
                  joinCode: currentGame.join_code?.toLocaleUpperCase(),
                })
              }
            }}
          >
            {t("play.yourTurn.endTurnButton", "End Turn")}
          </Button>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  bubble: {
    backgroundColor: theme.color.background_primary,
    borderRadius: 10,
    marginVertical: vertGrid(2),
    // alignSelf: "center",
  },
  container: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#f2f2f2",
  },
  textSection: {
    marginHorizontal: grid(1),
  },
  content: {
    flex: 1,
    margin: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flex: 1,
    aspectRatio: 32 / 47,
    maxHeight: "100%",
    // width: 320,
    // height: 470,
    backgroundColor: theme.color.pastel_light_blue,
    borderRadius: 5,
    shadowColor: "rgba(0,0,0,0.5)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
  },
  card1: {
    backgroundColor: theme.color.pastel_light_blue,
  },
  card2: {
    backgroundColor: theme.color.pastel_blue,
  },
  label: {
    // lineHeight: 400,
    textAlign: "center",
    fontSize: 55,
    fontFamily: "System",
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  footer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 20,
    shadowColor: "#000",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0,
  },
  quickActionText: {
    color: theme.color.text_secondary,
  },
  orange: {
    width: 55,
    height: 55,
    // borderWidth: 6,
    backgroundColor: PlayStateColors.wait,
    borderRadius: 55,
    marginTop: -15,
  },
  green: {
    width: 75,
    height: 75,
    // backgroundColor: "#fff",
    borderRadius: 75,
    // borderWidth: 6,
    backgroundColor: PlayStateColors.go,
  },
  red: {
    width: 75,
    height: 75,
    // backgroundColor: "#fff",
    borderRadius: 75,
    // borderWidth: 6,
    backgroundColor: PlayStateColors.stop,
  },
})
