import { StatusBar } from "expo-status-bar"
import React from "react"
import { Platform, Dimensions, View, StyleSheet } from "react-native"
import { AuthProvider } from "./src/common/contexts/CurrentAuthService"
import { RootNavigator } from "./src/common/navigators/RootNavigator"
import { ApolloWrapper } from "./src/common/utils/ApolloWrapper"
import { ThemeProvider } from "./src/common/contexts/ThemeProvider"
import FlashMessage from "react-native-flash-message"
import { ActionSheetProvider } from "@expo/react-native-action-sheet"
import "./i18n"
import { AndroidPadding, Bubbles, Logo, Text } from "@common/components"
import { useOTAUpdate } from "@common/hooks/useOTAUpdate"
import { useNotification } from "@common/hooks/useNotification"
import * as Progress from "react-native-progress"
import { theme } from "@common/theme"
import { LinearGradient } from "expo-linear-gradient"
import * as Sentry from "sentry-expo"

const { width } = Dimensions.get("window")

export default function App() {
  if (Platform.OS != "web") {
    useNotification()
  }

  const updateFound = Platform.OS !== "web" ? useOTAUpdate() : false

  Sentry.init({
    dsn: "https://02289f4752d74d208ae201af489aadc8@o1144231.ingest.sentry.io/6207948",
    // enableInExpoDevelopment: true,
    debug: __DEV__ ? true : false, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
  })

  return (
    <React.StrictMode>
      <ThemeProvider>
        <AuthProvider>
          <ApolloWrapper>
            {/* <ThemeProvider theme={theme}> */}
            {/* <View style={styles.container}> */}
            <StatusBar style="auto" />
            <React.Suspense fallback={<Text>loading...</Text>}>
              <ActionSheetProvider>
                {updateFound ? (
                  <LinearGradient
                    colors={[
                      theme.color.pastel_teal,
                      theme.color.pastel_blue,
                      theme.color.pastel_gray,
                    ]}
                    style={styles.container}
                  >
                    <View style={styles.container}>
                      <Logo />
                    </View>
                    <View
                      style={[
                        styles.container,
                        { justifyContent: "flex-start" },
                      ]}
                    >
                      <Text
                        variant="h2"
                        style={{ color: theme.color.text_secondary }}
                      >
                        {"Update Found! Updating..."}
                      </Text>
                      <Progress.Bar
                        indeterminate
                        width={width * 0.8}
                        color={theme.color.text_secondary}
                        borderColor={theme.color.text_secondary}
                      />
                    </View>
                  </LinearGradient>
                ) : (
                  <Bubbles>
                    <RootNavigator />
                  </Bubbles>
                )}
              </ActionSheetProvider>
              <FlashMessage
                position="top"
                floating={Platform.OS == "web" ? true : false}
                style={{ paddingTop: AndroidPadding }}
              />
            </React.Suspense>
            {/* </View> */}
            {/* </ThemeProvider> */}
          </ApolloWrapper>
        </AuthProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
