import React from "react"
import { grid, theme, vertGrid } from "@common/theme"
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  TextInput,
  FlatList,
} from "react-native"
import DraggableFlatList, {
  RenderItemParams,
  ScaleDecorator,
} from "react-native-draggable-flatlist"
import { useTranslation } from "react-i18next"
import { AntDesign } from "@expo/vector-icons"
import { Controller, UseFormReturn } from "react-hook-form"
import { SettingsForm } from "../forms/SettingsForm"
import { FontAwesome5 } from "@expo/vector-icons"

const { width } = Dimensions.get("window")

const NUM_ITEMS = 10
function getColor(i: number) {
  const multiplier = 255 / (NUM_ITEMS - 1)
  const colorVal = i * multiplier
  return `rgb(${colorVal}, ${Math.abs(128 - colorVal)}, ${255 - colorVal})`
}

type Item = {
  id: string | null
  value: string
}

interface IProps {
  methods: UseFormReturn<SettingsForm>
  name: "rounds"
  editable: boolean
}

export const RoundSettings: React.FC<IProps> = ({ methods, name }) => {
  const { t } = useTranslation()
  const [newRound, setNewRound] = React.useState("")
  const [warning, setWarning] = React.useState(false)

  const {
    control,
    formState: { errors },
  } = methods

  const renderItem = ({
    item,
    index,
    drag,
    isActive,
  }: RenderItemParams<Item>) => {
    const backgroundColor = getColor(index ?? 0)
    return (
      <ScaleDecorator>
        <TouchableOpacity
          onLongPress={drag}
          disabled={isActive}
          style={[
            styles.rowItem,
            { backgroundColor: isActive ? "red" : backgroundColor },
          ]}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "10%",
              }}
            />
            <View style={{ flex: 1 }}>
              <Text style={styles.text}>{item.value}</Text>
            </View>
            <View
              style={{
                width: "10%",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  const curVal = [...methods.getValues(name)]
                  if (curVal.length === 1) {
                    setWarning(true)
                    setTimeout(() => {
                      setWarning(false)
                    }, 1000)
                    return
                  }

                  if (index !== undefined) {
                    curVal.splice(index, 1)
                    methods.setValue(name, curVal)
                  }
                }}
              >
                <FontAwesome5 name="trash-alt" size={25} />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>
      </ScaleDecorator>
    )
  }

  return (
    <FlatList
      scrollEnabled={false}
      horizontal
      data={[]}
      renderItem={() => null}
      ListFooterComponent={
        <Controller
          control={control}
          rules={{
            maxLength: 1,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              {warning && <Text>Must have at least one round!</Text>}
              {errors[name] && <Text>{(errors[name] as any)?.message}</Text>}
              <DraggableFlatList
                scrollEnabled={false}
                data={value}
                onDragEnd={({ data }) => {
                  onChange(data)
                }}
                keyExtractor={(item) => item.id + item.value}
                renderItem={renderItem}
              />
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginTop: vertGrid(1),
                }}
              >
                <TextInput
                  placeholder={t("settings.rounds.add", "Add Custom Round")}
                  style={styles.input}
                  value={newRound}
                  onChangeText={setNewRound}
                />
                <TouchableOpacity
                  style={styles.sideButton}
                  onPress={() => {
                    if (newRound.length > 0) {
                      const newRoundObj = {
                        id: null,
                        value: newRound,
                      }
                      const curVal = methods.getValues(name)
                      methods.setValue(name, curVal.concat(newRoundObj))
                      setNewRound("")
                    }
                  }}
                >
                  <View style={styles.sideButtonContainer}>
                    <AntDesign name="plus" size={20} />
                  </View>
                </TouchableOpacity>
              </View>
            </>
          )}
          name={name}
        />
      }
    />
  )
}

const styles = StyleSheet.create({
  rowItem: {
    height: 100,
    width: width - grid(1) * 2,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  input: {
    width: "80%",
    padding: grid(1),
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: theme.color.accent_secondary,
    fontSize: 20,
    textAlign: "center",
  },
  sideButton: {
    width: "20%",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
  },
  sideButtonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
})
