import { Dimensions } from 'react-native';
import { color } from './colors';
const { width, height } = Dimensions.get('window');

const MAX_COLUMN = 25;
const WIDTH_PER_COLUMN = width / MAX_COLUMN;
// Guideline sizes are based on iPhone SE
const guidelineBaseWidth = 375;
const guidelineBaseHeight = 667;

export const grid = (cols: number) => {
  return WIDTH_PER_COLUMN * cols;
};

export const vertGrid = (scale: number) => {
  return 4 * scale
}

export const hexToRGB = (hex: string, alpha: string) => {

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

// https://medium.com/soluto-engineering/size-matters-5aeeb462900a
export const scale = (size: number) => (width / guidelineBaseWidth) * size;
export const verticalScale = (size: number) =>
  (height / guidelineBaseHeight) * size;
export const adjustedScale = (size: number, factor = 0.5) =>
  size + (scale(size) - size) * factor;
export const adjustedVerticalScale = (size: number, factor = 0.5) =>
  size + (verticalScale(size) - size) * factor;

export const theme = {
  color: color,
  fonts: {
    h1: {
      fontSize: 32,
      lineHeight: 38,
    },
    h2: {
      fontSize: 24,
      lineHeight: 28,
    },
    h3: {
      fontSize: 18,
      lineHeight: 20,
    },
    h4: {
      fontSize: 16,
      lineHeight: 16,
    },
    h5: {
      fontSize: 16,
      lineHeight: 24,
    },
    h6: {
      fontSize: 14,
      lineHeight: 20,
    },
    p: {
      fontSize: 16,
      lineHeight: 24,
    },
    caption: {
      fontSize: 14,
      lineHeight: 20,
    },
    inactive: {
      fontSize: 14,
      lineHeight: 20,
    },
    footnote: {
      fontSize: 12,
      lineHeight: 16,
    },
  },
};