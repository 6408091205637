import * as React from "react"
import AsyncStorage from "@react-native-async-storage/async-storage"

const asyncStorageKey = "gameInProgress"

export const getGameInProgress = async (): Promise<string | null> => {
  return await AsyncStorage.getItem(asyncStorageKey)
}

export const setGameInProgress = async (joinCode: string): Promise<string | null> => {
  await AsyncStorage.setItem(asyncStorageKey, joinCode)
  return joinCode
}

export const removeGameInProgress = async () => {
  await AsyncStorage.removeItem(asyncStorageKey)
}

export const useJoinGameInProgress = () => {
  const [joinGameInProgress, setJoinGameInProgress] = React.useState<string | null>(
    null
  )

  React.useEffect(() => {
    getGameInProgress().then((gip) => {
      setJoinGameInProgress(gip)
    })
  }, [])

  const deleteJoinGameInProgress = () => {
    removeGameInProgress().then(() => {
      setJoinGameInProgress(null)
    })
  }

  return {joinGameInProgress, setJoinGameInProgress, deleteJoinGameInProgress}
}