import React from "react"
import { View, TextInput, StyleSheet, ActivityIndicator } from "react-native"
import { CurrentAuthContext } from "@common/contexts/CurrentAuthService"
import { clientUuid } from "@common/contexts/CurrentPlayerService"
import {
  GameByJoinCodeQuery,
  useGameByJoinCodeQuery,
  useJoinGameMutation,
} from "@gql"
import { GameRoutes, RootRoutes } from "@common/routes"
import { useNavigation } from "@react-navigation/native"
import { useTranslation } from "react-i18next"
import { grid, theme } from "@common/theme"
import {
  SafeAreaView,
  Text,
  flashMessage,
  HeaderBackButton,
} from "@common/components"
import { RouteProp } from "@react-navigation/native"

interface IProps {
  route: RouteProp<{
    params: { joinCode?: string }
  }>
}

export const Join: React.FC<IProps> = ({ route }) => {
  const { t } = useTranslation()
  const currentAuth = React.useContext(CurrentAuthContext)
  const [joinCode, setJoinCode] = React.useState(route.params?.joinCode || "")
  const [loading, setLoading] = React.useState(false)
  const navigation = useNavigation<any>()

  const [joinGame] = useJoinGameMutation()

  const joinCodeNotEntered = !joinCode || joinCode.length < 4

  const { data } = useGameByJoinCodeQuery({
    skip: joinCodeNotEntered,
    variables: {
      joinCode: joinCode?.toLocaleUpperCase(),
    },
    onCompleted: (data) => {
      if (!data) {
        errored(
          t(
            "somethingWentWrong",
            "Something went wrong. Please try again later!"
          )
        )
      }
    },
  })

  // React.useEffect(() => {
  //   setJoinCode("GHHT")
  // }, [])

  const errored = (message: string) => {
    flashMessage(message, "warning")
    setJoinCode("")
  }

  const handleCompleted = async (data: GameByJoinCodeQuery) => {
    setJoinCode("")
    setLoading(true)
    if (data && data.games[0]) {
      try {
        const registration = await joinGame({
          variables: {
            gameId: data.games[0].id,
            clientUuid: await clientUuid(),
          },
          context: {
            headers: {
              "X-Hasura-Role": "anonymous",
            },
          },
        })
        if (registration.data?.joinGame) {
          await currentAuth.setJwtToken(registration.data.joinGame.jwt_token)
        }
        navigation.navigate(RootRoutes.GameNavigator, {
          screen: GameRoutes.Loading,
          joinCode: joinCode?.toLocaleUpperCase(),
        })
      } catch {
        // cannot join game
        errored(
          t(
            "invalidJoinCode",
            "Cannot find game {{ joinCode }}. Double check the code!",
            { joinCode: joinCode.toLocaleUpperCase() }
          ) + " 👀"
        )
        // navigation.navigate(RootRoutes.Pending, {
        //   joinCode: joinCode?.toLocaleUpperCase(),
        // })
      }
    } else {
      errored(
        t(
          "invalidJoinCode",
          "Cannot find game {{ joinCode }}. Double check the code!",
          { joinCode: joinCode.toLocaleUpperCase() }
        ) + " 👀"
      )
    }
    setLoading(false)
  }

  React.useEffect(() => {
    if (data) {
      handleCompleted(data)
    }
  }, [data])

  return (
    <SafeAreaView style={{ backgroundColor: "transparent" }}>
      <View style={{ marginLeft: grid(1) }}>
        <HeaderBackButton
          onPress={() => {
            if (navigation.canGoBack()) navigation.goBack()
          }}
          tintColor={theme.color.pastel_gray}
        />
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          // backgroundColor: theme.color.pastel_green,
        }}
      >
        {loading ? (
          <ActivityIndicator
            size="large"
            color={theme.color.pastel_blue}
            style={styles.indicator}
          />
        ) : (
          <>
            <TextInput
              placeholder={t("join.label", "4-letter code")}
              autoCorrect={false}
              spellCheck={false}
              keyboardType="visible-password"
              value={joinCode.toLocaleUpperCase()}
              onChangeText={(text) => setJoinCode(text.toLocaleUpperCase())}
              style={styles.input}
              placeholderTextColor={theme.color.text_primary}
              maxLength={4}
            />
            <Text style={styles.description}>
              {t("join.description", "Ask your host for the code!")}
            </Text>
          </>
        )}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  input: {
    ...theme.fonts.h1,
    width: "80%",
    height: 100,
    marginLeft: "10%",
    marginRight: "10%",
    marginBottom: "5%",
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 5,
    borderColor: theme.color.pastel_gray,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "700",
    color: theme.color.text_secondary,
  },
  description: {
    textAlign: "center",
    color: theme.color.text_secondary,
  },
  indicator: {
    transform: [{ scale: 2 }],
    justifyContent: "center",
    alignItems: "center",
  },
})
