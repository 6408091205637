import { StyleSheet, View } from "react-native"
import React from "react"
import { grid, theme } from "@common/theme"

export const Handlebar: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.handleBarView} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginTop: 8,
  },
  handleBarView: {
    backgroundColor: theme.color.stroke_border,
    borderRadius: 100,
    height: 4,
    position: "absolute",
    width: grid(2),
  },
})
