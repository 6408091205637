import React from "react"
import {
  Modal as RNModal,
  View,
  StyleSheet,
  ModalProps,
  Dimensions,
  Pressable,
  ViewStyle,
} from "react-native"
import { theme } from "@common/theme"

const { height, width } = Dimensions.get("window")

interface IProps {
  onMaskClick?: () => void
  modalStyle?: ViewStyle
}

export const Modal: React.FC<IProps & ModalProps> = ({
  children,
  onMaskClick,
  modalStyle,
  ...props
}) => {
  return (
    <RNModal {...props}>
      <Pressable
        style={styles.modalContainer}
        onPress={() => {
          onMaskClick?.()
        }}
      >
        <Pressable>
          <View style={[styles.modalView, modalStyle]}>{children}</View>
        </Pressable>
      </Pressable>
    </RNModal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    backgroundColor: theme.color.background_primary,
    borderRadius: 10,
    padding: 10,
    width: width * 0.8,
  },
})
