import { View, StyleSheet, TouchableOpacity, Dimensions } from "react-native"
import { CurrentAuthContext } from "@common/contexts/CurrentAuthService"
import { clientUuid } from "@common/contexts/CurrentPlayerService"
import { Games, useNewGameMutation, useQueryGameByIdQuery } from "@gql"
import React, { useContext, useState } from "react"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { GameRoutes, LobbyNavigationProp, RootRoutes } from "@common/routes"
import { useTranslation } from "react-i18next"
import { hexToRGB, theme, vertGrid } from "@common/theme"
import { MotiView } from "moti"
import { AngledButton } from "@common/components/Shapes/AngledButton"
import {
  Text,
  SafeAreaView,
  Logo,
  Modal,
  Button,
  flashMessage,
} from "@common/components"
import { usePlaySound } from "@common/hooks/usePlaySound"
import { useJoinGameInProgress } from "@common/utils/useJoinGameInProgress"
// import Land from "@common/images/land.svg"

const { height, width } = Dimensions.get("window")

enum PlayerState {
  Joining = 1,
  Hosting,
  Choosing,
}

export const Home: React.FC = () => {
  const { t } = useTranslation()
  const navigation = useNavigation<LobbyNavigationProp>()
  const currentAuth = useContext(CurrentAuthContext)
  const [gameId, setGameId] = useState<Games["id"] | null>(null)
  const {
    joinGameInProgress,
    setJoinGameInProgress,
    deleteJoinGameInProgress,
  } = useJoinGameInProgress()
  const [playerState, setPlayerState] = useState<PlayerState>(
    PlayerState.Choosing
  )
  const playClickSound = usePlaySound("click")
  const [newGame, { loading: newGameLoading, error: newGameError }] =
    useNewGameMutation()
  const { data: gameData } = useQueryGameByIdQuery({
    skip: gameId === null,
    variables: {
      id: gameId,
    },
  })

  React.useEffect(() => {
    if (newGameError) {
      flashMessage("Encountered an Error. Please try again later.", "error")
    }
  }, [newGameError])

  React.useEffect(() => {
    if (gameId && gameData) {
      const joinCode = gameData?.games_by_pk?.join_code
      if (joinCode) {
        navigation.navigate(RootRoutes.GameNavigator, {
          screen: GameRoutes.Lobby,
          joinCode: joinCode?.toLocaleUpperCase(),
          params: {
            joinCode: joinCode?.toLocaleUpperCase(),
          },
        })
      }
    }
  }, [gameId, gameData])

  useFocusEffect(
    React.useCallback(() => {
      setGameId(null)
      setPlayerState(PlayerState.Choosing)
      currentAuth.setJwtToken(null)
    }, [])
  )

  return (
    <>
      <Modal
        transparent={true}
        visible={!!joinGameInProgress}
        onMaskClick={() => {
          deleteJoinGameInProgress()
        }}
      >
        <Text
          variant="h3"
          style={{ textAlign: "center", paddingVertical: vertGrid(3) }}
        >
          Looks like you have a game in progress...
        </Text>
        <Button
          onPress={() => {
            navigation.navigate(RootRoutes.Join, {
              joinCode: joinGameInProgress || "",
            })
            setJoinGameInProgress(null)
          }}
        >
          Join Game with Code: {joinGameInProgress}
        </Button>
        <Text variant="footnote" style={{ textAlign: "center" }}>
          Click/Press outside to exit.
        </Text>
      </Modal>

      {/* <View
        style={{
          position: "absolute",
          width: "100%",
          height: "10%",
          bottom: -20,
        }}
      >
        <Land />
      </View> */}
      <SafeAreaView style={{ flex: 1, overflow: "hidden" }}>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo />
        </View>

        {[PlayerState.Choosing, PlayerState.Hosting].includes(playerState) && (
          <View style={{ flex: 1, justifyContent: "flex-end" }}>
            <TouchableOpacity
              disabled={newGameLoading || playerState === PlayerState.Hosting}
              onPress={async () => {
                playClickSound()
                const registration = await newGame({
                  variables: {
                    clientUuid: await clientUuid(),
                  },
                  context: {
                    headers: {
                      "X-Hasura-Role": "anonymous",
                    },
                  },
                })
                if (registration.data?.newGame) {
                  await currentAuth.setJwtToken(
                    registration.data.newGame.jwt_token
                  )
                  setGameId(registration.data?.newGame.id)
                  setPlayerState(PlayerState.Hosting)
                }
              }}
              style={{
                transform: height > width ? [{ rotate: "-15deg" }] : [],
              }}
            >
              <MotiView
                from={{
                  translateX: width,
                }}
                animate={{
                  translateX: 10,
                }}
                transition={{
                  type: "spring",
                  delay: 0,
                }}
              >
                <AngledButton
                  backgroundColor="transparent"
                  color={hexToRGB(theme.color.pastel_teal, "0.7")}
                >
                  <View style={styles.textContainer}>
                    <Text fontVariant="Bold" style={styles.buttonText}>
                      {t("home.hostGameButton", "New Game")}
                    </Text>
                  </View>
                </AngledButton>
              </MotiView>
            </TouchableOpacity>

            <TouchableOpacity
              disabled={newGameLoading || playerState === PlayerState.Hosting}
              onPress={() => {
                playClickSound()
                navigation.navigate(RootRoutes.Join)
              }}
              style={{
                transform: height > width ? [{ rotate: "-15deg" }] : [],
                marginTop: vertGrid(4),
                marginBottom:
                  height > width
                    ? (width / 2) * Math.tan(15 * (Math.PI / 180))
                    : vertGrid(4),
              }}
            >
              <MotiView
                from={{
                  translateX: width,
                }}
                animate={{
                  translateX: 10,
                }}
                transition={{
                  type: "spring",
                  delay: 700,
                }}
                style={{
                  flexDirection: "row",
                }}
              >
                <AngledButton
                  backgroundColor="transparent"
                  color={hexToRGB(theme.color.pastel_green, "0.7")}
                >
                  <View style={styles.textContainer}>
                    <Text fontVariant="Bold" style={styles.buttonText}>
                      {t("home.joinGameButton", "Join Game")}
                    </Text>
                  </View>
                </AngledButton>
              </MotiView>
            </TouchableOpacity>
          </View>
        )}
      </SafeAreaView>
    </>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    fontSize: 50,
    color: theme.color.text_primary,
  },
  textContainer: {
    width: width - 100,
  },
})
