/* eslint-disable sonarjs/no-nested-switch */
import { theme } from "@common/theme"
import React from "react"
import {
  GestureResponderEvent,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
  ActivityIndicator,
  TextStyle,
} from "react-native"
import { useTranslation } from "react-i18next"
import { Text, FontVariants, TextVariants } from "@common/components/Text/Text"
import { makeStyleArray } from "@common/utils/style"
import { usePlaySound } from "@common/hooks/usePlaySound"

export type ButtonVariants =
  | "primary"
  | "secondary"
  | "tertiary"
  | "outline"
  | "clear"

enum VariantType {
  View,
  Text,
}

interface IProps {
  disabled?: boolean
  block?: boolean
  doSound?: boolean
  onPress?: (event?: GestureResponderEvent) => void
  variant?: ButtonVariants
  textVariant?: TextVariants
  fontVariant?: FontVariants
  style?: ViewStyle | ViewStyle[]
  textStyle?: TextStyle | TextStyle[]
  loading?: boolean
}

export const Button: React.FC<IProps> = ({
  variant = "primary",
  block = false,
  doSound = true,
  fontVariant,
  textVariant,
  onPress,
  children,
  style,
  textStyle,
  loading,
  disabled,
  ...props
}) => {
  const { t } = useTranslation()
  const playClickSound = usePlaySound("click")

  const touchableOpacityVariant = (type: VariantType) => {
    switch (variant) {
      case "primary":
        switch (type) {
          case VariantType.View: {
            return {
              backgroundColor: theme.color.interactive_primary,
            }
          }
          case VariantType.Text: {
            return {
              color: theme.color.interactive_foreground_primary,
            }
          }
        }
      case "secondary":
        switch (type) {
          case VariantType.View: {
            return {
              backgroundColor: theme.color.interactive_secondary,
            }
          }
          case VariantType.Text: {
            return {
              color: theme.color.interactive_foreground_secondary,
            }
          }
        }
      case "tertiary":
        switch (type) {
          case VariantType.View: {
            return {
              backgroundColor: theme.color.interactive_tertiary,
            }
          }
          case VariantType.Text: {
            return {
              color: theme.color.interactive_foreground_secondary,
            }
          }
        }
      case "outline": {
        switch (type) {
          case VariantType.View: {
            return {
              backgroundColor: "transparent",
              borderWidth: 1,
              borderColor: theme.color.text_primary,
            }
          }
          case VariantType.Text: {
            return {
              color: theme.color.text_primary,
            }
          }
        }
      }
      case "clear":
        switch (type) {
          case VariantType.View: {
            return {
              backgroundColor: "transparent",
            }
          }
          case VariantType.Text: {
            return {
              color: theme.color.text_primary,
            }
          }
        }
    }
  }

  const handlePress = (e: GestureResponderEvent) => {
    if (doSound) {
      playClickSound()
    }
    onPress?.(e)
  }

  return (
    <TouchableOpacity
      {...props}
      accessibilityRole="button"
      onPress={handlePress}
      style={[
        styles.container,
        block ? styles.block : {},
        touchableOpacityVariant(VariantType.View),
        ...makeStyleArray(style),
        loading || disabled
          ? {
              backgroundColor: theme.color.interactive_disabled,
            }
          : {},
      ]}
      disabled={(disabled || loading) ?? false}
    >
      {loading && <ActivityIndicator style={styles.spinner} />}
      <Text
        testID="components:button_text"
        fontVariant={fontVariant}
        variant={textVariant}
        style={[
          styles.text,
          touchableOpacityVariant(VariantType.Text),
          block ? styles.blockText : {},
          loading ? { color: theme.color.interactive_foreground_muted } : {},
          ...makeStyleArray(textStyle),
        ]}
      >
        {loading ? t("loading...") : children}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  block: {
    width: "100%",
  },
  blockText: {
    width: "100%",
  },
  container: {
    alignItems: "center",
    backgroundColor: theme.color.interactive_primary,
    borderRadius: 10,
    flexDirection: "row",
    height: 64,
    justifyContent: "center",
    // overflow: "hidden",
    paddingHorizontal: 48,
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 20,
    shadowColor: "#000",
  },
  spinner: {
    marginRight: 8,
  },
  text: {
    color: theme.color.interactive_foreground_primary,
    textAlign: "center",
  },
})
