import React from "react"
import { StyleSheet } from "react-native"
import { NavigationContainer, DefaultTheme } from "@react-navigation/native"
import {
  createStackNavigator,
  CardStyleInterpolators,
  TransitionSpecs,
} from "@react-navigation/stack"
import { Home } from "@home/components/Home"
import { Join } from "@home/components/Join"
import { RootRoutes } from "@common/routes/RootRoutes"
import { GameNavigator } from "./GameNavigator"
import { theme } from "@common/theme"

const Stack = createStackNavigator()

export const RootNavigator = () => {
  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: "transparent",
      text: theme.color.text_primary,
    },
  }

  return (
    <>
      <NavigationContainer theme={MyTheme}>
        <Stack.Navigator
          initialRouteName="Home"
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen name="Home" component={Home} />
          <Stack.Screen
            name="Join"
            component={Join}
            options={{
              cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
              transitionSpec: {
                open: TransitionSpecs.FadeInFromBottomAndroidSpec,
                close: TransitionSpecs.FadeInFromBottomAndroidSpec,
              },
            }}
          />
          {/* <Stack.Screen name="Pending" component={Pending} /> */}
          <Stack.Screen
            name={RootRoutes.GameNavigator}
            component={GameNavigator}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </>
  )
}

const styles = StyleSheet.create({
  indicator: {
    transform: [{ scale: 2 }],
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
  },
})
