import React from "react"
import { ScrollView, View } from "react-native"
import ScreenCard from "@card-submission/components/ScreenCard"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import { filter, reject } from "lodash"
import { AssignTeamsButton } from "@card-submission/components/AssignTeamsButton"
import { useTranslation } from "react-i18next"
import { ReadyButton } from "./ReadyButton"
import { grid, theme, vertGrid } from "@common/theme"
import { Text } from "@common/components"

enum WaitingForSubmissionsState {
  Waiting,
  SubmittedAssign,
  SubmittedWait,
}

export function WaitingForSubmissions() {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)

  const numEntriesPerPlayer = currentGame.num_entries_per_player
  const numPlayers = currentGame.players.length

  if (!numEntriesPerPlayer || !numPlayers) {
    return null
  }

  const total = numEntriesPerPlayer * numPlayers
  const submittedOrAcceptedSoFar = currentGame.cards.length

  const waitingForPlayers = reject(currentGame.players, (player) => {
    return (
      filter(currentGame.cards, (card) => card.player_id === player.id)
        .length === numEntriesPerPlayer
    )
  })

  if (!submittedOrAcceptedSoFar) {
    return null
  }

  let state: WaitingForSubmissionsState

  if (total === 0 || submittedOrAcceptedSoFar !== total) {
    state = WaitingForSubmissionsState.Waiting
  } else if (PlayerRole.Host === currentPlayer.role) {
    state = WaitingForSubmissionsState.SubmittedAssign
  } else {
    state = WaitingForSubmissionsState.SubmittedWait
  }

  const unscreenedCards =
    PlayerRole.Host === currentPlayer.role && currentGame.screen_cards
      ? currentGame.cards.filter(
          (card) =>
            card.is_allowed === null && card.player_id !== currentPlayer.id
        )
      : []

  return (
    <>
      <Text
        variant="h1"
        fontVariant="LuckiestGuy"
        style={{ textAlign: "center", color: theme.color.text_secondary }}
      >
        {t("cardSubmission.waiting.title", "You're done!")}
      </Text>
      {
        {
          [WaitingForSubmissionsState.Waiting]: (
            <View
              style={{
                flex: 1,
                width: "100%",
              }}
            >
              {/* <Text style={{ textAlign: "center" }}>
                {t(
                  "cardSubmission.waiting.description",
                  "Just waiting for everyone else..."
                )}
              </Text> */}
              <Text style={{ ...theme.fonts.h5, textAlign: "center" }}>
                {t(
                  "cardSubmission.waiting.progress",
                  "{{ progress }} people are ready",
                  {
                    progress: `${
                      currentGame.players.length - waitingForPlayers.length
                    }/${currentGame.players.length}`,
                  }
                )}
              </Text>
              <ScrollView style={{ flex: 1, marginTop: vertGrid(4) }}>
                {currentGame.players.map((player, index) => (
                  <View key={player.id} style={{ marginVertical: vertGrid(1) }}>
                    <ReadyButton
                      fromRight={index % 2 == 0}
                      isReady={
                        filter(
                          currentGame.cards,
                          (card) => card.player_id === player.id
                        ).length === numEntriesPerPlayer
                      }
                      username={player.username || ""}
                    />
                  </View>
                ))}
              </ScrollView>
            </View>
          ),
          [WaitingForSubmissionsState.SubmittedAssign]: (
            <View style={{ marginHorizontal: grid(2) }}>
              <Text
                style={{
                  textAlign: "center",
                  color: theme.color.text_secondary,
                }}
              >
                {t(
                  "cardSubmission.waiting.submitted.host",
                  "All players submitted! As the host, you can now assign teams."
                )}
              </Text>
            </View>
          ),
          [WaitingForSubmissionsState.SubmittedWait]: (
            <View style={{ marginHorizontal: grid(2) }}>
              <Text
                style={{
                  textAlign: "center",
                  color: theme.color.text_secondary,
                }}
              >
                {t(
                  "cardSubmission.waiting.submitted.player",
                  "All players submitted {{ cardCount }} cards in total. Now we are waiting on the host to start the game!",
                  { cardCount: submittedOrAcceptedSoFar }
                )}
              </Text>
            </View>
          ),
        }[state]
      }

      {PlayerRole.Host === currentPlayer.role && currentGame.screen_cards && (
        <ScrollView
          style={{
            flex: 1,
            width: "100%",
          }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          {unscreenedCards.map((card, index) => (
            <ScreenCard key={index} card={card} />
          ))}
        </ScrollView>
      )}

      {WaitingForSubmissionsState.SubmittedAssign === state ? (
        <View style={{ marginTop: 30, paddingHorizontal: grid(2) }}>
          <AssignTeamsButton />
        </View>
      ) : !unscreenedCards.length ? (
        <View style={{ marginTop: 50 }}>
          {/* <Text>TODO: logo goes here instead of text</Text> */}
        </View>
      ) : null}
    </>
  )
}
