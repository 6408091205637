import React from "react"
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
} from "react-native"
import { theme } from "@common/theme"
import { Controller, UseFormReturn } from "react-hook-form"
import { AntDesign } from "@expo/vector-icons"
import { SettingsForm } from "@lobby/forms/SettingsForm"

interface IProps {
  methods: UseFormReturn<SettingsForm>
  name: "num_entries_per_player" | "seconds_per_turn"
  editable: boolean
  placeholder?: string
  min?: number
  max?: number
  step?: number
}

export const SettingsNumericInput: React.FC<IProps> = ({
  methods,
  name,
  editable,
  placeholder,
  min,
  max,
  step = 1,
}) => {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = methods

  const getNumberFromString = (string: string) => {
    return parseInt(string.replace(/[^0-9]/g, "")) || 0
  }

  const handleChange = (
    value: string | number,
    onChange: (value: string) => void
  ) => {
    const numOnly =
      typeof value === "string" ? getNumberFromString(value) : value

    if (min && numOnly < min) {
      setError(name, {
        type: "min",
        message: `Must be at least ${min}!`,
      })
    } else if (max && numOnly > max) {
      setError(name, {
        type: "max",
        message: `Should be less than ${max} cards per person.`,
      })
    } else {
      clearErrors(name)
      const numString = numOnly.toString()
      onChange(numString)
    }
  }

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.numericContainer}>
            <TouchableOpacity
              style={styles.sideButton}
              onPress={() => {
                handleChange(getNumberFromString(value) - step, onChange)
              }}
              disabled={!editable}
            >
              <View style={styles.sideButtonContainer}>
                <AntDesign name="minus" size={20} />
              </View>
            </TouchableOpacity>
            <TextInput
              keyboardType="numeric"
              placeholder={placeholder}
              style={[
                styles.input,
                {
                  flex: 1,
                  borderRadius: 0,
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                },
              ]}
              value={value}
              editable={editable}
              onChangeText={(value) => {
                handleChange(value, onChange)
              }}
            />
            <TouchableOpacity
              style={styles.sideButton}
              onPress={() => {
                handleChange(getNumberFromString(value) + step, onChange)
              }}
              disabled={!editable}
            >
              <View style={styles.sideButtonContainer}>
                <AntDesign name="plus" size={20} />
              </View>
            </TouchableOpacity>
          </View>
        )}
        name={name}
      />

      {errors[name] && <Text>{errors[name]?.message}</Text>}
    </>
  )
}

const styles = StyleSheet.create({
  numericContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
    borderRadius: 10,
  },
  sideButton: {
    height: "100%",
    width: "15%",
  },
  sideButtonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "100%",
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
    fontSize: 20,
    textAlign: "center",
  },
})
