import React from "react"
import { View, ScrollView, StyleSheet } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import { useSubmitCardsMutation } from "@gql"
import { isEmpty, filter, startCase } from "lodash"
import { SubmissionCard } from "../components/SubmissionCard"
import { useTranslation } from "react-i18next"
import { Text, Button } from "@common/components"
import { grid, theme } from "@common/theme"
import { useForm } from "react-hook-form"

export function SubmissionForm(props: { onSubmit: () => void }) {
  const { t } = useTranslation()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const [submitCards, { called }] = useSubmitCardsMutation()

  const numSubmitted = filter(
    currentGame.cards,
    (card) => card.player_id === currentPlayer.id
  ).length

  const numToSubmit =
    (currentGame.num_entries_per_player &&
      currentGame.num_entries_per_player - numSubmitted) ||
    0

  const methods = useForm()

  let areEmptyWords = Object.values(methods.getValues()).some((word) => {
    if (!word) return true
    return word.length < 1
  })

  const submissionCards = []
  for (var i = 0; i < numToSubmit; i++) {
    submissionCards.push(
      <View key={i}>
        <SubmissionCard
          methods={methods}
          index={i}
          totalWordCount={numToSubmit}
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ padding: grid(1) }}>
        <Text variant="h1" fontVariant="LuckiestGuy" style={styles.title}>
          {t("cardSubmission.title", "Submit {{ count }} words", {
            count: numToSubmit,
            defaultValue_plural: "Submit {{ count }} words",
          })}
        </Text>

        <Text style={styles.description}>
          {t(
            "cardSubmission.description",
            'These cards will be put into the "fishbowl," and drawn randomly in rounds of {{ rounds }}. They can be words, familiar phrases, or inside jokes!',
            {
              rounds: currentGame.rounds
                .map((round) => startCase(round.value))
                .join(", "),
            }
          )}
        </Text>

        {!!currentGame.starting_letter && (
          <Text>
            {t(
              "cardSubmission.descriptionLetter",
              "They must start with the letter {{letter}}",
              {
                letter: currentGame.starting_letter.toLocaleUpperCase(),
              }
            )}
          </Text>
        )}
      </View>

      <ScrollView style={{ flex: 1 }}>{submissionCards}</ScrollView>

      <View style={{ padding: grid(1) }}>
        <Button
          textVariant="h2"
          disabled={
            called || areEmptyWords || !isEmpty(methods.formState.errors)
          }
          onPress={methods.handleSubmit(async (data) => {
            await submitCards({
              variables: {
                cards: Object.values(data).map((word) => {
                  return {
                    player_id: currentPlayer.id,
                    game_id: currentGame.id,
                    word: word,
                  }
                }),
              },
            })
            props.onSubmit()
          })}
        >
          {t("cardSubmission.submitButton", "Submit")}
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    ...theme.fonts.h2,
    textAlign: "center",
    color: theme.color.text_primary,
  },
  description: {
    color: theme.color.text_primary,
  },
})
