import React from "react"
import { Controller, UseFormReturn } from "react-hook-form"
import { SettingsForm, SettingsFormNames } from "../forms/SettingsForm"
import { Switch } from "react-native-elements"
import { Text } from "@common/components"

interface IProps {
  methods: UseFormReturn<SettingsForm>
  name: "screen_cards" | "allow_card_skips"
  editable: boolean
  placeholder?: string
}

export const SettingsSwitchInput: React.FC<IProps> = ({
  methods,
  name,
  editable,
  placeholder,
}) => {
  const {
    control,
    formState: { errors },
  } = methods

  return (
    <>
      <Text>
        {editable && (
          <Controller
            control={control}
            rules={{
              maxLength: 1,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Switch
                value={value}
                // disabled={!canConfigureSettings || props.disabled}
                onValueChange={onChange}
              />
            )}
            name={name}
          />
        )}
        {placeholder}
      </Text>
      {errors[name] && <Text>{errors[name]?.message}</Text>}
    </>
  )
}
