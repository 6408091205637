import React from "react"
import { Dimensions, Image, StyleSheet } from "react-native"
const { height } = Dimensions.get("window")
const logoHeight = height * 0.5
const logoWidth = (logoHeight * 6) / 5

export const Logo = ({}) => {
  return <Image style={styles.logo} source={require("@assets/logo.png")} />
}

const styles = StyleSheet.create({
  logo: {
    height: logoHeight,
    width: logoWidth,
  },
})
