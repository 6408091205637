import { Winner } from "./types"
import { PodiumStep } from "./PodiumStep"
import { View } from "react-native"
import { theme, vertGrid } from "@common/theme"

interface Props {
  readonly winners: Winner[]
}

export function Podium({ winners }: Props) {
  const podium = [8, 6, 4, 2, 0, 1, 3, 5, 7, 9]
    .reduce(
      (podiumOrder, position) => [...podiumOrder, winners[position]],
      [] as readonly Winner[]
    )
    .filter(Boolean)

  const highestScore = winners[0].score

  return (
    <View
      style={{
        height: 250,
        flexDirection: "row",
        alignItems: "flex-end",
      }}
    >
      {podium.map((winner, index) => (
        <PodiumStep
          key={winner.id}
          podium={podium}
          winner={winner}
          highestScore={highestScore}
          index={index}
        />
      ))}
    </View>
  )
}
