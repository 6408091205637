import React from "react"
import { Dimensions } from "react-native"
import { AngledButton } from "@common/components/Shapes/AngledButton"
import { MotiView, useAnimationState } from "moti"
import { theme } from "@common/theme"

const { width } = Dimensions.get("window")

interface IProps {
  isReady: boolean
  username: string
  fromRight: boolean
}

export const ReadyButton: React.FC<IProps> = ({
  isReady,
  username,
  fromRight = true,
}) => {
  const readyUp = useAnimationState({
    from: {
      translateX: fromRight ? 50 : -50,
    },
    to: {
      translateX: fromRight ? width / 2 - 50 : -width / 2 + 50,
    },
  })

  React.useEffect(() => {
    if (isReady) {
      readyUp.transitionTo((currentState) => {
        if (currentState === "from") {
          return "to"
        }
        return "from"
      })
    }
  }, [isReady])

  return (
    <MotiView
      state={readyUp}
      style={{
        flexDirection: "row",
        justifyContent: fromRight ? "flex-start" : "flex-end",
      }}
    >
      <AngledButton
        fromRight={fromRight}
        start={null}
        end={null}
        backgroundColor="transparent"
        color={isReady ? theme.color.pastel_green : theme.color.pastel_gray}
        colors={isReady ? ["lawngreen", "green"] : ["red", "darkred"]}
        blockStyle={{
          width: isReady ? width - 150 : 150,
        }}
        textStyle={{
          fontSize: 50,
          color: isReady
            ? theme.color.text_primary
            : theme.color.text_secondary,
        }}
      >
        {username}
        {/* {isReady ? " is ready" : ""} */}
      </AngledButton>
    </MotiView>
  )
}
