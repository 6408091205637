import React from "react"
import { View, StyleSheet, ScrollView } from "react-native"
import { Button as RNEButton } from "react-native-elements"
import { PlayerArena } from "@common/components/PlayerArena"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import {
  GameStateEnum,
  TeamColorsEnum,
  useUpdateAllPlayersMutation,
  useUpdateGameStateMutation,
} from "@gql"
import {
  currentPlayerTeam,
  TeamColor,
  teamsWithSequence,
} from "@common/utils/team"
import { filter } from "lodash"
import { Trans, useTranslation } from "react-i18next"
import { grid, theme, vertGrid } from "@common/theme"
import { Text, Button, Layout } from "@common/components"
import { Divider } from "react-native-elements/dist/divider/Divider"

export function TeamAssignment() {
  const { t } = useTranslation()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const [updateGameState] = useUpdateGameStateMutation()
  const [updateAllPlayers] = useUpdateAllPlayersMutation()

  const myTeamColor = currentPlayerTeam(
    currentPlayer.id,
    currentGame.players
  ).team_color
  const myTeamPlayers = filter(
    currentGame.players,
    (player) => player.team?.team_color === myTeamColor
  )
  const otherTeamColor =
    currentPlayerTeam(currentPlayer.id, currentGame.players).team_color ===
    TeamColorsEnum.Blue
      ? TeamColorsEnum.Red
      : TeamColorsEnum.Blue
  const otherTeamPlayers = filter(
    currentGame.players,
    (player) => player.team?.team_color === otherTeamColor
  )

  return (
    <View style={styles.teamAssignmentContainer}>
      <Layout
        headerColor={theme.color.pastel_teal}
        headerDividerColor={theme.color.pastel_green}
        header={
          <Text fontVariant="LuckiestGuy" style={styles.title}>
            {t("teams.title", "Teams")}
          </Text>
        }
        footerDividerColor={theme.color.pastel_green}
        footerColor={theme.color.pastel_teal}
        footer={
          <>
            {currentPlayer.role === PlayerRole.Participant && (
              <View style={styles.bottomText}>
                <Text>
                  {t(
                    "teams.description.player",
                    "Don't like the teams? Your host can re-randomize them or switch players from one team to another."
                  )}
                </Text>
              </View>
            )}

            {currentPlayer.role === PlayerRole.Host && (
              <>
                <View>
                  <Text style={styles.description}>
                    <Trans t={t} i18nKey="teams.description.host">
                      {"Don't like the teams? "}
                    </Trans>
                  </Text>
                  <View style={{ alignItems: "center" }}>
                    <RNEButton
                      type="clear"
                      title="Re-randomize them!"
                      onPress={() => {
                        const players = teamsWithSequence(
                          currentGame.players,
                          currentGame.teams
                        )
                        updateAllPlayers({
                          variables: {
                            gameId: currentGame.id,
                            players: players.map(
                              ({ id, team_id, team_sequence }) => ({
                                id,
                                team_id,
                                team_sequence,
                              })
                            ),
                          },
                        })
                      }}
                    />
                  </View>

                  <Text style={styles.description}>
                    {"Or press a player to switch from one team to another."}
                  </Text>
                </View>
                <View style={styles.buttonContainer}>
                  <Button
                    block
                    onPress={async () => {
                      updateGameState({
                        variables: {
                          id: currentGame.id,
                          state: GameStateEnum.TeamNameCreation,
                        },
                      })
                    }}
                    textStyle={styles.buttonText}
                  >
                    {t("teams.startGameButton", "Create Team Names")}
                  </Button>
                </View>
              </>
            )}
          </>
        }
      >
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ alignItems: "center" }}
        >
          <View style={styles.bubble}>
            <View>
              <Text
                fontVariant="LuckiestGuy"
                style={[styles.subTitle, { color: TeamColor[myTeamColor] }]}
              >
                {t(
                  `teams.teamName.${myTeamColor}`,
                  `Team ${myTeamColor}`
                ).toLocaleUpperCase()}
              </Text>
              <Text
                style={{
                  ...theme.fonts.h6,
                  textAlign: "center",
                  fontStyle: "italic",
                }}
              >
                {t("teams.teamName.yourSubtitle", " (your team)")}
              </Text>
            </View>

            <PlayerArena
              players={myTeamPlayers}
              hostCanSwitchTeams={currentPlayer.role === PlayerRole.Host}
            />
          </View>

          <View style={{ marginVertical: vertGrid(4) }}>
            <Text
              variant="h2"
              fontVariant="LuckiestGuy"
              style={{ textAlign: "center", color: "white" }}
            >
              - {t("teams.versusDivider", "vs")} -
            </Text>
          </View>

          <View style={styles.bubble}>
            <Text
              fontVariant="LuckiestGuy"
              style={[styles.subTitle, { color: TeamColor[otherTeamColor] }]}
            >
              {t(
                `teams.teamName.${otherTeamColor}`,
                `Team ${otherTeamColor}`
              ).toLocaleUpperCase()}
            </Text>
            <PlayerArena
              players={otherTeamPlayers}
              hostCanSwitchTeams={currentPlayer.role === PlayerRole.Host}
            />
          </View>
        </ScrollView>
      </Layout>
    </View>
  )
}

const styles = StyleSheet.create({
  bubble: {
    backgroundColor: theme.color.background_primary,
    borderRadius: 10,
    marginVertical: vertGrid(2),
    paddingVertical: vertGrid(2),
    minWidth: "50%",
    // alignSelf: "center",
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    ...theme.fonts.h1,
    textAlign: "center",
    margin: 10,
  },
  subTitle: {
    ...theme.fonts.h2,
    textAlign: "center",
  },
  description: {
    ...theme.fonts.h3,
    textAlign: "center",
  },
  buttonContainer: {
    marginVertical: vertGrid(2),
    marginHorizontal: grid(2),
  },
  buttonText: {
    ...theme.fonts.h2,
  },
  bottomText: {
    margin: 10,
  },
  teamAssignmentContainer: {
    flex: 1,
    // backgroundColor: theme.color.pastel_teal,
  },
})
