import { ColorValue, View } from "react-native"
import { PlayerChip } from "@common/components/PlayerChip"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import {
  TeamColorsEnum,
  useRemovePlayerMutation,
  useUpdateAllPlayersMutation,
} from "@gql"
import {
  Players,
  Player,
  teamsWithSequenceWithUpdate,
  getTeamIdByColor,
} from "@common/utils/team"
import React from "react"
import { useTranslation } from "react-i18next"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { grid, theme, vertGrid } from "@common/theme"

export function PlayerArena(props: {
  players: Players
  hostCanRemovePlayer?: boolean
  hostCanSwitchTeams?: boolean
  textColor?: ColorValue
}) {
  const { t } = useTranslation()
  const { showActionSheetWithOptions } = useActionSheet()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const [removePlayer] = useRemovePlayerMutation()
  const [updateAllPlayers] = useUpdateAllPlayersMutation()

  return (
    //waspaper
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.players.map((player: Player) => {
        return (
          player.username && (
            <View
              style={{ marginHorizontal: grid(1), marginVertical: vertGrid(1) }}
              key={player.id}
            >
              <PlayerChip
                handleSwitch={
                  props.hostCanSwitchTeams
                    ? () => {
                        const updatedPlayer = currentGame.players.find(
                          (p) => p.id === player.id
                        )
                        if (updatedPlayer) {
                          const teamIds = getTeamIdByColor(currentGame.teams)

                          updatedPlayer.team_id =
                            updatedPlayer?.team?.team_color ===
                            TeamColorsEnum.Red
                              ? teamIds[TeamColorsEnum.Blue]
                              : teamIds[TeamColorsEnum.Red]
                          const players = teamsWithSequenceWithUpdate(
                            currentGame.players,
                            updatedPlayer,
                            teamIds
                          )
                          updateAllPlayers({
                            variables: {
                              gameId: currentGame.id,
                              players: players.map(
                                ({ id, team_id, team_sequence }) => ({
                                  id,
                                  team_id,
                                  team_sequence,
                                })
                              ),
                            },
                          })
                        }
                      }
                    : undefined
                }
                key={player.id}
                username={player.username}
                teamColor={player.team?.team_color}
                textColor={props.textColor || theme.color.text_primary}
                handleDelete={
                  props.hostCanRemovePlayer && player.id !== currentPlayer.id
                    ? () => {
                        const options = ["Delete", "Cancel"]
                        const destructiveButtonIndex = 0
                        const cancelButtonIndex = 1
                        showActionSheetWithOptions(
                          {
                            options,
                            cancelButtonIndex,
                            destructiveButtonIndex,
                          },
                          (buttonIndex) => {
                            if (buttonIndex == destructiveButtonIndex) {
                              removePlayer({
                                variables: {
                                  id: player.id,
                                },
                              })
                            }
                          }
                        )
                      }
                    : undefined
                }
              />
            </View>
          )
        )
      })}
    </View>
  )
}
