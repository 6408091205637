import React from "react"
import {
  View,
  Dimensions,
  StyleSheet,
  ViewStyle,
  TextStyle,
} from "react-native"
import { theme } from "@common/theme"
import { LinearGradient, LinearGradientPoint } from "expo-linear-gradient"
import { TriangleCorner } from "./TriangleCorner"
import { Text } from "@common/components"
import { makeStyleArray } from "@common/utils/style"

const { width } = Dimensions.get("window")

export interface IProps {
  containerStyle?: ViewStyle
  blockStyle?: ViewStyle
  textStyle?: TextStyle | TextStyle[]
  fromRight?: boolean
  color?: string
  colors?: string[]
  backgroundColor?: string
  start?: LinearGradientPoint | null
  end?: LinearGradientPoint | null
}

export const AngledButton: React.FC<IProps> = ({
  containerStyle = {},
  blockStyle = {},
  textStyle = {},
  fromRight = true,
  color = "red",
  colors = ["#00FFFF", "#17C8FF", "#329BFF", "#4C64FF", "#6536FF", "#8000FF"],
  backgroundColor = theme.color.background_color,
  start = { x: 0.0, y: 1.0 },
  end = { x: 1.0, y: 1.0 },
  children,
}) => {
  if (backgroundColor === "transparent") {
    return (
      <View
        style={[
          fromRight ? styles.triangleRight : styles.triangleLeft,
          { borderBottomColor: color },
          containerStyle,
        ]}
      >
        {/* View is a hack to get styling working on both web and ios */}
        <View
          style={[
            {
              top: 50,
              height: 100,
              backgroundColor: "transparent",
              justifyContent: "center",
              alignSelf: fromRight ? "flex-start" : "flex-end",
            },
            blockStyle,
          ]}
        >
          <Text
            numberOfLines={1}
            adjustsFontSizeToFit
            style={[
              {
                textAlign: fromRight ? "left" : "right",
                color: theme.color.text_secondary,
              },
              ...makeStyleArray(textStyle),
            ]}
          >
            {children}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <LinearGradient
      style={[
        styles.containerStyle,
        { justifyContent: fromRight ? "flex-start" : "flex-end" },
        containerStyle,
      ]}
      colors={colors}
      start={start}
      end={end}
    >
      {fromRight && (
        <TriangleCorner style={{ borderTopColor: backgroundColor }} />
      )}
      <View style={[styles.block, blockStyle]}>
        <Text
          numberOfLines={1}
          adjustsFontSizeToFit
          allowFontScaling
          style={[
            {
              textAlign: fromRight ? "left" : "right",
              color: "#fff",
            },
            ...makeStyleArray(textStyle),
          ]}
        >
          {children}
        </Text>
      </View>
      {!fromRight && (
        <TriangleCorner
          style={{
            transform: [{ rotate: "90deg" }],
            borderTopColor: backgroundColor,
          }}
        />
      )}
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: "row",
    width: width * 2,
  },
  block: {
    justifyContent: "center",
  },
  triangleLeft: {
    width: width * 2,
    height: 100,
    justifyContent: "center",
    borderStyle: "solid",
    borderBottomWidth: 100,
    borderRightWidth: 100,
    borderRightColor: "transparent",
  },
  triangleRight: {
    width: width * 2,
    height: 100,
    justifyContent: "center",
    borderStyle: "solid",
    borderBottomWidth: 100,
    borderLeftWidth: 100,
    borderLeftColor: "transparent",
  },
})
