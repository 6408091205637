export const positions: Record<number, string> = {
    0: '1st',
    1: '2nd',
    2: '3rd',
    3: '4th',
    4: '5th',
    5: '6th',
    6: '7th',
    7: '8th',
    8: '9th',
    9: '10th'
  }
  
  export const prizes: readonly string[] = [
    '$1,000,000',
    '$500,000',
    '$250,000',
    '$125,000',
    '$100,000'
  ]
  
  export const colors: readonly string[] = [
    'blue',
    'yellow',
    'gray',
    'green',
    'red'
  ]
  