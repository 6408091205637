import React from "react"
import { ScrollView } from "react-native"
import { LinearGradient } from "expo-linear-gradient"
import { useBubbles } from "@common/hooks/useBubbles"
import { theme } from "@common/theme"

type BubbleComponentProps = {
  showGradient?: boolean
}

export const Bubbles: React.FC<BubbleComponentProps> = ({
  showGradient = true,
  children,
  ...props
}) => {
  const bubbles = useBubbles()

  return (
    <LinearGradient
      colors={[
        theme.color.pastel_teal,
        theme.color.pastel_blue,
        theme.color.pastel_gray,
      ]}
      style={{ height: "100%", width: "100%" }}
    >
      {bubbles}
      <ScrollView
        contentContainerStyle={{ height: "100%" }}
        alwaysBounceVertical={false}
        alwaysBounceHorizontal={false}
      >
        {children}
      </ScrollView>
    </LinearGradient>
  )
}
