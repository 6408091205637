import React from "react"
import { Platform } from "react-native"
import { GameStateRedirects } from "@common/components/GameStateRedirects"
import { Lobby } from "@lobby/components/Lobby"
import { CurrentPlayerProvider } from "@common/contexts/CurrentPlayerService"
import { CurrentGameProvider } from "@common/contexts/CurrentGameService"
import { GameRoutes, RootNavigatorParams, RootRoutes } from "@common/routes"
import { SettingsSection } from "@lobby/components/SettingsSection"
import { CardSubmission } from "@card-submission/components/CardSubmission"
import { TeamAssignment } from "@team-assignment/components/TeamAssignment"
import { TeamNameCreation } from "@team-name-creation/components/TeamNameCreation"
import { Play } from "@play/components/Play"
import { EndGame } from "@end-game/components/EndGame"
import {
  activateKeepAwake,
  deactivateKeepAwake,
  useKeepAwake,
} from "expo-keep-awake"
import { RouteProp } from "@react-navigation/native"
import { GestureDirection } from "@react-navigation/stack/lib/typescript/src/types"
import {
  createStackNavigator,
  StackCardInterpolationProps,
  CardStyleInterpolators,
  TransitionSpecs,
} from "@react-navigation/stack"
import { drawerInterpolator } from "@common/utils/drawerInterpolator"
import { setGameInProgress } from "@common/utils/useJoinGameInProgress"
import { LoadingScreen } from "@common/components/LoadingScreen"

const bottomDrawerNavigationOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  gestureDirection: "vertical" as GestureDirection,
  gestureEnabled: true,
  cardStyleInterpolator: (props: StackCardInterpolationProps) =>
    drawerInterpolator({
      ...props,
      baseIndex: 0,
    }),
  cardStyle: {
    flex: 1,
    backgroundColor: "transparent",
  },
}

const GameStack = createStackNavigator()

interface IProps {
  route: RouteProp<RootNavigatorParams, RootRoutes.GameNavigator>
}

export const GameNavigator: React.FC<IProps> = ({ route }) => {
  Platform.OS !== "web" && useKeepAwake()
  const joinCode = route.params.joinCode

  React.useEffect(() => {
    setGameInProgress(joinCode)
    Platform.OS !== "web" && activateKeepAwake()

    return () => {
      Platform.OS !== "web" && deactivateKeepAwake()
    }
  }, [])

  return (
    <CurrentPlayerProvider joinCode={joinCode}>
      <CurrentGameProvider joinCode={joinCode}>
        <GameStateRedirects hideLoading joinCode={joinCode} />
        <GameStack.Navigator initialRouteName={GameRoutes.Loading}>
          <GameStack.Screen
            name={GameRoutes.Loading}
            component={LoadingScreen}
            options={{ headerShown: false }}
          />
          <GameStack.Screen
            name={GameRoutes.Lobby}
            component={Lobby}
            options={{ headerShown: false }}
          />
          <GameStack.Screen
            name={GameRoutes.Settings}
            component={SettingsSection}
            options={{
              headerShown: false,
              cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
              transitionSpec: {
                open: TransitionSpecs.FadeInFromBottomAndroidSpec,
                close: TransitionSpecs.FadeInFromBottomAndroidSpec,
              },
            }}
          />
          <GameStack.Screen
            name={GameRoutes.CardSubmission}
            component={CardSubmission}
            options={{ headerShown: false }}
          />
          <GameStack.Screen
            name={GameRoutes.TeamAssignment}
            component={TeamAssignment}
            options={{ headerShown: false }}
          />
          <GameStack.Screen
            name={GameRoutes.TeamNameCreation}
            component={TeamNameCreation}
            options={{ headerShown: false }}
          />
          <GameStack.Screen
            name={GameRoutes.ActivePlay}
            component={Play}
            options={{ headerShown: false }}
          />
          <GameStack.Screen
            name={GameRoutes.Ended}
            component={EndGame}
            options={{ headerShown: false }}
          />
        </GameStack.Navigator>
      </CurrentGameProvider>
    </CurrentPlayerProvider>
  )
}
