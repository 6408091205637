import React from "react"
import { Text as RNText, TextStyle, StyleSheet } from "react-native"
import { theme } from "@common/theme"
import { makeStyleArray } from "@common/utils/style"

export type TextVariants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "caption"
  | "inactive"
  | "footnote"
  | "black"

export type FontVariants =
  | "Roboto"
  | "Bold"
  | "LuckiestGuy"
  | "ComicNeue"
  | "ComicNeueBold"

interface IProps {
  variant?: TextVariants
  fontVariant?: FontVariants
  style?: TextStyle | TextStyle[]
  [x: string]: unknown
}

export const Text: React.FC<IProps> = ({
  variant,
  fontVariant,
  style,
  ...props
}) => {
  const getTextVariant = () => {
    switch (variant) {
      case "h1":
        return theme.fonts.h1
      case "h2":
        return theme.fonts.h2
      case "h3":
        return theme.fonts.h3
      case "h4":
        return theme.fonts.h4
      case "h5":
        return theme.fonts.h5
      case "h6":
        return theme.fonts.h6
      case "p":
        return theme.fonts.p
      case "caption":
        return theme.fonts.caption
      case "inactive":
        return theme.fonts.inactive
      case "footnote":
        return theme.fonts.footnote
      case "black":
        return { color: "#000" }
      default:
        return {}
    }
  }

  const getFontVariants = () => {
    let fontFamily
    switch (fontVariant) {
      case "Roboto":
        fontFamily = "Roboto_400Regular"
        break
      case "Bold":
        fontFamily = "Roboto_700Bold"
        break
      case "LuckiestGuy":
        fontFamily = "LuckiestGuy_400Regular"
        break
      case "ComicNeue":
        fontFamily = "ComicNeue_400Regular"
        break
      case "ComicNeueBold":
        fontFamily = "ComicNeue_700Bold"
        break
      default:
        fontFamily = "Roboto_400Regular"
        break
    }

    return {
      fontFamily,
    }
  }

  return (
    <RNText
      {...props}
      selectable={false}
      style={[
        styles.defaultStyle,
        getTextVariant(),
        getFontVariants(),
        ...makeStyleArray(style),
      ]}
    >
      {props.children}
    </RNText>
  )
}

const styles = StyleSheet.create({
  defaultStyle: {
    color: theme.color.text_primary,
  },
})
