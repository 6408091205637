import React from "react"
import { Text } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import { filter } from "lodash"
import { SubmissionForm } from "@card-submission/inputs"
import { WaitingForSubmissions } from "@card-submission/components/WaitingForSubmissions"
import { SafeAreaView, KeyboardAvoidingView } from "@common/components"

export function Title(props: { text: string }) {
  return <Text>{props.text}</Text>
}

enum CardSubmissionState {
  Submitting = 1,
  Waiting,
}

export function CardSubmission() {
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)

  const numSubmitted = filter(
    currentGame.cards,
    (card) => card.player_id === currentPlayer.id
  ).length

  const [cardSubmissionState, setCardSubmissionState] =
    React.useState<CardSubmissionState>(
      numSubmitted === currentGame.num_entries_per_player
        ? CardSubmissionState.Waiting
        : CardSubmissionState.Submitting
    )

  React.useEffect(() => {
    if (
      currentGame.num_entries_per_player &&
      numSubmitted < currentGame.num_entries_per_player
    ) {
      setCardSubmissionState(CardSubmissionState.Submitting)
    }
  }, [numSubmitted])

  return (
    <SafeAreaView
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {cardSubmissionState === CardSubmissionState.Submitting ? (
        <KeyboardAvoidingView>
          <SubmissionForm
            onSubmit={() => setCardSubmissionState(CardSubmissionState.Waiting)}
          />
        </KeyboardAvoidingView>
      ) : (
        <WaitingForSubmissions />
      )}
    </SafeAreaView>
  )
}
