import React from "react"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  GameStateEnum,
  useUpdateAllPlayersMutation,
  useUpdateGameStateMutation,
} from "@gql"
import { teamsWithSequence } from "@common/utils/team"
import { useTranslation } from "react-i18next"
import { Button } from "@common/components/Button/Button"

export function AssignTeamsButton() {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const [updateGameState] = useUpdateGameStateMutation()
  const [updateAllPlayers] = useUpdateAllPlayersMutation()

  return (
    <Button
      block
      textVariant="h2"
      onPress={async () => {
        const players = teamsWithSequence(
          currentGame.players,
          currentGame.teams
        )
        await updateAllPlayers({
          variables: {
            gameId: currentGame.id,
            players: players.map(({ id, team_id, team_sequence }) => ({
              id,
              team_id,
              team_sequence,
            })),
          },
        })
        updateGameState({
          variables: {
            id: currentGame.id,
            state: GameStateEnum.TeamAssignment,
          },
        })
      }}
    >
      {t("cardSubmission.assignTeamsButton", "Assign Teams")}
    </Button>
  )
}
