import * as React from "react"
import { Text, TextInput, StyleSheet } from "react-native"
import { theme } from "@common/theme"
import { Controller, UseFormReturn } from "react-hook-form"
import { SettingsForm, SettingsFormNames } from "../forms/SettingsForm"

interface IProps {
  methods: UseFormReturn<SettingsForm>
  name: "starting_letter"
  editable: boolean
  placeholder?: string
}

export const SettingsTextInput: React.FC<IProps> = ({
  methods,
  name,
  editable,
  placeholder,
}) => {
  const {
    control,
    formState: { errors },
  } = methods

  const handleChange = (value: string, onChange: (value: string) => void) => {
    if (!errors[name]) {
      onChange(value)
    }
  }

  return (
    <>
      <Controller
        control={control}
        rules={{
          maxLength: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            placeholder={placeholder}
            style={styles.input}
            value={String(value)}
            editable={editable}
            onChangeText={(value) => {
              handleChange(value, onChange)
            }}
          />
        )}
        name={name}
      />
      {errors[name] && <Text>{errors[name]?.message}</Text>}
    </>
  )
}

const styles = StyleSheet.create({
  numericContainer: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
    borderRadius: 10,
  },
  input: {
    width: "100%",
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
    fontSize: 20,
    textAlign: "center",
  },
})
