import React from "react"
import { Audio } from "expo-av"
import { Vibration, Platform } from "react-native";
import * as Haptics from 'expo-haptics';

const files = {
  click: require('@assets/audio/click.mp3'),
  timer1: require('@assets/audio/timer1.mp3'),
  timer2: require('@assets/audio/timer2.mp3'),
  round_start: require('@assets/audio/round_start.mp3'),
  round_end: require('@assets/audio/round_end.mp3'),
  team_won: require('@assets/audio/team_won.mp3'),
  timer: require('@assets/audio/timer.mp3'),
};

type FileType = typeof files
type Name = keyof FileType

type VibrateType = 'short' | 'long'

export const usePlaySound = (
  name: Name, 
  onLoad: boolean = false, 
  vibrate: boolean = true, 
  vibrateType: VibrateType = 'short',
): (() => void) => {
  const [sound, setSound] = React.useState<Audio.Sound>()

  async function playSound() {
    const { sound } = await Audio.Sound.createAsync(
      files[name]
    )
    setSound(sound)

    if (vibrate) {
      if (vibrateType == "long") {
        Vibration.vibrate(
          Platform.OS === "android" ? [0, 1000, 400, 1000] : [1000, 1000], 
          false
        )
      } else {
        if (Platform.OS !== 'web') {
          if (Haptics.selectionAsync) {
            Haptics.selectionAsync()
          }
        } else {
          Vibration.vibrate([0, 50], false)
        }
      }
    }
    await sound.playAsync()
  }

  React.useEffect(() => {
    if (onLoad) playSound()
  
    return sound
      ? () => {
          sound.unloadAsync()
        }
      : undefined
  }, [])

  return playSound
}