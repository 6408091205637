import { CurrentGameSubscription, TeamColorsEnum } from "@gql"
import { filter, flatMap } from "lodash"

export function teamScore(
  teamColor: TeamColorsEnum,
  turns: CurrentGameSubscription["games"][0]["turns"],
  players: CurrentGameSubscription["games"][0]["players"]
) {
  const teamPlayerIds = filter(players, (player) => player.team?.team_color === teamColor).map(
    (player) => player.id
  )
  const teamTurns = filter(turns, (turn) =>
    teamPlayerIds.includes(turn.player_id)
  )
  return flatMap(teamTurns, (turn) => turn.completed_card_ids).length
}
