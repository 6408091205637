import React, { ReactNode } from "react"
import { View, StyleSheet, TouchableOpacity } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import { TeamColorsEnum, useStartReviewMutation } from "@gql"
import { currentPlayerTeam, TeamColor } from "@common/utils/team"
import { ActiveTurnPlayState, drawableCards } from "@common/utils/turn"
import { capitalize, filter, flatMap, last } from "lodash"
import { GameRoundInstructionCard, GameRound } from "./GameRoundInstructionCard"
import { HostControls } from "./HostControls"
import { NoMoreRounds } from "./NoMoreRounds"
import { OtherTeamContent, YourTeamTurnContent } from "./TeamContent"
import { TurnContextPanel } from "./TurnContextPanel"
import { YourTurnContent } from "./YourTurnContent"
import { useTranslation } from "react-i18next"
import { playStateFromTurn } from "../utils"
import { useSecondsLeft } from "../utils/useSecondsLeft"
import { theme } from "@common/theme"
import { Text, SafeAreaView, Modal } from "@common/components"
import { FontAwesome } from "@expo/vector-icons"
import { SettingsGear } from "@lobby/components/SettingsGear"

export function Play() {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const [showHostControls, setShowHostControls] = React.useState(false)
  const [activeCardIndex, setActiveCardIndex] = React.useState<number>(0)

  const [startReview] = useStartReviewMutation()

  const [hasDismissedInstructionCard, setHasDismissedInstructionCard] =
    React.useState(false)

  const completedCardIds = flatMap(
    currentGame.turns,
    (turn) => turn.completed_card_ids
  )

  const activeTurn = last(currentGame.turns)
  const activePlayer = currentGame.players.find(
    (player) => player.id === activeTurn?.player_id
  )

  const [activeTurnPlayState, setActiveTurnPlayState] = React.useState(
    playStateFromTurn(activeTurn)
  )
  React.useEffect(() => {
    setActiveTurnPlayState(playStateFromTurn(activeTurn))
  }, [activeTurn])

  const secondsLeft = useSecondsLeft(activeTurnPlayState)

  // countdown timer
  React.useEffect(() => {
    if (
      activeTurnPlayState === ActiveTurnPlayState.Playing &&
      secondsLeft <= 0
    ) {
      setActiveTurnPlayState(ActiveTurnPlayState.Reviewing)
      if (currentPlayer.id === activeTurn?.player_id && activeTurn?.id) {
        startReview({
          variables: {
            currentTurnId: activeTurn.id,
          },
        })
      }
    }
  }, [secondsLeft]) // eslint-disable-line react-hooks/exhaustive-deps

  const numCompletedCards = completedCardIds.length
  const totalNumCards = currentGame.cards.length
  const numRounds = currentGame.rounds.length
  let roundNum = Math.floor(numCompletedCards / totalNumCards)

  React.useEffect(() => {
    setHasDismissedInstructionCard(false)
  }, [roundNum])

  if (numCompletedCards === numRounds * totalNumCards) {
    return <NoMoreRounds />
  }

  const currentRoundId = currentGame.rounds[roundNum].id
  const nextRoundId = currentGame.rounds[roundNum + 1]?.id

  const roundMarkers = [...Array(numRounds).keys()]
  let round = ""
  // if (roundMarkers.includes(roundMarker)) {
  if (roundMarkers.includes(roundNum)) {
    // const value = capitalize(currentGame.rounds[roundMarker].value)
    const value = capitalize(currentGame.rounds[roundNum].value)
    round = GameRound[value as GameRound]
    if (round) {
      round = t(`howToPlay.round.${round.toLowerCase()}.name`, round)
    } else {
      round = value
    }
  }

  if (!activeTurn || !activePlayer) {
    return null
  }

  const currentTeam = currentPlayerTeam(currentPlayer.id, currentGame.players)
  const currentTeamColor =
    TeamColor[currentTeam.team_color as TeamColorsEnum] ||
    theme.color.accent_secondary
  const yourTurn = activePlayer.id === currentPlayer.id
  const yourTeamTurn = activePlayer.team?.name === currentTeam.name
  let titleText: string = ""
  let content: ReactNode = null
  if (yourTurn) {
    titleText = t("play.yourTurn.title", "Your Turn")
    content = (
      <YourTurnContent
        yourTeamPlayers={filter(
          currentGame.players,
          (player) => activePlayer.team === player.team
        )}
        cardsInBowl={drawableCards(currentGame.turns, currentGame.cards)}
        secondsLeft={secondsLeft}
        activePlayer={activePlayer}
        activeTurn={activeTurn}
        activeTurnPlayState={activeTurnPlayState}
        onStart={() => {
          setActiveTurnPlayState(ActiveTurnPlayState.Playing)
        }}
        onOutOfCards={() => {
          setActiveTurnPlayState(ActiveTurnPlayState.Reviewing)
          startReview({
            variables: {
              currentTurnId: activeTurn.id,
            },
          })
        }}
        currentRoundId={currentRoundId}
        nextRoundId={nextRoundId}
        activeCardIndex={activeCardIndex}
        setActiveCardIndex={setActiveCardIndex}
      />
    )
  } else if (yourTeamTurn) {
    titleText = t("play.yourTeam.title", "You're Guessin'")
    content = (
      <YourTeamTurnContent
        activePlayer={activePlayer}
        activeTurn={activeTurn}
      />
    )
  } else {
    titleText = t("play.otherTeam.title", "You're Chillin'")
    content = (
      <OtherTeamContent activePlayer={activePlayer} activeTurn={activeTurn} />
    )
  }

  return (
    <View style={styles.playContainer}>
      <SafeAreaView
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          margin: 10,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
          }}
        >
          <View style={{ width: 20 }}></View>
          <View style={{ flex: 1, alignItems: "center" }}>
            <Text
              variant="h1"
              fontVariant="LuckiestGuy"
              style={{ color: currentTeamColor }}
              numberOfLines={1}
              adjustsFontSizeToFit
              allowFontScaling
            >
              {currentTeam.name}
            </Text>
          </View>
          <View style={{ width: 20 }}>
            {currentPlayer.role === PlayerRole.Host ? (
              <TouchableOpacity
                onPress={() => {
                  setShowHostControls((curVal) => !curVal)
                }}
              >
                <FontAwesome
                  name="gear"
                  size={20}
                  color={theme.color.text_primary}
                />
              </TouchableOpacity>
            ) : (
              <SettingsGear showShareCode />
            )}
          </View>
        </View>

        {showHostControls ? (
          <HostControls
            activePlayer={activePlayer}
            activeTurn={activeTurn}
            currentRoundId={currentRoundId}
          />
        ) : (
          <>
            <GameRoundInstructionCard
              condensed
              round={round}
              // roundNumber={Number(roundMarker + 1)}
              roundNumber={Number(roundNum + 1)}
              onDismiss={() => {
                setHasDismissedInstructionCard(true)
              }}
            />
            {!hasDismissedInstructionCard && (
              <Modal
                transparent={true}
                visible={!hasDismissedInstructionCard}
                onMaskClick={() => {
                  setHasDismissedInstructionCard(true)
                }}
              >
                <GameRoundInstructionCard
                  condensed={false}
                  round={round}
                  roundNumber={Number(roundNum + 1)}
                  onDismiss={() => {
                    setHasDismissedInstructionCard(true)
                  }}
                />
              </Modal>
            )}

            <TurnContextPanel
              activeCardIndex={activeCardIndex}
              secondsLeft={Math.round(Math.max(secondsLeft, 0)) || 0}
              myTurn={yourTurn}
            />

            {content}
          </>
        )}
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  playContainer: {
    flex: 1,
    // backgroundColor: theme.color.pastel_teal,
  },
  title: {
    ...theme.fonts.h2,
  },
})
