import React from "react"
import { AnimatePresence } from "framer-motion"
import { MotiView } from "moti"
import { Text } from "@common/components"

interface IProps {
  time: number
  [x: string]: unknown
}

const AnimatedText = ({ time, ...props }: { time: number }) => {
  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: -20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
    >
      <Text {...props}>{time}</Text>
    </MotiView>
  )
}

export const SlideDownTimer: React.FC<IProps> = ({ time, ...props }) => {
  const isEven = time % 2 == 0

  return (
    <AnimatePresence exitBeforeEnter>
      {isEven && <AnimatedText time={time} {...props} key="even" />}
      {!isEven && <AnimatedText time={time} {...props} key="odd" />}
    </AnimatePresence>
  )
}
