import React from "react"
import { View, StyleSheet } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import { GameStateEnum, useUpdateGameStateMutation } from "@gql"
import { useTranslation } from "react-i18next"
import { grid, theme, vertGrid } from "@common/theme"
import { Text, Button, SafeAreaView } from "@common/components"
import { LinearGradient } from "expo-linear-gradient"

export function NoMoreRounds() {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const [updateGameState] = useUpdateGameStateMutation()

  return (
    <LinearGradient
      colors={[
        theme.color.pastel_teal,
        theme.color.pastel_blue,
        theme.color.pastel_gray,
      ]}
      style={{ flex: 1 }}
    >
      <SafeAreaView
        style={{
          flex: 1,
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text variant="h1" style={styles.text}>
          {t("end.title", "Game Over")}
        </Text>
        <Text style={styles.text}>
          {t(
            "end.endGame.description",
            "Your host will end the game to show some stats!"
          )}
        </Text>
        <View
          style={{
            marginTop: vertGrid(2),
            paddingHorizontal: grid(2),
          }}
        >
          {currentPlayer.role === PlayerRole.Host && (
            <Button
              block
              textVariant="h2"
              onPress={() => {
                updateGameState({
                  variables: {
                    id: currentGame.id,
                    state: GameStateEnum.Ended,
                  },
                })
              }}
            >
              {t("end.endGame.button", "End Game")}
            </Button>
          )}
        </View>
      </SafeAreaView>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  text: {
    color: theme.color.text_secondary,
  },
})
