import React from "react"
import { View, Dimensions } from "react-native"
import { PlayerChip } from "@common/components/PlayerChip"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import {
  CurrentGameSubscription,
  useEndCurrentTurnAndStartNextTurnMutation,
} from "@gql"
import {
  nextPlayerForNextTeam,
  nextPlayerForSameTeam,
} from "@common/utils/turn"
import { Trans, useTranslation } from "react-i18next"
import { theme, vertGrid } from "@common/theme"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { Button, Text, SafeAreaView } from "@common/components"
import { ShareSection } from "@lobby/components/ShareSection"
import { ParticpantSettingsSummary } from "@lobby/components/ParticipantSettingsSummary"

const { width } = Dimensions.get("window")

export function HostControls(props: {
  activeTurn: CurrentGameSubscription["games"][0]["turns"][0]
  activePlayer: CurrentGameSubscription["games"][0]["players"][0]
  currentRoundId: number
}) {
  const { t } = useTranslation()
  const { showActionSheetWithOptions } = useActionSheet()

  const currentGame = React.useContext(CurrentGameContext)
  const [endTurn] = useEndCurrentTurnAndStartNextTurnMutation()

  const nextPlayerActiveTeam = nextPlayerForSameTeam(
    props.activePlayer,
    currentGame.players
  )

  const nextPlayerNextTeam = nextPlayerForNextTeam(
    props.activePlayer,
    currentGame.turns,
    currentGame.players
  )

  return (
    <SafeAreaView
      style={{
        flexDirection: "column",
        borderColor: theme.color.accent_secondary,
      }}
    >
      <ShareSection droppedOut />
      <ParticpantSettingsSummary />

      <Text
        variant="h1"
        style={{ textAlign: "center", paddingVertical: vertGrid(2) }}
      >
        {t("hostControls.title", "Host Controls")}
      </Text>

      <View
        style={{
          flex: 1,
          alignItems: "center",
        }}
      >
        <Button
          style={{
            height: "30%",
            width: width - 20,
            marginBottom: vertGrid(4),
          }}
          onPress={() => {
            const title = t(
              "hostControls.skipPlayer.confirmation",
              "Are you sure you want to skip {{ activePlayerName }} (team {{ activePlayerTeam }})? {{ nextPlayerName }} from the same team would go instead.",
              {
                activePlayerName: props.activePlayer.username,
                activePlayerTeam: props.activePlayer.team?.name,
                nextPlayerName: nextPlayerActiveTeam.username,
              }
            )
            const options = ["Skip", "Cancel"]
            const skipButtonIndex = 0
            const cancelButtonIndex = 1
            showActionSheetWithOptions(
              {
                title,
                options,
                cancelButtonIndex,
              },
              (buttonIndex) => {
                if (buttonIndex == skipButtonIndex) {
                  endTurn({
                    variables: {
                      currentTurnId: props.activeTurn.id,
                      completedCardIds: [],
                      gameId: currentGame.id,
                      currentTurnScorings: [],
                      nextTurnplayerId: nextPlayerActiveTeam.id,
                      roundId: props.currentRoundId,
                    },
                  })
                }
              }
            )
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text variant="h2">
              {t("hostControls.skipPlayer.button", "Skip Player")}
            </Text>
            <Trans t={t} i18nKey="hostControls.skipHelper">
              <PlayerChip teamColor={nextPlayerActiveTeam.team?.team_color}>
                {{ playerUsername: nextPlayerActiveTeam.username }}
              </PlayerChip>
            </Trans>
            <Text>{" would be next"}</Text>
          </View>
        </Button>

        <Button
          style={{ height: "30%", width: width - 20 }}
          onPress={() => {
            const title = t(
              "hostControls.skipTeam.confirmation",
              "Are you sure you want to skip {{ activePlayerName }} (team {{ activePlayerTeam }})? {{ nextPlayerName }} from the other team would go next.",
              {
                activePlayerName: props.activePlayer.username,
                activePlayerTeam: props.activePlayer.team?.name,
                nextPlayerName: nextPlayerNextTeam.username,
              }
            )
            const options = ["Skip", "Cancel"]
            const skipButtonIndex = 0
            const cancelButtonIndex = 1
            showActionSheetWithOptions(
              {
                title,
                options,
                cancelButtonIndex,
              },
              (buttonIndex) => {
                if (buttonIndex == skipButtonIndex) {
                  endTurn({
                    variables: {
                      currentTurnId: props.activeTurn.id,
                      completedCardIds: [],
                      gameId: currentGame.id,
                      currentTurnScorings: [],
                      nextTurnplayerId: nextPlayerNextTeam.id,
                      roundId: props.currentRoundId,
                    },
                  })
                }
              }
            )
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text variant="h2">
              {t("hostControls.skipTeam.button", "Skip Team")}
            </Text>
            <Trans t={t} i18nKey="hostControls.skipHelper">
              <PlayerChip teamColor={nextPlayerNextTeam.team?.team_color}>
                {{ playerUsername: nextPlayerNextTeam.username }}
              </PlayerChip>
            </Trans>
            <Text>{" would be next"}</Text>
          </View>
        </Button>
      </View>
    </SafeAreaView>
  )
}
