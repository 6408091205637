import React from "react"
import { View, TextInput, Text, StyleSheet } from "react-native"
import { useForm, Controller } from "react-hook-form"
import { UsernameInputForm } from "@lobby/types"
import { useTranslation } from "react-i18next"
import { Players, useUpdatePlayerMutation } from "@gql"
import { theme, vertGrid } from "@common/theme"
import { Button } from "@common/components/Button/Button"

export function UsernameInput(props: {
  playerId: Players["id"]
  username: string
}) {
  const { t } = useTranslation()
  const [updatePlayer] = useUpdatePlayerMutation()
  const [savedUsername, setSavedUsername] = React.useState(props.username || "")

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UsernameInputForm>({
    defaultValues: {
      username: undefined,
    },
  })

  const usernameValue = watch("username")

  const onSubmit = async (data: UsernameInputForm) => {
    const response = await updatePlayer({
      variables: {
        id: props.playerId,
        input: { username: data.username },
      },
    })
    if (response.data?.update_players_by_pk?.username) {
      setSavedUsername(response.data?.update_players_by_pk?.username)
    }
  }

  return (
    <View style={styles.container}>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            placeholder={t("lobby.username.label", "Username")}
            placeholderTextColor={theme.color.text_secondary}
            defaultValue={savedUsername}
            style={styles.input}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
          />
        )}
        name="username"
      />
      {errors.username && <Text>This is required.</Text>}
      {/* <Text style={{ width: "100%", textAlign: "right" }}>
        {t("lobby.username.helper", "Emojis encouraged!")} 🌍🚀✨
      </Text> */}

      <Button
        block
        variant="primary"
        style={styles.buttonContainer}
        disabled={usernameValue === "" || usernameValue === savedUsername}
        onPress={handleSubmit(onSubmit)}
        textStyle={styles.buttonText}
      >
        {!!savedUsername
          ? t("lobby.username.changeButton", "Change")
          : t("lobby.username.joinButton", "Join")}
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginVertical: vertGrid(2),
  },
  buttonText: {
    ...theme.fonts.h2,
  },
  container: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  input: {
    width: "100%",
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderColor: theme.color.pastel_green,
    fontSize: 20,
    textAlign: "center",
  },
})
