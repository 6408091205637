import { theme, PlayStateColors } from "@common/theme"
import { showMessage } from "react-native-flash-message"

export type MessageVariants =
  | "success"
  | "info"
  | "warning"
  | "error"

export const flashMessage = (
  message: string = "",
  variant: MessageVariants = "success",
  ...props: any[]
) => {
  let backgroundColor, color
  switch (variant) {
    case "success":
      backgroundColor = PlayStateColors.go
      color = theme.color.text_secondary
      break
    case "info":
      backgroundColor = PlayStateColors.alt
      color = theme.color.text_secondary
      break
    case "warning":
      backgroundColor = PlayStateColors.wait
      color = theme.color.text_secondary
      break
    case "error":
      backgroundColor = PlayStateColors.stop
      color = theme.color.text_secondary
      break
  }

  return showMessage({
    message: message,
    type: "default",
    backgroundColor,
    color,
    ...props
  })
}
