import { ApolloProvider } from "@apollo/react-hooks"
import { InMemoryCache, ApolloClient, HttpLink, split } from "@apollo/client"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { getMainDefinition } from "@apollo/client/utilities"
import {
  AuthRole,
  CurrentAuthContext,
  CurrentAuthContextType,
} from "@common/contexts/CurrentAuthService"
import * as React from "react"
import Constants from "expo-constants"

const { manifest } = Constants
const env = manifest?.releaseChannel || ""
let apiUri: string, wsUri: string
if (__DEV__ || env.indexOf("dev") !== -1) {
  // Dev
  if (manifest?.debuggerHost) {
    apiUri = `http://${manifest.debuggerHost
      .split(":")
      .shift()}:8080/v1/graphql`
    wsUri = `ws://${manifest.debuggerHost.split(":").shift()}:8080/v1/graphql`
  } else {
    apiUri = "http://localhost:8080/v1/graphql"
    wsUri = "ws://localhost:8080/v1/graphql"
  }
} else if (env.indexOf("staging") !== -1) {
  // Staging
  apiUri = "https://api.epicfishbowl.com/v1/graphql"
  wsUri = "wss://api.epicfishbowl.com/v1/graphql"
} else {
  // Prod
  apiUri = "https://api.epicfishbowl.com/v1/graphql"
  wsUri = "wss://api.epicfishbowl.com/v1/graphql"
}

const createApolloClient = (jwtToken: CurrentAuthContextType["jwtToken"]) => {
  const httpLink = new HttpLink({
    uri: apiUri,
    credentials: "include",
    headers: jwtToken
      ? {
          Authorization: `Bearer ${jwtToken}`,
          "X-Hasura-Role": AuthRole.Player,
        }
      : undefined,
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsUri,
      lazy: true,
      retryAttempts: Infinity,
      connectionParams: {
        headers: jwtToken
          ? {
              Authorization: `Bearer ${jwtToken}`,
              "X-Hasura-Role": AuthRole.Player,
            }
          : undefined,
      },
    })
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })
}

export function ApolloWrapper(props: { children: React.ReactNode }) {
  const currentAuth = React.useContext(CurrentAuthContext)
  const client: any = createApolloClient(currentAuth.jwtToken)
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}
