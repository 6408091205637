import React from "react"
import { CurrentGameSubscription, GameCardPlayStyleEnum } from "@gql"
import { startCase } from "lodash"
import { useTranslation } from "react-i18next"
import { Text } from "@common/components"

export interface IProps {
  cardPlayStyle?: GameCardPlayStyleEnum
  numEntriesPerPlayer: number
  startingLetter?: string
  screenCard?: boolean
  secondsPerTurn?: number
  allowCardSkips?: boolean
  rounds?: CurrentGameSubscription["games"][0]["rounds"]
}

export const SettingsSummary: React.FC<IProps> = ({
  cardPlayStyle = GameCardPlayStyleEnum.PlayersSubmitWords,
  numEntriesPerPlayer,
  startingLetter,
  screenCard,
  secondsPerTurn,
  allowCardSkips,
  rounds,
}) => {
  const { t } = useTranslation()

  let cardPlayStyleDesc = ""
  switch (cardPlayStyle) {
    case GameCardPlayStyleEnum.PlayersSubmitWords:
      const submitWord = "Players will submit {{ numEntriesPerPlayer }} word"
      const startingWith = 'starting with the letter "{{ startingLetter }}"'

      if (startingLetter) {
        cardPlayStyleDesc = t(
          "settings.summary.cardStyle.playersSubmitLetter",
          `${submitWord}, ${startingWith}.`,
          {
            numEntriesPerPlayer,
            startingLetter,
            defaultValue_plural: `${submitWord}s, all ${startingWith}.`,
          }
        )
      } else {
        cardPlayStyleDesc = t(
          "settings.summary.cardStyle.playersSubmit",
          `${submitWord}.`,
          {
            numEntriesPerPlayer,
            defaultValue_plural: `${submitWord}s.`,
          }
        )
      }

      if (screenCard) {
        cardPlayStyleDesc += " The host will be able to screen words."
      }
      break
    case GameCardPlayStyleEnum.HostProvidesWords:
      cardPlayStyleDesc = " The host will provide the words."
      break
  }

  return (
    <Text>
      {cardPlayStyleDesc}
      {"\n"}
      {rounds &&
        rounds.length > 0 &&
        t(
          "settings.summary.turnSeconds",
          "Turns will last {{ seconds }} seconds across rounds of {{ rounds }}.",
          {
            seconds: secondsPerTurn,
            rounds: rounds.map((round) => startCase(round.value)).join(", "),
          }
        )}
      {"\n"}
      {allowCardSkips
        ? t(
            "settings.summary.cardSkips",
            "Players can skip cards during their turn."
          )
        : t(
            "settings.summary.noCardSkips",
            "Players cannot skip cards during their turn."
          )}
    </Text>
  )
}
