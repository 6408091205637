import React from "react"
import { Text, TextInput, StyleSheet } from "react-native"
import { Card } from "react-native-elements"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { lowerCase } from "lodash"
import { useTranslation } from "react-i18next"
import { theme } from "@common/theme"
import { UseFormReturn, Controller } from "react-hook-form"

enum errorTypes {
  required = "required",
  starting_letter = "starting_letter",
  similar_submittted = "similar_submittted",
  already_submittted = "already_submittted",
}

export function SubmissionCard(props: {
  methods: UseFormReturn
  index: number
  totalWordCount: number
}) {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)

  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = props.methods

  const hasStartingLetterError = (word: string) => {
    return (
      !!currentGame.starting_letter &&
      word.length > 0 &&
      word[0].toLocaleUpperCase() !==
        currentGame.starting_letter.toLocaleUpperCase()
    )
  }

  const hasSimilarSubmissionError = (word: string) => {
    const submittedWords = currentGame.cards.map((card) => lowerCase(card.word))
    return submittedWords.includes(lowerCase(word))
  }

  const alreadySubmittedWord = (word: string) => {
    if (word) {
      const currentWords = Object.values(props.methods.getValues())
      const duplicatedSubmittedWords = currentWords.filter(
        (submittedWord) => lowerCase(submittedWord) === lowerCase(word)
      )
      return duplicatedSubmittedWords.length > 0
    }

    return false
  }

  const name = "word_" + props.index

  const handleChange = (value: string) => {
    let foundError = false
    if (!value) {
      foundError = true
      setError(name, {
        type: errorTypes.required,
        message: t("cardSubmission.card.required", "Cannot be empty."),
      })
    }
    if (currentGame.starting_letter && hasStartingLetterError(value)) {
      foundError = true
      setError(name, {
        type: errorTypes.starting_letter,
        message: t(
          "cardSubmission.card.helperLetter",
          `Must start with letter {{ letter }}!`,
          {
            letter: currentGame.starting_letter.toLocaleUpperCase(),
          }
        ),
      })
    }
    if (hasSimilarSubmissionError(value)) {
      foundError = true
      setError(name, {
        type: errorTypes.similar_submittted,
        message: t(
          "cardSubmission.card.helperSimilar",
          "Someone made a similar submission - try a new word!"
        ),
      })
    }
    if (alreadySubmittedWord(value)) {
      foundError = true
      setError(name, {
        type: errorTypes.already_submittted,
        message: t(
          "cardSubmission.card.alreadySubmitted",
          "You have already entered this - try a new word!"
        ),
      })
    }

    if (!foundError) clearErrors(name)
  }

  return (
    <Card containerStyle={{ borderRadius: 10 }}>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            style={styles.input}
            value={value}
            placeholder={t("submissioncard.input", "Enter word here.")}
            placeholderTextColor={theme.color.text_primary}
            onChangeText={(value) => {
              handleChange(value)
              onChange(value)
            }}
            onBlur={onBlur}
          />
        )}
      />

      {errors[name] && (
        <Text style={styles.error}>{errors[name]?.message}</Text>
      )}

      <Text
        style={{ textAlign: "center", color: theme.color.accent_secondary }}
      >
        {props.index + 1} of {props.totalWordCount}
      </Text>
    </Card>
  )
}

const styles = StyleSheet.create({
  error: {
    color: "red",
  },
  input: {
    width: "100%",
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme.color.accent_secondary,
    fontSize: 20,
    textAlign: "center",
  },
})
