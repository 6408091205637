import React from "react"
import { View, StyleSheet } from "react-native"
import { PlayerChipList } from "@common/components/PlayerChipList"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import { teamScore } from "@common/utils/score"
import { Team, TeamColor } from "@common/utils/team"
import { flatMap, isEmpty, reject } from "lodash"
import { useTranslation } from "react-i18next"
import { useNavigation } from "@react-navigation/native"
import { RootNavigationProp, RootRoutes } from "@common/routes"
import { Podium } from "@end-game/components/Podium/Podium"
import { WinnersList } from "@end-game/components/Podium/WinnerList"
import { Winner } from "./Podium/types"
import { WinScreen } from "./WinScreen"
import { Text, Button, Layout } from "@common/components"
import { grid, theme, vertGrid } from "@common/theme"
import { ScrollView } from "react-native-gesture-handler"
import { TeamColorsEnum } from "@gql"
import { removeGameInProgress } from "@common/utils/useJoinGameInProgress"

// import {
//     FacebookIcon,
//     FacebookShareButton,
//     TwitterIcon,
//     TwitterShareButton,
//   } from "react-share"

export const EndGame: React.FC = () => {
  const { t } = useTranslation()
  const navigation = useNavigation<RootNavigationProp>()
  const currentGame = React.useContext(CurrentGameContext)
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const [hideMyTeamWon, setHideMyTeamWon] = React.useState(false)

  React.useEffect(() => {
    removeGameInProgress()
  }, [])

  const turnsByPlayer = new Map()
  currentGame.turns.forEach((turn) => {
    turnsByPlayer.set(
      turn.player_id,
      reject(
        (turnsByPlayer.get(turn.player_id) || []).concat([
          turn.completed_card_ids,
        ]),
        (arr) => isEmpty(arr)
      )
    )
  })

  const scoresByPlayer = new Map()
  turnsByPlayer.forEach((value, key) => {
    scoresByPlayer.set(key, flatMap(value).length)
  })

  const playersWithScores: Winner[] = currentGame.players
    .map((player) => {
      return {
        ...player,
        score: scoresByPlayer.get(player.id),
      }
    })
    .sort((a, b) => {
      return (b.score || 0) - (a.score || 0)
    })
    .map((winner, place) => ({ ...winner, place }))

  let _numTopPlayers = 5
  const topPlayers = playersWithScores.slice(0, _numTopPlayers)

  const redScore = teamScore(
    TeamColorsEnum.Red,
    currentGame.turns,
    currentGame.players
  )
  const blueScore = teamScore(
    TeamColorsEnum.Blue,
    currentGame.turns,
    currentGame.players
  )

  const myTeam = currentGame.players.find(
    (player) => player.id === currentPlayer.id
  )?.team

  const tie = redScore === blueScore
  const winningTeamColor =
    redScore > blueScore ? TeamColorsEnum.Red : TeamColorsEnum.Blue
  const winningTeam = currentGame.teams.find(
    (team) => team.team_color === winningTeamColor
  )
  const myTeamWon = winningTeamColor === myTeam?.team_color

  const winningTeamPlayers = playersWithScores.filter(
    (player) => player.team?.team_color === winningTeamColor
  )

  const shareContent = t(
    "end.shareContent",
    "Just had a great time playing {{ url }} online, you should check it out!",
    { url: "fishbowl-game.com" }
  )

  return myTeamWon && !hideMyTeamWon ? (
    <WinScreen setHideMyTeamWon={setHideMyTeamWon} />
  ) : (
    <Layout
      headerColor={theme.color.pastel_teal}
      header={
        <View
          style={{
            flexDirection: "column",
            paddingTop: vertGrid(1),
          }}
        >
          <Text variant="h4" fontVariant="LuckiestGuy" style={styles.text}>
            {t("end.title", "Game Over")}
          </Text>
          <Text variant="h2" style={styles.text}>
            {
              <Text style={{ color: TeamColor[TeamColorsEnum.Red] }}>
                {redScore}
              </Text>
            }
            {" - "}
            {
              <Text style={{ color: TeamColor[TeamColorsEnum.Blue] }}>
                {blueScore}
              </Text>
            }
          </Text>
          {tie ? (
            <Text
              variant="h3"
              style={{
                color: TeamColor[winningTeamColor],
                textAlign: "center",
              }}
            >
              {t("end.result.tie", "It's a tie! Play again to break it.")}
            </Text>
          ) : (
            <Text
              variant="h3"
              fontVariant="LuckiestGuy"
              style={{
                color: TeamColor[winningTeamColor],
                textAlign: "center",
              }}
            >
              {t("end.result.win", "{{ teamName }}", {
                teamName: winningTeam?.name,
              })}
              <Text
                variant="h3"
                style={{
                  color: TeamColor[winningTeamColor],
                  textAlign: "center",
                }}
              >
                {" win!"}
              </Text>
            </Text>
          )}

          <PlayerChipList
            players={winningTeamPlayers}
            horizontal
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
            }}
          />

          <Text style={styles.text}>
            {t("end.yourScore", "You scored {{ score }} across all rounds.", {
              score: scoresByPlayer.get(currentPlayer.id) || 0,
            })}
          </Text>
        </View>
      }
    >
      <View style={{ flex: 1 }}>
        <Podium winners={topPlayers} />
        <ScrollView style={{ flex: 1 }}>
          <WinnersList winners={topPlayers} />
        </ScrollView>
      </View>

      {/* {!isEmpty(highestScorePlayers) && (
          <Trans
            t={t}
            i18nKey="end.highScore"
            count={highestScorePlayers.length}
            values={{ highScore }}
            tOptions={{
              defaultValue_plural:
                "<0>{{playerUsernames}}</0> put the team on their back. They got their team to guess the most number of cards ({{ highScore }}!), across all rounds.",
            }}
          >
            <PlayerChipList players={highestScorePlayers}>
              {{ playerUsernames: null }}
            </PlayerChipList>
            <Text>
              {
                " put the team on their back. They got their team to guess the most number of cards ({{ highScore }}!), across all rounds."
              }
            </Text>
          </Trans>
        )} */}
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={[styles.text, { color: theme.color.text_secondary }]}>
          {t(
            "end.thanks.share",
            "Thanks for playing -- if you had fun, share it with your friends!"
          )}
        </Text>

        {/* <View style={{ justifyContent: "center" }}>
            <TwitterShareButton url={"fishbowl-game.com"} title={shareContent}>
              <TwitterIcon size={50} round />
            </TwitterShareButton>
            <FacebookShareButton url={"fishbowl-game.com"} title={shareContent}>
              <FacebookIcon size={50} round />
            </FacebookShareButton>
          </View> */}
        {/* <Trans t={t} i18nKey="end.thanks.support">
            <Text>Or support the project by</Text>
            <View>
              <Link href="https://forms.gle/L9qWMsnAUghXqqxE9" target="_blank">
                sharing your feedback
              </Link>
              , and playing again soon!
            </View>
          </Trans> */}
      </View>
      <View style={{ justifyContent: "center" }}>
        <Button
          textVariant="h2"
          style={{ margin: grid(1) }}
          onPress={() => navigation.navigate(RootRoutes.Home)}
        >
          {t("end.playAgainButton", "Play Again")}
        </Button>
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
  },
})
