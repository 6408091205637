import { theme } from "@common/theme"
import { CurrentGameSubscription, TeamColorsEnum } from "@gql"
import { cloneDeep, find, remove, shuffle } from "lodash"

export type Player = CurrentGameSubscription["games"][0]["players"][0]
export type Team = CurrentGameSubscription["games"][0]["teams"][0]
export type Players = Array<Player>
export type Teams = Array<Team>

export const TeamColor = {
  [TeamColorsEnum.Red]: theme.color.error_primary,
  [TeamColorsEnum.Blue]: theme.color.pastel_blue,
}

function addTeamAndSequence(players: Players, teamId: String) {
  return players.map((player: Player, index) => {
    return { ...player, team_id: teamId, team_sequence: index }
  })
}

export function getTeamIdByColor(teams: Teams) {
  const redTeamId = teams.find(team => team.team_color === TeamColorsEnum.Red)?.id
  const blueTeamId = teams.find(team => team.team_color === TeamColorsEnum.Blue)?.id

  return {
    [TeamColorsEnum.Red]: redTeamId,
    [TeamColorsEnum.Blue]: blueTeamId,
  }
}

// [1,2,3,4,5,6].splice(0,Math.ceil(6/ 2))
// > [1, 2, 3]
// [1,2,3,4,5,6].splice(Math.ceil(6/ 2), 6)
// > [4, 5, 6]
// [1,2,3,4,5,6,7].splice(0,Math.ceil(7/ 2))
// > [1, 2, 3, 4] (first half will always be equal or 1 longer)
// [1,2,3,4,5,6,7].splice(Math.ceil(7/ 2), 7)
// > [5, 6, 7]

export function teamsWithSequence(players: Players, teams: Teams) {
  const teamIds = getTeamIdByColor(teams)

  const shuffledPlayers = shuffle(players)
  const halfLength = Math.ceil(shuffledPlayers.length / 2)
  const redTeam = addTeamAndSequence(
    cloneDeep(shuffledPlayers).splice(0, halfLength),
    teamIds[TeamColorsEnum.Red]
  )
  const blueTeam = addTeamAndSequence(
    cloneDeep(shuffledPlayers).splice(halfLength, shuffledPlayers.length),
    teamIds[TeamColorsEnum.Blue]
  )
  return redTeam.concat(blueTeam)
}

export function teamsWithSequenceWithUpdate(
  players: Players,
  updatedPlayer: Player,
  teamIds: Team["id"]
) {
  remove(players, (player) => player.id === updatedPlayer.id)
  players.push(updatedPlayer)

  const redTeam = addTeamAndSequence(
    players.filter(player => player.team_id === teamIds[TeamColorsEnum.Red]),
    teamIds[TeamColorsEnum.Red]
  )

  const blueTeam = addTeamAndSequence(
    players.filter(player => player.team_id === teamIds[TeamColorsEnum.Blue]),
    teamIds[TeamColorsEnum.Blue]
  )

  return redTeam.concat(blueTeam)
}

export function currentPlayerTeam(
  currentPlayerId: Player["id"],
  players: Players
): Team {
  return find(players, (player) => player.id === currentPlayerId)?.team as Team
}
