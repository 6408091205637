import { Winner } from "./types"
import { WinnerCard } from "./WinnerCard"
import { View } from "react-native"

interface Props {
  readonly winners: Winner[]
}

export function WinnersList({ winners }: Props) {
  return (
    <View style={{ flex: 1 }}>
      {winners.map((winner, index) => (
        <WinnerCard
          key={winner.id}
          winners={winners}
          winner={winner}
          index={index}
        />
      ))}
    </View>
  )
}
