import React from "react"
import {
  View,
  Animated,
  PanResponder,
  Dimensions,
  Platform,
  ViewStyle,
} from "react-native"
import { Text } from "@common/components"
import { theme } from "@common/theme"

const { height, width } = Dimensions.get("window")

const distance = (x: number, y: number) => {
  return Math.hypot(x, y)
}

type Direction = "top" | "left" | "right" | "bottom"

interface IProps {
  children: React.ReactNode
  activeCardIndex: number | null
  style?: ViewStyle | ViewStyle[]
  cardContainerStyle?: ViewStyle | ViewStyle[]
  secondCardZoom?: number
  loop?: boolean
  initialIndex?: number
  rightSwipeText?: string
  leftSwipeText?: string
  renderNoMoreCards?: () => React.ReactElement | React.ReactElement
  onSwipeStart?: () => void
  onSwipeEnd?: () => void
  onSwiped?: (index: number) => void
  onSwipedLeft?: (index: number) => void
  onSwipedRight?: (index: number) => void
  onSwipedTop?: (index: number) => void
  onSwipedBottom?: (index: number) => void
  onSwipedAll?: () => void
  onSwipe?: (x: number, y: number) => void

  disableBottomSwipe?: boolean
  disableLeftSwipe?: boolean
  disableRightSwipe?: boolean
  disableTopSwipe?: boolean
  verticalSwipe?: boolean
  verticalThreshold?: number

  horizontalSwipe?: boolean
  horizontalThreshold?: number
  outputRotationRange?: string[] | number[]
  duration?: number
  useNativeDriver?: boolean
}

export type SwipeableCardRef = {
  goBackFromTop: () => void
  goBackFromRight: () => void
  goBackFromLeft: () => void
  goBackFromBottom: () => void
  swipeTop: () => void
  swipeBottom: () => void
  swipeRight: () => void
  swipeLeft: () => void
}

export const CardStack = React.memo(
  React.forwardRef<SwipeableCardRef, IProps>(
    (
      {
        children,
        activeCardIndex,
        style = {},
        cardContainerStyle = {},
        secondCardZoom = 0.95,
        loop = false,
        initialIndex = 0,
        rightSwipeText = "",
        leftSwipeText = "",
        renderNoMoreCards = () => {
          return <Text>No More Cards</Text>
        },
        onSwipeStart = () => null,
        onSwipeEnd = () => null,
        onSwiped = () => {},
        onSwipedLeft = () => {},
        onSwipedRight = () => {},
        onSwipedTop = () => {},
        onSwipedBottom = () => {},
        onSwipedAll = async () => {},
        onSwipe = () => {},
        disableBottomSwipe = false,
        disableLeftSwipe = false,
        disableRightSwipe = false,
        disableTopSwipe = false,
        verticalSwipe = true,
        verticalThreshold = height / 4,
        horizontalSwipe = true,
        horizontalThreshold = width / 5, // altered from default width / 2
        outputRotationRange = ["-15deg", "0deg", "15deg"],
        duration = 300,
        useNativeDriver = false,
      },
      ref
    ) => {
      const drag = new Animated.ValueXY({ x: 0, y: 0 })
      const dragDistance = new Animated.Value(0)
      // const [drag, setDrag] = React.useState(new Animated.ValueXY({ x: 0, y: 0 }))
      // const [dragDistance, setDragDistance] = React.useState(new Animated.Value(0))
      const [sindex, setSIndex] = React.useState(0)
      const [cardA, setCardA] = React.useState(null)
      const [cardB, setCardB] = React.useState(null)
      const [topCard, setTopCard] = React.useState("cardA")
      const [cards, setCards] = React.useState<any[]>([])
      const [touchStart, setTouchStart] = React.useState(0)
      const mounted = React.useRef(false)

      React.useImperativeHandle(ref, () => ({
        goBackFromTop: () => {
          _goBack("top")
        },
        goBackFromRight: () => {
          _goBack("right")
        },
        goBackFromLeft: () => {
          _goBack("left")
        },
        goBackFromBottom: () => {
          _goBack("bottom")
        },
        swipeTop: (d = null) => {
          _nextCard("top", 0, -height, d || duration)
        },
        swipeBottom: (d = null) => {
          _nextCard("bottom", 0, height, d || duration)
        },
        swipeRight: (d = null) => {
          _nextCard("right", width * 1.5, 0, d || duration)
        },
        swipeLeft: (d = null) => {
          _nextCard("left", -width * 1.5, 0, d || duration)
        },
      }))

      const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: (evt, gestureState) => false,
        onStartShouldSetPanResponderCapture: (evt, gestureState) => false,
        onMoveShouldSetPanResponder: (evt, gestureState) => {
          //altered
          const isVerticalSwipe =
            Math.pow(gestureState.dx, 2) < Math.pow(gestureState.dy, 2)
          if (!verticalSwipe && isVerticalSwipe) {
            return false
          }
          return (
            Math.sqrt(
              Math.pow(gestureState.dx, 2) + Math.pow(gestureState.dy, 2)
            ) > 10
          )
        },
        onMoveShouldSetPanResponderCapture: (evt, gestureState) => {
          const isVerticalSwipe =
            Math.pow(gestureState.dx, 2) < Math.pow(gestureState.dy, 2)
          if (!verticalSwipe && isVerticalSwipe) {
            return false
          }
          return (
            Math.sqrt(
              Math.pow(gestureState.dx, 2) + Math.pow(gestureState.dy, 2)
            ) > 10
          )
        },
        onPanResponderGrant: (evt, gestureState) => {
          onSwipeStart()
          setTouchStart(new Date().getTime())
        },
        onPanResponderMove: (evt, gestureState) => {
          const movedX = gestureState.moveX - gestureState.x0
          const movedY = gestureState.moveY - gestureState.y0
          onSwipe(movedX, movedY)
          const newDragDistance = distance(
            horizontalSwipe ? gestureState.dx : 0,
            verticalSwipe ? gestureState.dy : 0
          )
          dragDistance.setValue(newDragDistance)
          drag.setValue({
            x: horizontalSwipe ? gestureState.dx : 0,
            y: verticalSwipe ? gestureState.dy : 0,
          })
        },
        onPanResponderTerminationRequest: (evt, gestureState) => true,
        onPanResponderRelease: (evt, gestureState) => {
          onSwipeEnd()
          const currentTime = new Date().getTime()
          const swipeDuration = currentTime - touchStart

          if (
            (Math.abs(gestureState.dx) > horizontalThreshold ||
              (Math.abs(gestureState.dx) > horizontalThreshold * 0.6 &&
                swipeDuration < 150)) &&
            horizontalSwipe
          ) {
            const swipeDirection =
              gestureState.dx < 0 ? width * -1.5 : width * 1.5
            if (swipeDirection < 0 && !disableLeftSwipe) {
              _nextCard("left", swipeDirection, gestureState.dy, duration)
            } else if (swipeDirection > 0 && !disableRightSwipe) {
              _nextCard("right", swipeDirection, gestureState.dy, duration)
            } else {
              _resetCard()
            }
          } else if (
            (Math.abs(gestureState.dy) > verticalThreshold ||
              (Math.abs(gestureState.dy) > verticalThreshold * 0.8 &&
                swipeDuration < 150)) &&
            verticalSwipe
          ) {
            const swipeDirection = gestureState.dy < 0 ? height * -1 : height
            if (swipeDirection < 0 && !disableTopSwipe) {
              _nextCard("top", gestureState.dx, swipeDirection, duration)
            } else if (swipeDirection > 0 && !disableBottomSwipe) {
              _nextCard("bottom", gestureState.dx, swipeDirection, duration)
            } else {
              _resetCard()
            }
          } else {
            _resetCard()
          }
        },
        onPanResponderTerminate: (evt, gestureState) => {},
        onShouldBlockNativeResponder: (evt, gestureState) => {
          return true
        },
      })

      React.useEffect(() => {
        if (!mounted.current) {
          mounted.current = true
        } else {
          if (typeof children === "undefined") return
          const childrenArrayed = Array.isArray(children)
            ? children
            : [children]
          let aIndex =
            topCard == "cardA"
              ? _getIndex(sindex - 2, childrenArrayed.length)
              : _getIndex(sindex - 1, childrenArrayed.length)
          let bIndex =
            topCard == "cardB"
              ? _getIndex(sindex - 2, childrenArrayed.length)
              : _getIndex(sindex - 1, childrenArrayed.length)

          setCards(childrenArrayed)
          setCardA(childrenArrayed[aIndex] || null)
          setCardB(childrenArrayed[bIndex] || null)
        }
      }, [mounted])

      const _getIndex = (index: number, cards: number) => {
        return loop ? mod(index, cards) : index
      }

      React.useEffect(() => {
        initDeck()
      }, [])

      const initDeck = () => {
        if (typeof children === "undefined") return
        const newCards = Array.isArray(children) ? children : [children]
        const initialIndexA = initialIndex < newCards.length ? initialIndex : 0
        const initialIndexB = loop
          ? mod(initialIndexA + 1, newCards.length)
          : initialIndexA + 1
        const newCardA = newCards[initialIndexA] || null
        const newCardB = newCards[initialIndexB] || null
        setCards(newCards)
        setCardA(newCardA)
        setCardB(newCardB)
        setSIndex(initialIndexB + 1)
      }

      const _resetCard = () => {
        Animated.timing(dragDistance, {
          toValue: 0,
          duration: duration,
          useNativeDriver: useNativeDriver,
        }).start()
        Animated.spring(drag, {
          toValue: { x: 0, y: 0 },
          // TODO: duration was removed from spring
          // duration: duration,
          useNativeDriver: useNativeDriver,
        }).start()
      }

      const mod = (n: number, m: number) => {
        return ((n % m) + m) % m
      }

      const _goBack = (direction: Direction) => {
        if (sindex - 3 < 0 && !loop) return

        const previusCardIndex = mod(sindex - 3, cards.length)

        if (topCard === "cardA") {
          setCardB(cards[previusCardIndex])
        } else {
          setCardA(cards[previusCardIndex])
        }

        setTopCard(topCard === "cardA" ? "cardB" : "cardA")
        setSIndex(sindex - 1)

        setTimeout(() => {
          switch (direction) {
            case "top":
              drag.setValue({ x: 0, y: -height })
              dragDistance.setValue(height)
              break
            case "left":
              drag.setValue({ x: -width, y: 0 })
              dragDistance.setValue(width)
              break
            case "right":
              drag.setValue({ x: width, y: 0 })
              dragDistance.setValue(width)
              break
            case "bottom":
              drag.setValue({ x: 0, y: height })
              dragDistance.setValue(width)
              break
            default:
          }

          Animated.spring(dragDistance, {
            toValue: 0,
            // duration: duration,
            useNativeDriver: useNativeDriver,
          }).start()

          Animated.spring(drag, {
            toValue: { x: 0, y: 0 },
            // duration: duration,
            useNativeDriver: useNativeDriver,
          }).start()
        }, 0)
      }

      const _nextCard = (
        direction: Direction,
        x: number,
        y: number,
        duration: number = 400
      ) => {
        // index for the next card to be renderd
        const nextCard = loop ? Math.abs(sindex) % cards.length : sindex

        // index of the swiped card
        const index = loop ? mod(nextCard - 2, cards.length) : nextCard - 2

        if (index === cards.length - 1) {
          onSwipedAll()
        }

        if (sindex - 2 < cards.length || loop) {
          Animated.spring(dragDistance, {
            toValue: 220,
            // duration,
            useNativeDriver: useNativeDriver,
          }).start()

          Animated.timing(drag, {
            toValue: { x: horizontalSwipe ? x : 0, y: verticalSwipe ? y : 0 },
            // duration,
            useNativeDriver: useNativeDriver,
          }).start(() => {
            const newTopCard = topCard === "cardA" ? "cardB" : "cardA"

            if (newTopCard === "cardA") {
              setCardB(cards[nextCard])
            }
            if (newTopCard === "cardB") {
              setCardA(cards[nextCard])
            }
            drag.setValue({ x: 0, y: 0 })
            dragDistance.setValue(0)
            setTopCard(newTopCard)
            setSIndex(nextCard + 1)

            onSwiped(index)
            switch (direction) {
              case "left":
                onSwipedLeft(index)
                if (cards[index] && cards[index].onSwipedLeft)
                  cards[index] && cards[index].onSwipedLeft()
                break
              case "right":
                onSwipedRight(index)
                if (cards[index] && cards[index].onSwipedRight)
                  cards[index].onSwipedRight()
                break
              case "top":
                onSwipedTop(index)
                if (cards[index] && cards[index].onSwipedTop)
                  cards[index].onSwipedTop()
                break
              case "bottom":
                onSwipedBottom(index)
                if (cards[index] && cards[index].onSwipedBottom)
                  cards[index].onSwipedBottom()
                break
              default:
            }
          })
        }
      }

      /**
       * @description CardB’s click feature is trigger the CardA on the card stack. (Solved on Android)
       * @see https://facebook.github.io/react-native/docs/view#pointerevents
       */
      const _setPointerEvents = (topCard: string, topCardName: string) => {
        return { pointerEvents: topCard === topCardName ? "auto" : "none" }
      }

      const scale = dragDistance.interpolate({
        inputRange: [0, 10, 220],
        outputRange: [secondCardZoom, secondCardZoom, 1],
        extrapolate: "clamp",
      })
      const rotate = drag.x.interpolate({
        inputRange: [-width / 1.5, 0, width / 1.5],
        outputRange: outputRotationRange,
        extrapolate: "clamp",
      })

      const rightTextOpacity = () =>
        drag.x.interpolate({
          inputRange: [-width / 1.5, 0, width / 1.5],
          outputRange: [0, -0.2, 3],
          extrapolate: "clamp",
        })

      const leftTextOpacity = () =>
        drag.x.interpolate({
          inputRange: [-width / 1.5, 0, width / 1.5],
          outputRange: [3, -0.2, 0],
          extrapolate: "clamp",
        })

      const LeftAndRightText = () => (
        <>
          {!!rightSwipeText && !disableRightSwipe && (
            <Animated.View
              style={{
                opacity: rightTextOpacity(),
                transform: [{ rotate: "-30deg" }, { translateX: -50 }],
                position: "absolute",
                top: 50,
                zIndex: 1000,
              }}
            >
              <Text
                style={{
                  borderWidth: 1,
                  borderColor: theme.color.text_inverse_highlight,
                  color: theme.color.text_inverse_highlight,
                  fontSize: 32,
                  fontWeight: "800",
                  padding: 10,
                }}
              >
                {rightSwipeText}
              </Text>
            </Animated.View>
          )}
          {!!leftSwipeText && !disableLeftSwipe && (
            <Animated.View
              style={{
                opacity: leftTextOpacity(),
                transform: [{ rotate: "30deg" }, { translateX: 50 }],
                position: "absolute",
                top: 50,
                zIndex: 1000,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  borderWidth: 1,
                  borderColor: theme.color.error_primary,
                  color: theme.color.error_primary,
                  fontSize: 32,
                  fontWeight: "800",
                  padding: 10,
                }}
              >
                {leftSwipeText}
              </Text>
            </Animated.View>
          )}
        </>
      )

      return (
        <View
          {...panResponder.panHandlers}
          style={[{ position: "relative" }, style]}
        >
          {renderNoMoreCards()}

          <Animated.View
            // {..._setPointerEvents(topCard, "cardB")}
            style={[
              {
                position: "absolute",
                zIndex: topCard === "cardB" ? 3 : 2,
                ...Platform.select({
                  android: {
                    elevation: topCard === "cardB" ? 3 : 2,
                  },
                }),
                transform: [
                  { rotate: topCard === "cardB" ? rotate : "0deg" },
                  { translateX: topCard === "cardB" ? drag.x : 0 },
                  { translateY: topCard === "cardB" ? drag.y : 0 },
                  { scale: topCard === "cardB" ? 1 : scale },
                ],
              },
              cardContainerStyle,
            ]}
          >
            {topCard === "cardB" && <LeftAndRightText />}
            {cardB}
          </Animated.View>
          <Animated.View
            // {..._setPointerEvents(topCard, "cardA")}
            style={[
              {
                position: "absolute",
                zIndex: topCard === "cardA" ? 3 : 2,
                ...Platform.select({
                  android: {
                    elevation: topCard === "cardA" ? 3 : 2,
                  },
                }),
                transform: [
                  { rotate: topCard === "cardA" ? rotate : "0deg" },
                  { translateX: topCard === "cardA" ? drag.x : 0 },
                  { translateY: topCard === "cardA" ? drag.y : 0 },
                  { scale: topCard === "cardA" ? 1 : scale },
                ],
              },
              cardContainerStyle,
            ]}
          >
            {topCard === "cardA" && <LeftAndRightText />}
            {cardA}
          </Animated.View>
        </View>
      )
    }
  ),
  (props, nextProps) => {
    if (props.activeCardIndex !== nextProps.activeCardIndex) return false
    return true
  }
)
