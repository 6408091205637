import {
  CurrentGameSubscription,
  GameCardPlayStyleEnum,
  GameStateEnum,
  useCurrentGameSubscription,
} from "@gql"
import React from "react"
import { Platform } from "react-native"
import * as Updates from "expo-updates"

export type CurrentGameContextType = CurrentGameSubscription["games"][0] & {
  loading: boolean
}

export const CurrentGameContext = React.createContext<CurrentGameContextType>({
  id: "",
  state: GameStateEnum.Lobby,
  rounds: [],
  cards: [],
  players: [],
  turns: [],
  allow_card_skips: true,
  screen_cards: false,
  card_play_style: GameCardPlayStyleEnum.PlayersSubmitWords,
  loading: true,
  teams: [],
})

export const CurrentGameProvider = (props: {
  joinCode: string
  children: React.ReactNode
}) => {
  // const navigation = useNavigation<RootNavigationProp>()
  const { data, loading, error } = useCurrentGameSubscription({
    variables: {
      joinCode: props.joinCode,
    },
  })

  React.useEffect(() => {
    if (error) {
      if (Platform.OS == "web") {
        window.location.reload()
      } else {
        Updates.reloadAsync()
      }
    }
  }, [error])

  // if (!loading && !data?.games[0]) {
  //   navigation.navigate(RootRoutes.Home)
  // }
  return data?.games[0] ? (
    <CurrentGameContext.Provider value={{ ...data.games[0], loading }}>
      {props.children}
    </CurrentGameContext.Provider>
  ) : null
}
