import React from "react"
import { View, ViewStyle } from "react-native"

interface IProps {
  style?: ViewStyle | ViewStyle[]
}

export const Card: React.FC<IProps> = ({ style, children }) => (
  <View style={style}>{children}</View>
)
