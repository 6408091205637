import React from "react"
import { View, ActivityIndicator, StyleSheet } from "react-native"
import { theme } from "@common/theme"

export const LoadingScreen: React.FC = () => {
  return (
    <View style={[styles.container, styles.horizontal]}>
      <ActivityIndicator
        size="large"
        color={theme.color.pastel_blue}
        style={styles.indicator}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  indicator: {
    transform: [{ scale: 2 }],
  },
})
