import { NavigatorScreenParams } from '@react-navigation/native';
import { GameNavigatorParams } from './GameRoutes';

export enum RootRoutes {
  Home = 'Home',
  Join = 'Join',
  // Pending = 'Pending',
  GameNavigator = 'GameNavigator',
}

export type RootNavigatorParams = {
  [RootRoutes.Home]: undefined;
  [RootRoutes.Join]: undefined | { joinCode?: string };
  // [RootRoutes.Pending]: undefined;
  [RootRoutes.GameNavigator]: NavigatorScreenParams<GameNavigatorParams> & { joinCode: string };
};