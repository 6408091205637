import React from "react"
import { useFonts } from "expo-font"
import { Roboto_400Regular, Roboto_700Bold } from "@expo-google-fonts/roboto"
import { LuckiestGuy_400Regular } from "@expo-google-fonts/luckiest-guy"
import {
  ComicNeue_400Regular,
  ComicNeue_700Bold,
} from "@expo-google-fonts/comic-neue"

// Reference: https://docs.expo.io/versions/latest/sdk/font/#usefonts
export const Fonts: React.FC = ({ children }) => {
  const [loaded] = useFonts({
    Roboto_400Regular,
    Roboto_700Bold,
    LuckiestGuy_400Regular,
    ComicNeue_400Regular,
    ComicNeue_700Bold,
  })

  if (!loaded) {
    return null
  }

  return <>{children}</>
}
