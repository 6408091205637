import { makeStyleArray } from "@common/utils/style"
import {
  StyleSheet,
  Platform,
  StatusBar,
  SafeAreaView as RNSafeAreaView,
  ViewStyle,
} from "react-native"

interface Props {
  style?: ViewStyle | ViewStyle[]
  [x: string]: unknown
}

export const AndroidPadding =
  Platform.OS === "android" ? StatusBar.currentHeight : 0

export const SafeAreaView: React.FC<Props> = ({
  style,
  children,
  ...props
}) => {
  return (
    <RNSafeAreaView
      style={[styles.safeAreaView, ...makeStyleArray(style)]}
      {...props}
    >
      {children}
    </RNSafeAreaView>
  )
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    paddingTop: AndroidPadding,
  },
})
