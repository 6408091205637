import React, { useEffect } from "react"
import * as SecureStore from "expo-secure-store"
import { Platform } from "react-native"

export enum AuthRole {
  Anonymous = "anonymous",
  Player = "player",
}

export const AuthStorageKey = "player.jwtToken"

export type CurrentAuthContextType = {
  jwtToken: string | null
  setJwtToken: (jwtToken: string | null) => void
}

export const CurrentAuthContext = React.createContext<CurrentAuthContextType>({
  jwtToken: null,
  setJwtToken: () => {},
})

export function AuthProvider(props: { children: React.ReactNode }) {
  const [jwtToken, setJwtToken] =
    React.useState<CurrentAuthContextType["jwtToken"]>(null)

  useEffect(() => {
    async function getSecureStore() {
      let response
      if (Platform.OS === "web") {
        response = localStorage.getItem(AuthStorageKey)
      } else {
        response = await SecureStore.getItemAsync(AuthStorageKey)
      }
      setJwtToken(response)
    }
    getSecureStore()
  }, [])

  return (
    <CurrentAuthContext.Provider
      value={{
        jwtToken: jwtToken,
        setJwtToken: (jwtToken: CurrentAuthContextType["jwtToken"]) => {
          if (Platform.OS === "web") {
            if (jwtToken) {
              localStorage.setItem(AuthStorageKey, jwtToken)
            } else {
              localStorage.removeItem(AuthStorageKey)
            }
          } else {
            if (jwtToken) {
              SecureStore.setItemAsync(AuthStorageKey, jwtToken)
            } else {
              SecureStore.deleteItemAsync(AuthStorageKey)
            }
          }
          setJwtToken(jwtToken)
        },
      }}
    >
      {props.children}
    </CurrentAuthContext.Provider>
  )
}
