import React from "react"
import { theme } from "@common/theme"
import { useTranslation } from "react-i18next"
import { View, Dimensions, TouchableOpacity } from "react-native"
import { Text } from "@common/components"
import { Tooltip } from "react-native-elements/dist/tooltip/Tooltip"
import { Octicons } from "@expo/vector-icons"

const { width } = Dimensions.get("window")

export enum GameRound {
  Taboo = "Taboo",
  Charades = "Charades",
  Password = "Password",
}

export const GameRounds = [
  GameRound.Taboo,
  GameRound.Charades,
  GameRound.Password,
]

export const GameRoundDescription: Record<GameRound, string> = {
  [GameRound.Taboo]:
    "Use words to describe the word or phrase on the card, without any acting or gestures. You cannot use any part of the word or phrase!",
  [GameRound.Charades]:
    "Without words or sounds, act and use gestures to communicate the word or phrase on the card.",
  [GameRound.Password]:
    "You can say exactly one word to describe the word or phrase on the card, no more! You'll rely on your team's memory and association.",
}

export function GameRoundInstructionCard(props: {
  round: GameRound | string
  roundNumber: number
  onDismiss: () => void
  condensed: boolean
}) {
  const { t } = useTranslation()
  let description = GameRoundDescription[props.round as GameRound]

  if (description) {
    description = t(
      `howToPlay.round.${props.round.toLowerCase()}.description`,
      description
    )
  } else {
    description = t(
      "howToPlay.round.customDescription",
      "Your host will give you the rules for this one!"
    )
  }

  return props.condensed ? (
    <Tooltip
      popover={<Text variant="h3">{description}</Text>}
      containerStyle={{ width: width * 0.5, height: 170 }}
      backgroundColor={theme.color.pastel_teal}
    >
      <View style={{ flexDirection: "row" }}>
        <Text
          fontVariant="LuckiestGuy"
          variant="h2"
          style={{ fontWeight: "100" }}
        >
          {`${t("howToPlay.round.heading", "Round")} ${props.roundNumber}: ${
            props.round
          } `}
        </Text>
        <Octicons color={theme.color.text_primary} name="info" size={15} />
      </View>
    </Tooltip>
  ) : (
    <TouchableOpacity onPress={props.onDismiss}>
      <Text
        fontVariant="LuckiestGuy"
        variant="h2"
        style={{ fontWeight: "100", textAlign: "center" }}
      >
        {`${t("howToPlay.round.heading", "Round")} ${props.roundNumber}: ${
          props.round
        } `}
      </Text>
      <Text variant="h3">{description}</Text>
      <Text variant="footnote" style={{ textAlign: "center" }}>
        {t("howToPlay.round.pressAnywhere", "press anywhere to continue")}
      </Text>
    </TouchableOpacity>
  )

  // return (
  //   <Card
  //     containerStyle={{
  //       backgroundColor: theme.color.pastel_teal,
  //       borderColor: "transparent",
  //     }}
  //   >
  //     <Card.Title>
  //       <View
  //         style={{
  //           width: "100%",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         <View style={{ width: "10%" }}></View>
  //         <Text
  //           fontVariant="LuckiestGuy"
  //           variant="h2"
  //           style={{ fontWeight: "100" }}
  //         >
  //           {`${t("howToPlay.round.heading", "Round")} ${props.roundNumber}: ${
  //             props.round
  //           }`}
  //         </Text>
  //         <View style={{ width: "10%" }}>
  //           <TouchableOpacity
  //             onPress={props.onDismiss}
  //             testID={"modal:header:close"}
  //             accessibilityRole="image"
  //             accessibilityLabel="Close icon"
  //             accessibilityHint="Press to close current screen"
  //           >
  //             <Ionicons
  //               color={theme.color.text_primary}
  //               name="close"
  //               size={24}
  //             />
  //           </TouchableOpacity>
  //         </View>
  //       </View>
  //     </Card.Title>
  //     <Text>{description}</Text>
  //   </Card>
  // )
}
