import React from "react"
import { View } from "react-native"
import { UsernameInput } from "@lobby/inputs/UsernameInput"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { CurrentPlayerContext } from "@common/contexts/CurrentPlayerService"
import { GameCardPlayStyleEnum } from "@gql"
import { ShareSection } from "@lobby/components/ShareSection"
import { WaitingRoom } from "@lobby/components/WaitingRoom"
import { grid, theme, vertGrid } from "@common/theme"
import { HeaderBackButton, Layout } from "@common/components"
import { SettingsGear } from "./SettingsGear"
import { useNavigation } from "@react-navigation/native"

interface IProps {}

export const Lobby: React.FC<IProps> = () => {
  const navigation = useNavigation()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const currentGame = React.useContext(CurrentGameContext)
  const [cardPlayStyle, setCardPlayStyle] = React.useState(
    GameCardPlayStyleEnum.PlayersSubmitWords
  )

  React.useEffect(() => {
    setCardPlayStyle(currentGame.card_play_style)
  }, [currentGame.card_play_style])

  return (
    <View style={{ flex: 1 }}>
      <Layout
        header={
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1, marginLeft: grid(1) }}>
              <HeaderBackButton
                onPress={() => {
                  if (navigation.canGoBack()) navigation.goBack()
                }}
                tintColor={theme.color.pastel_gray}
              />
            </View>
            <View style={{ flex: 5, flexDirection: "column" }}>
              <ShareSection />
            </View>
            <View
              style={{
                flex: 1,
                marginRight: grid(1),
                alignItems: "flex-end",
              }}
            >
              <SettingsGear />
            </View>
          </View>
        }
        headerColor={theme.color.pastel_teal}
        headerDividerColor={theme.color.pastel_green}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: grid(1),
            marginTop: vertGrid(2),
          }}
        >
          <UsernameInput
            username={currentPlayer.username || ""}
            playerId={currentPlayer.id}
          />

          <WaitingRoom cardPlayStyle={cardPlayStyle} />
        </View>
      </Layout>
    </View>
  )
}
