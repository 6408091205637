import React from "react"
import { View, StyleSheet } from "react-native"
import { Divider } from "react-native-elements"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { teamScore } from "@common/utils/score"
import { TeamColor } from "@common/utils/team"
import { drawableCards } from "@common/utils/turn"
import { useTranslation } from "react-i18next"
import { theme } from "@common/theme"
import { Text } from "@common/components"
import { TeamColorsEnum } from "@gql"
import { CountdownCircleTimer } from "@common/components/CountdownCircleTimer"
import { SlideDownTimer } from "@common/components/CountdownCircleTimer/SlideDownTimer"

interface CardsLeftItemProps {
  activeCardIndex: number | null
  myTurn: boolean
}

const CardsLeftItem: React.FC<CardsLeftItemProps> = ({
  activeCardIndex,
  myTurn,
}) => {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const numCardsLeft = drawableCards(
    currentGame.turns,
    currentGame.cards
  ).length
  return (
    <View style={styles.panelColumn}>
      <Text fontVariant="Bold" style={styles.panelHeader}>
        {myTurn && `${activeCardIndex || 0} / `}
        {`${numCardsLeft}`}
      </Text>
      <Text>{t("play.turnContext.cards", "cards")}</Text>
    </View>
  )
}

function ScoreCardItem() {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)

  return (
    <View style={styles.panelColumn}>
      <Text style={styles.panelHeader}>
        {
          <Text
            fontVariant="Bold"
            style={{ color: TeamColor[TeamColorsEnum.Red] }}
          >
            {teamScore(
              TeamColorsEnum.Red,
              currentGame.turns,
              currentGame.players
            )}
          </Text>
        }
        {" - "}
        {
          <Text
            fontVariant="Bold"
            style={{ color: TeamColor[TeamColorsEnum.Blue] }}
          >
            {teamScore(
              TeamColorsEnum.Blue,
              currentGame.turns,
              currentGame.players
            )}
          </Text>
        }
      </Text>
      <Text>{t("play.turnContext.score", "score")}</Text>
    </View>
  )
}

const CountdownTimerItem = (props: { secondsLeft: number }) => {
  const currentGame = React.useContext(CurrentGameContext)
  const [slideDownTimer, setSlikeDownTimer] =
    React.useState<React.ReactNode>(null)

  React.useEffect(() => {
    setSlikeDownTimer(
      <SlideDownTimer
        variant="h2"
        fontVariant="Bold"
        time={props.secondsLeft}
      />
    )
  }, [props.secondsLeft])

  return (
    <View style={styles.panelColumn}>
      <CountdownCircleTimer
        startDuration={Number(currentGame.seconds_per_turn)}
        remainingTime={props.secondsLeft}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[15, 7, 5, 0]}
        size={90}
      >
        {slideDownTimer}
      </CountdownCircleTimer>
    </View>
  )
}

export const TurnContextPanel = (props: {
  secondsLeft: number
  activeCardIndex: number | null
  myTurn: boolean
}) => {
  return (
    <View style={styles.panelContainer}>
      <CardsLeftItem
        activeCardIndex={props.activeCardIndex}
        myTurn={props.myTurn}
      ></CardsLeftItem>
      <Divider orientation="vertical" />
      <CountdownTimerItem secondsLeft={props.secondsLeft}></CountdownTimerItem>
      <Divider orientation="vertical" />
      <ScoreCardItem></ScoreCardItem>
    </View>
  )
}

const styles = StyleSheet.create({
  panelContainer: {
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
  },
  panelColumn: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  panelHeader: {
    ...theme.fonts.h3,
  },
})
