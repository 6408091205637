import React from "react"
import { StyleSheet } from "react-native"
import { CurrentGameContext } from "@common/contexts/CurrentGameService"
import { useTranslation } from "react-i18next"
import { Text } from "@common/components"

export const ShareSection = ({ droppedOut = false }) => {
  const { t } = useTranslation()
  const currentGame = React.useContext(CurrentGameContext)
  const [copyButtonClicked, setCopyButtonClicked] = React.useState(false)

  React.useEffect(() => {
    let timeout: NodeJS.Timeout
    if (copyButtonClicked) {
      timeout = setTimeout(() => {
        setCopyButtonClicked(false)
      }, 1000)
    }
    return () => timeout && clearTimeout(timeout)
  }, [copyButtonClicked])

  return (
    <>
      {/* <Text>{t("lobby.shareGame.linkLabel", "Share your link with everyone playing")}</Text>
      <View style={{ paddingTop: 8, paddingBottom: 8 }}>
        <TextField
          id="standard-read-only-input"
          defaultValue={document.URL.replace("http://", "").replace(
            "https://",
            ""
          )}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
        <Clipboard
          data-clipboard-text={document.URL}
          style={{ border: "none", background: "none" }}
        >
          <Button
            variant="contained"
            color="default"
            style={
              copyButtonClicked
                ? { backgroundColor: green[600], color: "#fff" }
                : {}
            }
            onClick={() => setCopyButtonClicked(true)}
          >
            {copyButtonClicked ? "Copied" : "Copy"}
          </Button>
        </Clipboard>
      </View> */}
      <Text style={styles.shareLabel}>
        {droppedOut
          ? t(
              "lobby.droppedShareGame.title",
              "Someone dropped \n or lost connection?"
            )
          : t("lobby.shareGame.title", "Share the code")}
      </Text>
      <Text selectable={true} style={styles.shareCode}>
        {currentGame.join_code}
      </Text>
      <Text style={styles.shareLabel}>
        {droppedOut
          ? t(
              "lobby.droppedShareGame.description",
              "Join back in the same browser."
            )
          : t("lobby.shareGame.description", "to invite your friends!")}
      </Text>
    </>
  )
}

const styles = StyleSheet.create({
  shareLabel: {
    textAlign: "center",
  },
  shareCode: {
    fontSize: 40,
    fontWeight: "600",
    textAlign: "center",
    color: "black",
  },
})
