import React from "react"
import { FlatList } from "react-native"
import { PlayerChip } from "./PlayerChip"
import { Players } from "@common/utils/team"
import { theme } from "@common/theme"

interface Props {
  players: Players
  textColor?: string
  [x: string]: unknown
}

export const PlayerChipList: React.FC<Props> = (props: Props) => {
  return (
    <FlatList
      data={props.players}
      renderItem={({ item }) => (
        <PlayerChip
          teamColor={item?.team?.team_color}
          textColor={props.textColor ?? theme.color.text_primary}
        >
          {item?.username}
        </PlayerChip>
      )}
      keyExtractor={(item) => item.id}
      {...props}
    />
  )
}
