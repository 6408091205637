import React from "react"
import { View, StyleSheet, Dimensions } from "react-native"
import { ModalHeader } from "@common/components/ModalHeader/ModalHeader"
import {
  CurrentPlayerContext,
  PlayerRole,
} from "@common/contexts/CurrentPlayerService"
import { theme, vertGrid } from "@common/theme"
import { SettingsForm } from "@lobby/forms/SettingsForm"
import { RouteProp, useNavigation } from "@react-navigation/native"
import { useTranslation } from "react-i18next"
import { ParticpantSettingsSummary } from "./ParticipantSettingsSummary"
import { ShareSection } from "./ShareSection"
import { GameNavigatorParams, GameRoutes } from "@common/routes"
import { SafeAreaView } from "@common/components"

interface IProps {
  route: RouteProp<GameNavigatorParams, GameRoutes.Settings>
}

const { height } = Dimensions.get("window")

export const SettingsSection: React.FC<IProps> = ({ route }) => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const currentPlayer = React.useContext(CurrentPlayerContext)
  const canConfigureSettings = currentPlayer.role === PlayerRole.Host

  const onDismiss = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    }
  }

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeAreaContainer}>
        <ModalHeader
          title={t("settings.heading", "Settings")}
          onDismiss={onDismiss}
          showLineSeparator={false}
          showHandleBar={false}
          // showHandleBar={Platform.OS === "web" ? false : true}
        />
        {route.params.showShareCode && (
          <View style={styles.settingsContainer}>
            <ShareSection />
          </View>
        )}
        {canConfigureSettings ? (
          <SettingsForm onDismiss={onDismiss} />
        ) : (
          <ParticpantSettingsSummary />
        )}
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: theme.color.pastel_teal,
    height,
  },
  detail: {
    ...theme.fonts.p,
    color: theme.color.text_caption,
    marginTop: 24,
  },
  header: {
    ...theme.fonts.h1,
    color: theme.color.text_primary,
    marginTop: 80,
  },
  marginBottom: {
    marginBottom: 24,
  },
  safeAreaContainer: {
    flex: 1,
  },
  settingsContainer: {
    marginVertical: vertGrid(2),
  },
  settingsTitle: {
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
  },
})
